import { AbstractModuleApiClient } from "@androshq/core/browser-client";
import { MODULE_API_ROUTER_PATH } from "../../common";

class AuthApiClient extends AbstractModuleApiClient {
  constructor() {
    super(MODULE_API_ROUTER_PATH);
  }

  auth = {
    authenticate: () =>
      this.makeRequest({
        path: "",
        requestInit: { method: "POST" },
      }),
  };
}

export const authApiClient = new AuthApiClient();
