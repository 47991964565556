import { useAuth } from "@androshq/auth/browser-client";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  DropdownMenu,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  ReloadIcon,
} from "@androshq/shared-ui";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function UserAvatar() {
  const [isLoggingOut] = useState(false);

  const { user } = useAuth();
  const navigate = useNavigate();

  const userNameInitials = user
    ? `${user.profile.given_name ? user.profile.given_name[0] : ""}${user.profile.family_name ? user.profile.family_name[0] : ""}`
    : "";

  const handleOnLogout = () => {
    navigate('logout')
    // setIsLoggingOut(true);
    // logout();
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger className="outline-none">
        <Avatar className="cursor-pointer select-none rounded-none border-l border-border">
          <AvatarImage className="rounded-none" />
          <AvatarFallback className="rounded-none">
            {userNameInitials}
          </AvatarFallback>
        </Avatar>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuLabel>Hello, {user?.profile.given_name}</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuItem
          disabled={isLoggingOut}
          onSelect={(e: any) => e.preventDefault()}
        >
          <div
            onClick={handleOnLogout}
            className="flex w-full items-center gap-2 cursor-pointer"
          >
            <span>Logout</span>
            {isLoggingOut && <ReloadIcon className="h-3 w-3 animate-spin" />}
          </div>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
