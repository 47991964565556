export enum WorkflowName {
  ChangeApprovalsWorkflow = "ChangeApprovalsWorkflow",
  ERPWriteWorkflow = "ERPWriteWorkflow",
}

export enum WorkflowVersion {
  v1_0 = "1.0",
}

export interface WorkflowReference {
  workflow_id: string;
  workflow_run_id: string;
  workflow_type_name: WorkflowName; // Should only be ChangeApprovalsWorkflow for now (as of first ERPWriteWorkflow implementation) - AC
}
