import React from "react";
import { Spinner, SpinnerProps } from "./Spinner";

const Loading: React.FC<SpinnerProps> = (props) => {
  return (
    <div className="flex h-48 w-full items-center justify-center">
      <Spinner {...props} />
    </div>
  );
};

export default Loading;
