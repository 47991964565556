import { useQueryClient } from "@tanstack/react-query";
import { useCompanies } from "./companies/useCompanies";
import { useItems } from "./items/useItems";
import { useFetchStates } from "./states/useFetchStates";
import { useFetchUsers } from "./users/useFetchUsers";

// const VERSION = import.meta.env.CLIENT_VERSION;

export const useCachedData = () => {
  const queryClient = useQueryClient();
  const companies = useCompanies();
  const items = useItems();
  const users = useFetchUsers();
  const locations = useFetchStates({ state_type: "location" });
  const vendors = useFetchStates({ state_type: "vendor" });

  const invalidateAll = () => {
    queryClient.invalidateQueries({ refetchType: "all" });
  };

  return { companies, items, locations, vendors, users, invalidateAll };
};
