import { WorkflowEventRequestData } from "../../../api/types";
import { WorkflowReference } from "../types";

export enum EventTypeEnum {
  START = "START",
}
export type EventType = `${EventTypeEnum}`;

export type WorkflowTypeName = "ERPWriteWorkflow";
export type WorkflowTypeVersion = "1.0";

export interface ERPWriteWorkflowEventRequestData extends WorkflowEventRequestData {
  workflow_type_name: WorkflowTypeName;
  workflow_event_type: EventType;
  data: WorkflowReference[];
}
