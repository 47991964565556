import { useQuery } from "@tanstack/react-query";
import { sopApiClient } from "../client";
import { Location, Vendor } from "../types";

export const useStates = () => {
  return useQuery({
    queryKey: ["states"],
    queryFn: async () => {
      const response = await sopApiClient.states.getAll();
      if (!response.success) throw new Error(response.message);
      return response.data;
    },
    staleTime: 1000 * 60 * 60, // 1 hour
  });
};

export const useState = (id: number) => {
  const statesQuery = useStates();

  const state = statesQuery.data?.find((state) => state.id === id);

  return {
    data: state,
    isLoading: statesQuery.isLoading,
    error: statesQuery.error,
  };
};

export const useVendors = () => {
  const statesQuery = useStates();

  const vendors = statesQuery.data?.filter((state) => state.state_type === "vendor");

  return {
    data: vendors as Vendor[] | undefined,
    isLoading: statesQuery.isLoading,
    error: statesQuery.error,
  };
};

export const useLocations = () => {
  const statesQuery = useStates();

  const locations = statesQuery.data?.filter((state) => state.state_type === "location");

  return {
    data: locations as Location[] | undefined,
    isLoading: statesQuery.isLoading,
    error: statesQuery.error,
  };
};
