import { useCallback, useEffect, useMemo, useState } from "react";
import { ReloadIcon } from "@androshq/shared-ui";
import { ColDef, GridApi, GridReadyEvent, ValueFormatterParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { getVendorApprovalStatus } from "../../api/standards";
import { ItemConfig, ItemVendorConfig } from "../../api/types";

interface Props {
  rows:
    | {
        vendor: ItemVendorConfig;
        item: ItemConfig;
      }[]
    | undefined;
  isLoading: boolean;
}

export default function SupplyTopTable({ rows, isLoading }: Props) {
  const [gridApi, setGridApi] = useState<GridApi | null>(null);

  const columnDefs = useMemo(
    () => [
      {
        headerName: "",
        children: [
          {
            headerName: "",
            flex: 1,
            sort: "asc",
            hide: true,
            valueGetter: (params: any) => params.data.vendor.item_vendor_priority,
          },
          { headerName: "Vendor", flex: 1, valueGetter: (params: any) => params.data.vendor.state_name },
        ],
      },
      {
        headerName: "Lead Time",
        children: [
          {
            headerName: "Total",
            flex: 1,
            valueGetter: (params: any) => {
              if (params.data && params.data.vendor.vendor_time_days && params.data.vendor.shipment_time_days) {
                return params.data.vendor.vendor_time_days + params.data.vendor.shipment_time_days;
              }
              return "N/A";
            },
          },
          {
            headerName: "Shipping",
            flex: 1,
            valueGetter: (params: any) => params.data.vendor.shipment_time_days,
          },
        ],
      },
      {
        headerName: "Order Constraints",
        children: [
          { headerName: "Lot Size", flex: 1, valueGetter: (params: any) => params.data.item.item_lot_size },
          { headerName: "Min Order", flex: 1, valueGetter: (params: any) => params.data.vendor.minimum_order_quantity },
        ],
      },
      {
        headerName: "Capacity",
        children: [
          {
            headerName: "Week Qty",
            flex: 1,
            valueGetter: (params: any) => params.data.vendor.max_weekly_item_capacity,
          },
        ],
      },
      {
        headerName: "",
        children: [
          {
            headerName: "Status",
            flex: 1,
            minWidth: 140,
            valueGetter: (params: any) => getVendorApprovalStatus(params.data.vendor),
            resizable: false,
          },
        ],
      },
      {
        headerName: "",
        children: [
          {
            headerName: "Preferred",
            flex: 1,
            valueGetter: (params: any) => params.data.vendor.vendor_preferred_for_item,
            valueFormatter: (params: ValueFormatterParams) => {
              if (params.value === 0) {
                return "No";
              } else if (params.value === 1) {
                return "Yes";
              } else {
                return "Unknown";
              }
            },
            resizable: false,
          },
        ],
      },
    ],
    [],
  );

  const defaultColDef: ColDef = useMemo(
    () => ({
      resizable: false,
      suppressHeaderMenuButton: true,
      suppressMovable: true,
      sortable: (rows?.length ?? 0) > 1,
    }),
    [rows],
  );

  const onGridReady = useCallback((event: GridReadyEvent) => setGridApi(event.api), []);

  useEffect(() => {
    if (!gridApi) return;

    if (isLoading) {
      gridApi.showLoadingOverlay();
    } else if (!rows) {
      gridApi.showNoRowsOverlay();
      gridApi.updateGridOptions({
        rowData: [],
      });
    } else {
      gridApi.hideOverlay();
      gridApi.updateGridOptions({
        rowData: rows,
        columnDefs,
      });
    }
  }, [gridApi, columnDefs, rows, isLoading]);

  return (
    <div className="ag-theme-quartz w-full h-full">
      <AgGridReact
        suppressCellFocus
        suppressAutoSize
        reactiveCustomComponents
        loadingOverlayComponent={() => <ReloadIcon className="h-6 w-6 animate-spin" />}
        noRowsOverlayComponent={() => <div>No data available</div>}
        {...{ defaultColDef, onGridReady }}
      />
    </div>
  );
}
