import { useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import { Item, State, WorkflowExecution } from "../../../../api/types";
import { useCachedData } from "../../../../hooks/use-cached-data";
import { defaultColDef, getColumnDefs } from "./columns";

export default function ConfirmModaTable({
  rowData,
  type,
  customTitle,
}: {
  rowData: WorkflowExecution[];
  type: "recent" | "old";
  customTitle?: string;
}) {
  const { locations, vendors, items } = useCachedData();

  const typedLocations = locations.data as State[];
  const typedVendors = vendors.data as State[];
  const typedItems = items.data as Item[];

  const columnDefs = useMemo(
    () => getColumnDefs(typedLocations, typedItems, typedVendors, type),
    [typedLocations, typedVendors, type, typedItems],
  );

  const tableTitle = useMemo(() => {
    const sourceName = rowData[0].state.version_history[0].data.rows[0].source_metadata.source_name;
    if (sourceName) {
      return sourceName.split("-")[0];
    } else {
      return "New row";
    }
  }, [rowData]);

  return (
    <div className="w-full flex-1">
      <div className="text-sm">{customTitle ? customTitle : tableTitle}</div>
      <div className="ag-theme-quartz w-full pt-2">
        <AgGridReact
          rowData={rowData}
          domLayout="autoHeight"
          suppressCellFocus
          suppressAutoSize
          reactiveCustomComponents
          {...{ columnDefs, defaultColDef }}
        />
      </div>
    </div>
  );
}
