import { Query } from "@cubejs-client/core";
import { ColDef, ColGroupDef } from "ag-grid-community";
import _ from "lodash";
import ItemNameCell from "../../../tables/cells/ItemNameCell";
import { BuiltQueryConfig, DisplayConfig, DynamicInputs, InputConfigs } from "../DataCard";
import {rowIsMuted,  cellClassFunc, companyCellWidth, muteUntilMaxWidth} from '../outputs/DataCardTable/helpers';

const customColumnDefs: (ColGroupDef<any> | ColDef<any, any>)[] = [
  {
    field: "inventory_projected_overages-company_name",
    headerName: "Company",
    flex: 1,
    width: companyCellWidth,
    maxWidth: companyCellWidth+20,
  },
  {
    field: "inventory_projected_overages-item_name",
    headerName: "Item",
    flex: 1,
    width: 120,
    maxWidth: 120,
    sortable: true,
    cellRendererSelector: (params) => {
      return {
        component: () => {
          if (!params.data) return;
          return (
            <ItemNameCell
              item_name={
                Object.keys(params.data).find((key) => key.endsWith("item_name"))
                  ? params.data[Object.keys(params.data).find((key) => key.endsWith("item_name"))!]
                  : ""
              }
              item_name_prefix={
                Object.keys(params.data).find((key) => key.endsWith("item_name_prefix"))
                  ? params.data[Object.keys(params.data).find((key) => key.endsWith("item_name_prefix"))!]
                  : ""
              }
              item_variant={
                Object.keys(params.data).find((key) => key.endsWith("item_variant"))
                  ? params.data[Object.keys(params.data).find((key) => key.endsWith("item_variant"))!]
                  : ""
              }
              extra_classes={cellClassFunc(params)}
            />
          );
        },
      };
    },
  },
  {
    field: "excess_weight",
    headerName: "Excess Wt Proj.",
    minWidth: 110,
    width: 110,
    maxWidth: 110,
    sort: "desc",
    sortable: true,
    sortIndex: 1,
    valueGetter: (params) => {
      const item_wt =
        parseFloat(params.data["inventory_projected_overages-excess_wt_at_lead_time"]) /
        (params.data["inventory_projected_overages-ending_balance"] -
          params.data["inventory_projected_overages-inv_qty_min_all_loc"]);
      return {
        excess_wt_at_lead_time: parseFloat(params.data["inventory_projected_overages-excess_wt_at_lead_time"]),
        ending_balance: params.data["inventory_projected_overages-ending_balance"],
        inv_qty_min_all_loc: params.data["inventory_projected_overages-inv_qty_min_all_loc"],
        inv_wt_min_all_loc: params.data["inventory_projected_overages-inv_qty_min_all_loc"] * item_wt,
        excess_pct:
          parseFloat(params.data["inventory_projected_overages-ending_balance"]) /
            Math.max(params.data["inventory_projected_overages-inv_qty_min_all_loc"], 1) -
          1,
      };
    },
    comparator: (valueA, valueB) => {
      if (valueA.excess_wt_at_lead_time == valueB.excess_wt_at_lead_time) return 0;
      return valueA.excess_wt_at_lead_time > valueB.excess_wt_at_lead_time ? 1 : -1;
    },
    cellRendererSelector: (params) => {
      return {
        component: () => {
          if (!params.data) return;
          const maxPct = 5;
          const warnWt = 10;
          const warnPctWtMin = 5;
          const warnPct = 1;
          const excessPctHundreds = Math.round(Math.min(params.value.excess_pct, maxPct) * 100);
          const plusStr = params.value.excess_pct > maxPct ? "+" : "";
          let statusColorClass = rowIsMuted(params) ? cellClassFunc(params) : null;
          if (!statusColorClass && (params.value.excess_wt_at_lead_time > warnWt || params.value.excess_pct > warnPct)) {
            if (params.value.excess_wt_at_lead_time > warnWt && params.value.excess_pct > warnPct) {
              statusColorClass = "text-orange-700";
            } else if (params.value.excess_wt_at_lead_time > warnPctWtMin && params.value.excess_pct > warnPct) {
              statusColorClass = "text-amber-500";
            }
          }
          return (
            <div className={`inline-flex ${statusColorClass ? statusColorClass : ""}`}>
              <div className="mr-2 w-10 text-right">{`${params.value.excess_wt_at_lead_time} MT`}</div>
              <span
                className={`${statusColorClass ? statusColorClass : "text-muted-foreground"}`}
              >{`+${excessPctHundreds}%${plusStr}`}</span>
            </div>
          );
        },
      };
    },
  },
  {
    field: "total_qty",
    headerName: "Current Proj. Balance",
    flex: 1,
    minWidth: 110,
    width: 110,
    maxWidth: 130,
    valueGetter: (params) => {
      return {
        excess_qty_at_lead_time:
          params.data["inventory_projected_overages-ending_balance"] -
          params.data["inventory_projected_overages-inv_qty_min_all_loc"],
        ending_balance: params.data["inventory_projected_overages-ending_balance"],
        inv_qty_min_all_loc: params.data["inventory_projected_overages-inv_qty_min_all_loc"],
        lead_time_days: params.data["inventory_projected_overages-lead_time_days"],
        excess_pct:
          parseFloat(params.data["inventory_projected_overages-ending_balance"]) /
            Math.max(params.data["inventory_projected_overages-inv_qty_min_all_loc"], 1) -
          1,
      };
    },
    cellRendererSelector: (params) => {
      return {
        component: () => {
          if (!params.data) return;
          let statusColorClass = rowIsMuted(params) ? cellClassFunc(params) : null;
          return (
            <div className={`inline-flex ${statusColorClass || ""}`}>
              <div className="mr-2 w-6 text-right">{`${params.value.ending_balance}`}</div>
              <div className={`mr-1 ${statusColorClass || "text-muted-foreground"}`}>{`vs min`}</div>
              <div className={`mr-2 w-6 text-left ${statusColorClass || "text-muted-foreground"}`}>{`${params.value.inv_qty_min_all_loc}`}</div>
            </div>
          );
        },
      };
    },
  },
  {
    headerName: "Mute Until",
    flex: 1,
    maxWidth: muteUntilMaxWidth,
    sort: "asc",
    sortable: true,
    sortIndex: 0,
    editable: true,
    singleClickEdit: true,
    cellDataType: "dateString",
    // cellEditor: "agDateStringCellEditor",
    field: "annotations-mute_until",
  },
  {
    headerName: "Note",
    flex: 1,
    editable: true,
    singleClickEdit: true,
    field: "annotations-memo",
  },
  // {
  //   field: "inventory_projected_overages-bal_snapshot_qty_all_loc",
  //   headerName: "Current Bal.",
  //   cellClass: "cursor-pointer",
  //   flex: 1,
  //   minWidth: 90,
  //   width: 90,
  //   maxWidth: 90,
  // },
  // {
  //   field: "inventory_projected_overages-inflow_cumulative",
  //   headerName: "On Odr/Shp",
  //   cellClass: "cursor-pointer",
  //   flex: 1,
  //   minWidth: 90,
  //   width: 90,
  //   maxWidth: 90,
  // },
  // {
  //   field: "date_projected",
  //   headerName: "Date Proj. (Lead Time)",
  //   cellClass: "cursor-pointer",
  //   flex: 1,
  //   valueGetter: (params) => {
  //     return {
  //       excess_qty_at_lead_time:
  //         params.data["inventory_projected_overages-ending_balance"] -
  //         params.data["inventory_projected_overages-inv_qty_min_all_loc"],
  //       ending_balance: params.data["inventory_projected_overages-ending_balance"],
  //       inv_qty_min_all_loc: params.data["inventory_projected_overages-inv_qty_min_all_loc"],
  //       lead_time_days: params.data["inventory_projected_overages-lead_time_days"],
  //       excess_pct:
  //         parseFloat(params.data["inventory_projected_overages-ending_balance"]) /
  //           Math.max(params.data["inventory_projected_overages-inv_qty_min_all_loc"], 1) -
  //         1,
  //     };
  //   },
  //   cellRendererSelector: (params) => {
  //     return {
  //       component: () => {
  //         if (!params.data) return;
  //         const dateProjected = addDays(new Date(), params.value.lead_time_days);
  //         return (
  //           <div className="inline-flex">
  //             <div className="mr-2 w-12 text-left">{`${format(dateProjected, "M/d/yy")}`}</div>
  //             <div className="text-left text-muted-foreground">{` in ${params.value.lead_time_days} days LT`}</div>
  //           </div>
  //         );
  //       },
  //     };
  //   },
  // },
];

export const getQueryParams = (
  selectedCompanyIds: string[],
  companies: any[],
): [DisplayConfig, BuiltQueryConfig[], InputConfigs] => {
  const dynamicInputs: DynamicInputs = {
    timeDimensions: [],
    filters: [
      {
        inputDataKey: "company_ids",
        member: "inventory_projected_overages.company_id",
        operator: "equals",
      },
    ],
  };

  const baseQuery: Query = {
    dimensions: [
      "inventory_projected_overages.company_name",
      "inventory_projected_overages.item_name",
      "inventory_projected_overages.item_name_prefix",
      "inventory_projected_overages.item_variant",
      "inventory_projected_overages.item_id",
      "inventory_projected_overages.excess_wt_at_lead_time",
      "inventory_projected_overages.lead_time_days",
      "inventory_projected_overages.bal_snapshot_qty_all_loc",
      "inventory_projected_overages.inflow_cumulative",
      "inventory_projected_overages.outflow_cumulative",
      "inventory_projected_overages.ending_balance",
      "inventory_projected_overages.inv_qty_min_all_loc",
      "annotations.mute_until",
      "annotations.memo",
      "inventory_projected_overages.scope",
    ],
    order: [
      ["annotations.mute_until", "asc"],
      ["inventory_projected_overages.excess_wt_at_lead_time", "desc"]],
  };
  const displayConfig: DisplayConfig = {
    cardTitle: "Most Overstocked Items (PO Push-Out/Cancel Required)",
    outputType: "table",
    noRowsMessage: selectedCompanyIds.length ? "No overstocked items found" : "Select a customer to see results",
    columnDefsCustom: customColumnDefs,
  };

  const queryConfigs: BuiltQueryConfig[] = [
    {
      baseQuery: baseQuery,
      dynamicInputs: dynamicInputs,
    },
  ];

  const inputConfigs: InputConfigs = {
    company_ids: {
      disabled: true,
      inputDataType: "multiSelect",
      selectedIds: selectedCompanyIds,
      options: companies,
    },
  };
  return [displayConfig, queryConfigs, inputConfigs];
};
