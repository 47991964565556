import { Button, Dialog, DialogContent } from "@androshq/shared-ui";
import { GridApi } from "ag-grid-community";
import { WorkflowExecution } from "../../../../api/types";
import ConfirmModaTable from "./ConfirmModalTable";
import { sortSelectedRows } from "./helpers";

interface Props {
  setIsSyncModalOpen: (val: boolean) => void;
  isSyncModalOpen: boolean;
  gridApi: GridApi | null;
  syncLinesToERP: () => void;
}

export default function ConfirmSyncModal({ isSyncModalOpen, setIsSyncModalOpen, gridApi, syncLinesToERP }: Props) {
  if (!gridApi) return null;
  const selectedRows: WorkflowExecution[] = gridApi.getSelectedRows();
  if (selectedRows.length === 0) return null;

  const { newRows, changed, moved } = sortSelectedRows(selectedRows);

  const confirmSync = () => {
    setIsSyncModalOpen(false);
    syncLinesToERP();
  };

  return (
    <Dialog open={isSyncModalOpen} onOpenChange={setIsSyncModalOpen}>
      <DialogContent className="sm:max-w-[1500px] max-h-[800px] flex flex-col gap-5" tabIndex={-1}>
        <div className=" overflow-auto flex flex-col gap-5">
          <div>
            <h3 className="font-semibold">New</h3>
            <div className="flex flex-col gap-10 pt-5">
              {newRows.map((row, idx) => (
                <div data-testid="sync-new-row" key={idx} className="flex justify-between items-center gap-20">
                  <div className="flex-1 text-center text-sm text-gray-500"></div>
                  <ConfirmModaTable rowData={row} type="recent" />
                </div>
              ))}
              {newRows.length === 0 && (
                <div className="flex-1 text-center text-sm text-gray-500">No new PO lines will be created</div>
              )}
            </div>
          </div>

          <div>
            <h3 className="font-semibold">Changed</h3>
            <div className="flex flex-col gap-10 pt-5">
              {changed.map((row, idx) => (
                <div key={idx} data-testid="sync-changed-row" className="flex justify-between items-center gap-20">
                  <ConfirmModaTable rowData={row} type="old" />
                  <ConfirmModaTable rowData={row} type="recent" />
                </div>
              ))}
              {changed.length === 0 && (
                <div className="flex-1 text-center text-sm text-gray-500">
                  No lines have been changed, or changed lines are moved to new documents
                </div>
              )}
            </div>
          </div>

          <div>
            <h3 className="font-semibold">Moved</h3>
            <div className="flex flex-col gap-10 pt-5">
              {moved.map((row, idx) => (
                <div key={idx} data-testid="sync-moved-row" className="flex justify-between items-center gap-20">
                  <ConfirmModaTable rowData={row} type="old" />
                  <ConfirmModaTable rowData={row} customTitle="Unknown PO" type="recent" />
                </div>
              ))}
              {moved.length === 0 && (
                <div className="flex-1 text-center text-sm text-gray-500">No rows have been moved to new POs</div>
              )}
            </div>
          </div>
        </div>
        <div className=" flex justify-end">
          <Button data-testid="confirm-sync" onClick={confirmSync}>
            Confirm
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
