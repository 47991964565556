import { useMemo } from "react";
import { ReloadIcon } from "@androshq/shared-ui";
import { AgChartOptions } from "ag-charts-community";
import { AgChartsReact } from "ag-charts-react";
import _ from "lodash";

type Props = {
  data: Record<string, number | string | Date>[] | undefined;
  series: {
    xKey: string;
    yKey: string;
    yName: string;
  }[];
  options?: AgChartOptions;
  isLoading?: boolean;
};

export default function BarChart({ data, series, options, isLoading }: Props) {
  const chartOptions = useMemo(
    () =>
      _.merge(
        {
          data,
          series: series?.map((legend) => ({
            type: "bar",
            cornerRadius: 4,
            ...legend,
          })),
        } as AgChartOptions,
        options,
      ),
    [data, series, options],
  );

  if (isLoading)
    return (
      <div className="flex w-full h-full flex-1 items-center justify-center gap-2">
        <ReloadIcon className="h-4 w-4 animate-spin" />
        Loading...
      </div>
    );
  return <AgChartsReact options={chartOptions} />;
}
