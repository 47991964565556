import { withModuleDashboardPageProps } from "@androshq/core/browser-client";
import { Outlet } from "react-router-dom";
import { CLIENT_MODULE_NAME, SectionNames } from "../../constants";

export const _ItemsOutlet = () => {
  return <Outlet />;
};

export const ItemsOutlet = withModuleDashboardPageProps(_ItemsOutlet, {
  moduleName: CLIENT_MODULE_NAME,
  sectionName: SectionNames.Items,
});
