import {
  ThunkDispatch,
  combineReducers,
  configureStore,
} from "@reduxjs/toolkit";
import supplyChainSlice from "./supplyChainSlice";
import appSlice from "./appSlice";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

export const sopReducer = combineReducers({
  supplyChain: supplyChainSlice,
  app: appSlice,
});

export type RootStateInterface = {
  sop: ReturnType<typeof sopReducer>;
};

export let useSopSelector: TypedUseSelectorHook<RootStateInterface> =
  useSelector;

type SopDispatch = ReturnType<typeof configureStore>["dispatch"];
export type AppDispatchInterface = SopDispatch & ThunkDispatch<any, any, any>;

export let useSopDispatch = () => useDispatch<AppDispatchInterface>();
