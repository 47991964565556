import { useCallback, useEffect, useMemo, useState } from "react";
import { useAuth } from "@androshq/auth/browser-client";
import { Permission, checkUserForPermissions } from "@androshq/auth/common";
import { ReloadIcon } from "@androshq/shared-ui";
import { CellKeyDownEvent, CellValueChangedEvent, ColDef, GridApi, GridReadyEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { addWeeks, format } from "date-fns";
import { toast } from "sonner";
import { sopApiClient } from "../..";
import { Company, ItemWithConfig } from "../../api/types";
import { useFetchForecastTableData } from "../../hooks/forecasting/useFetchForecastTableData";
import { useItems } from "../../hooks/items/useItems";
import {
  ITEM_FCST_PAGE_TS_COL_QTY,
  ITEM_FCST_PAGE_TS_MIN_WEEK_NUM,
  STD_GRID_OPTIONS,
  getDateRangeForCubeQuery,
  getHeaderOptionsForTimeSeriesCol,
  getTSColWeekRange,
} from "../tables/standards";

interface Props {
  item: ItemWithConfig;
  company: Company;
}

export default function ForecastTable({ item }: Props) {
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const { data: items } = useItems();
  const { user } = useAuth();
  const { allowed, reason } = checkUserForPermissions(user, [Permission.APP_DATA_FORECAST_EDIT]);
  const mostCommonLocationId = useMemo(() => {
    const selectedItem = items?.find((i) => i.id === item.id);
    if (!selectedItem) return null;

    const locationKeyValueTuples = Object.entries(selectedItem.config.location);
    const sortedLocations = locationKeyValueTuples.sort((a, b) => {
      if (a[1].balance_snapshot_quantity !== b[1].balance_snapshot_quantity) {
        return b[1].balance_snapshot_quantity - a[1].balance_snapshot_quantity;
      }
      return new Date(b[1].balance_snapshot_date).getTime() - new Date(a[1].balance_snapshot_date).getTime();
    });

    return sortedLocations.length ? parseInt(sortedLocations[0][0]) : null;
  }, [items, item?.id]);

  const { tableData, isLoading: forecastDataPending } = useFetchForecastTableData(
    item?.id,
    getDateRangeForCubeQuery(ITEM_FCST_PAGE_TS_MIN_WEEK_NUM, ITEM_FCST_PAGE_TS_COL_QTY),
  );
  const columnDefs: (ColDef & { week?: Date })[] = useMemo(() => {
    const columns: (ColDef & { week?: Date })[] = [
      { headerName: "", field: "start", cellClass: "font-bold", flex: 1.3 },
    ];

    const weekRange = getTSColWeekRange(ITEM_FCST_PAGE_TS_MIN_WEEK_NUM, ITEM_FCST_PAGE_TS_COL_QTY);

    for (let i = 0; i < weekRange.length; i++) {
      const weekDateStr = format(weekRange[i].date, "yyyy-MM-dd");

      columns.push({
        ...getHeaderOptionsForTimeSeriesCol(weekRange[i].num, weekRange[i].date),
        field: weekDateStr,
        flex: 1,
        cellStyle: (params) => {
          const fixedPlanRow = params.api.getRowNode("2");
          const recentForecastRow = params.api.getRowNode("0");
          if (params.data.start === "Plan" && typeof params.data[weekDateStr] === "number") {
            return { color: "#9CA3AF" };
          } else if (
            params.data.start === "Model" &&
            params.data[weekDateStr] === recentForecastRow?.data[weekDateStr]
          ) {
            return { color: "#9CA3AF" };
          } else if (
            params.data.start === "Model" &&
            params.data[weekDateStr] !== recentForecastRow?.data[weekDateStr] &&
            typeof fixedPlanRow?.data[weekDateStr] === "number"
          ) {
            return { color: "#5090DC" };
          } else {
            return { color: "black" };
          }
        },
        // cellClass: cn({ "bg-cyan-100": +recommended < +recentForecast && (+fixedTotal === 0 || fixedTotal === null) }),
      });
    }

    columns[columns.length - 1].maxWidth = 65;

    return columns;
  }, []);

  const defaultColDef: ColDef = {
    resizable: false,
    sortable: false,
    suppressHeaderMenuButton: true,
    suppressMovable: true,
    editable: (params) => {
      return params.node.rowIndex === 2 && allowed;
    },
    tooltipValueGetter: (params) => {
      if (params.node?.rowIndex === 2 && !allowed) {
        return reason;
      }
      return null;
    },
  };
  const onCellKeyDown = (event: CellKeyDownEvent) => {
    const keyboardEvent = event.event as KeyboardEvent;
    if (keyboardEvent && keyboardEvent.key === "Escape") {
      event.api.stopEditing(true);
    }
  };

  const onCellValueChanged = async (params: CellValueChangedEvent) => {
    const dateString = params.colDef.field as string; // assuming format "yyyy-mm-dd"
    const [year, month, day] = dateString.split("-").map(Number);
    const weekStart = new Date(year, month - 1, day); // month is 0-indexed in JavaScript Date
    const weekEnd = addWeeks(weekStart, 1);

    const toastId = toast.loading("Updating plan…");
    if (!mostCommonLocationId) {
      throw new Error("No location ID found while overriding plan");
    }

    await sopApiClient.items.item.overrideForecasts({
      itemId: +item.id,
      fromStateId: mostCommonLocationId,
      overrideWindowStart: weekStart,
      overrideWindowEnd: weekEnd,
      overrideQuantity: params.newValue === null ? 0 : params.newValue,
    });
    params.api.refreshCells({ force: true });
    toast.dismiss(toastId);
  };

  const onGridReady = useCallback((event: GridReadyEvent) => setGridApi(event.api), []);

  useEffect(() => {
    if (!gridApi) return;

    if (forecastDataPending) {
      gridApi.showLoadingOverlay();
    } else if (!tableData) {
      gridApi.showNoRowsOverlay();
      gridApi.updateGridOptions({
        rowData: [],
        ...STD_GRID_OPTIONS,
      });
    } else {
      gridApi.hideOverlay();
      gridApi.updateGridOptions({
        rowData: tableData,
        columnDefs,
        ...STD_GRID_OPTIONS,
      });
    }
  }, [gridApi, columnDefs, tableData, forecastDataPending]);

  return (
    <>
      <div className="ag-theme-quartz w-full p-2" data-testid="forecast-table">
        <AgGridReact
          suppressAutoSize
          reactiveCustomComponents
          stopEditingWhenCellsLoseFocus
          loadingOverlayComponent={() => <ReloadIcon className="h-6 w-6 animate-spin" />}
          noRowsOverlayComponent={() => <div>No data available</div>}
          {...{ defaultColDef, onCellKeyDown, onCellValueChanged, onGridReady }}
        />
      </div>
    </>
  );
}
