import { useQuery, useQueryClient } from "@tanstack/react-query";
import { sopApiClient } from "../../api/client";
import { WorkflowExecutionsSearchQueryParams } from "../../api/client";

export const queryKeyFactory = (params: any) => ["workflow-executions", params];

export const useFetchWorkflowExecutions = (params?: WorkflowExecutionsSearchQueryParams) => {
  const queryClient = useQueryClient();
  const queryKey = queryKeyFactory(params);

  const invalidate = () => queryClient.invalidateQueries({ queryKey });

  const query = useQuery({
    queryKey,
    queryFn: async () => {
      const response = await sopApiClient.workflows.executions.search(params);
      if (!response.success) throw new Error(response.message);
      return response.data;
    },
    meta: { cache: false },
  });

  return { ...query, invalidate };
};
