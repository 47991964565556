import { useEffect, useState } from "react";
import { useAuth } from "@androshq/auth/browser-client";
import { Permission, checkUserForPermissions } from "@androshq/auth/common";
import {
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
  ReloadIcon,
} from "@androshq/shared-ui";
import { useLocation, useNavigate } from "react-router-dom";
// import { useCompanies } from "../hooks/companies/useCompanies";
import { useInventoryItems } from "../../api/hooks/items";
import { compareFullItemNames, filterItemsOnFullName } from "../../api/standards";
import { ItemName } from "../labels/ItemName";

export const triggerGlobalSearchPopup = () => {
  const event = new KeyboardEvent("keydown", {
    key: "k",
    metaKey: true, // For Mac
    ctrlKey: true, // For Windows/Linux
    bubbles: true,
  });
  document.dispatchEvent(event);
};

export const Search = () => {
  const renderItemCount = 10;
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const { user } = useAuth();
  const [filter, setFilter] = useState("");

  // const { data: companies = [], isPending: companiesPending } = useCompanies();
  const { data: items, isLoading: itemsLoading } = useInventoryItems();
  const { allowed } = checkUserForPermissions(user, [Permission.APP_VIEWS_SEARCH]);

  // const filteredCompanies = filterData(companies, filter);
  const filteredItems =
    items && Array.isArray(items) ? filterItemsOnFullName(items, filter).slice(0, renderItemCount) : [];

  // Sort items alphabetically by variant in descending order
  filteredItems.sort(compareFullItemNames);

  useEffect(() => {
    if (!allowed) return;
    const down = (e: KeyboardEvent) => {
      if (e.key === "k" && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        setOpen(!open);
      }
    };

    document.addEventListener("keydown", down);
    return () => document.removeEventListener("keydown", down);
  }, [allowed, open]);

  useEffect(() => {
    open && setFilter("");
  }, [open]);

  const onCommandItemClicked = (callback: () => void) => {
    setOpen(false);
    callback();
  };

  const handleItemClicked = (id: number) => {
    onCommandItemClicked(() => {
      // Enable using global search on the item detail pages... just need to keep page consistent
      const currentPath = location.pathname;
      const itemPathRegex = /^\/sop\/items\/\d+\/(.+)$/;
      const match = currentPath.match(itemPathRegex);

      if (match) {
        // If there's a match, replace the ID in the current path
        const newPath = `/sop/items/${id}/${match[1]}`;
        navigate(newPath);
      } else {
        // If no match, navigate to the default supply-chain page
        navigate(`/sop/items/${id}/supply-chain`);
      }
    });
  };

  const renderEmpty = () => {
    if (itemsLoading) {
      return (
        <CommandEmpty>
          <div className="flex justify-center">
            <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
          </div>
        </CommandEmpty>
      );
    }

    return <CommandEmpty>No results found.</CommandEmpty>;
  };

  const renderItems = () => {
    if (!filteredItems.length) return;

    return (
      <CommandGroup heading="Items">
        {filteredItems.map((item) => (
          <CommandItem key={item.id} onSelect={() => handleItemClicked(item.id)}>
            <ItemName item={item} />
          </CommandItem>
        ))}
      </CommandGroup>
    );
  };

  const renderSeparator = () => {
    if (!filteredItems.length) return;

    return <CommandSeparator />;
  };

  return (
    <CommandDialog open={open} onOpenChange={setOpen} shouldFilter={false}>
      <CommandInput placeholder="Search items..." value={filter} onValueChange={setFilter} />
      <CommandList>
        {renderEmpty()}
        {renderItems()}
        {renderSeparator()}
        {/* {renderCompanies()} */}
      </CommandList>
    </CommandDialog>
  );
};
