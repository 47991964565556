import _ from "lodash";
import { IdentityUser } from "../../../../../api/types";
import { Approver, CAWorkflowExecution, StateTransition } from "../../types";

export const getIsVendorActual = (transitions: StateTransition[]) =>
  _.find(transitions, { state_type: "vendor" })?.actual ?? false;

const getNextApproverName = (approvers: Approver[], current_approver: IdentityUser | null): string => {
  if (!current_approver) {
    console.warn("Current approver is null");
    return "";
  }
  console.log(approvers);

  return "";
};

export const getTooltipForApprove = (
  data: CAWorkflowExecution | null,
  allowed: boolean,
  department: string,
): string => {
  if (!data) return "";
  const { approvers, current_approver, approval_status } = data.search_attributes;
  console.log(getNextApproverName(approvers, current_approver));

  if (approval_status !== "AWAITING_APPROVAL") {
    return `Workflow is ${approval_status} and does not require further approval`;
  }
  if (!allowed) {
    return `Only the current assigned role ${department} can approve this workflow`;
  }
  return "";
};
