import { cn } from "@androshq/shared-ui";
import { Button, ReloadIcon, Tabs, TabsList, TabsTrigger } from "@androshq/shared-ui";
import queryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { CompanyPicker } from "../../components/controls/CompanyPicker";
import { FilterMultiSelect } from "../../components/controls/FilterMultiSelect";
import Tooltip from "../../components/messages/Tooltip";

export interface IFilter {
  id: string;
  type: "switch" | "multiselect" | "customFilter";
  selectorType?: "single" | "multi";
  wordSeparator?: string;
  component?: "companyPicker";
  componentProps?: Record<string, string>;
  label?: string;
  options: { label: string; value: string }[];
}

export type IFilterGenerator<T = object[]> = (optionsSource: T) => IFilter;

export interface IAction {
  title: string;
  isLoading?: boolean;
  onClick: () => void;
  tooltip: string;
  isDisabled: boolean;
  icon?: React.ReactElement<{ className?: string }>;
  dataTestId?: string;
}

interface Props {
  filters: IFilter[];
  actions?: IAction[];
  className?: string;
}

export function WorkflowExecutionsActionBar({ filters, actions, className }: Props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const componentsMap = {
    companyPicker: CompanyPicker,
  };

  type ComponentName = keyof typeof componentsMap;

  const getPredefinedComponents = (componentName: ComponentName, props?: Record<string, string>) => {
    const Component = componentsMap[componentName];
    return Component ? <Component {...props} /> : null;
  };

  const handleSwitchChange = (key: string, value: string) => {
    const currentQuery = queryString.parse(location.search);
    if (value) {
      currentQuery[key] = value;
    } else {
      delete currentQuery[key];
    }
    const newQueryString = queryString.stringify(currentQuery);
    navigate({ search: newQueryString });
  };

  const handleMultiSelectChange = (key: string, values: string[]) => {
    const currentQuery = queryString.parse(location.search);
    if (values.length > 0) {
      currentQuery[key] = values;
    } else {
      delete currentQuery[key];
    }
    const newQueryString = queryString.stringify(currentQuery, { arrayFormat: "comma" });
    navigate(`?${newQueryString}`, { replace: true });
  };

  return (
    <div className={cn("flex items-center justify-between w-full border-b px-2.5 py-1", className)}>
      <div className="flex gap-4 xl:gap-5  flex-wrap">
        {filters?.map((filter, idx) => {
          if (filter.type === "switch") {
            return (
              <Tabs
                key={filter.id}
                value={searchParams.get(filter.id)! || filter.options[0].value}
                onValueChange={(value) => handleSwitchChange(filter.id, value)}
              >
                <TabsList>
                  {filter.options?.map((option) => (
                    <TabsTrigger key={option.value} value={option.value}>
                      {option.label}
                    </TabsTrigger>
                  ))}
                </TabsList>
              </Tabs>
            );
          } else if (filter.type === "multiselect") {
            const selectedValues = ((queryString.parse(location.search)[filter.id] as string) || "")
              .split(",")
              .filter(Boolean);

            return (
              <div key={idx} className="flex gap-1.5 items-center">
                <FilterMultiSelect
                  placeholder={`Search ${filter.label?.toLowerCase()}`}
                  label={filter.label!}
                  selectorType={filter.selectorType!}
                  wordSeparator={filter.wordSeparator!}
                  values={selectedValues}
                  options={filter.options}
                  onValueChange={(values) => handleMultiSelectChange(filter.id, values)}
                />
              </div>
            );
          } else if (filter.type === "customFilter") {
            return (
              <div key={filter.id} className="flex gap-1.5 items-center">
                {getPredefinedComponents(filter.component!, filter.componentProps)}
              </div>
            );
          }
        })}
      </div>
      <div className="flex gap-4 xl:gap-5 justify-end flex-wrap">
        {actions?.map((action, idx) => (
          <Tooltip key={idx} tooltip={action.tooltip}>
            <span tabIndex={0}>
              <Button
                disabled={action.isLoading || action.isDisabled === true}
                className="py-1 h-6 flex items-center"
                // @ts-expect-error bug in button component in @androshq/shared-ui
                size={"sm"}
                data-testid={action.dataTestId}
                variant={"link"}
                onClick={() => action.onClick()}
              >
                {action.isLoading ? <ReloadIcon className="mr-2 animate-spin" /> : action.icon}
                <span className=" hidden lg:inline">{action.title}</span>
              </Button>
            </span>
          </Tooltip>
        ))}
      </div>
    </div>
  );
}
