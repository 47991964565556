import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootStateInterface } from "..";

type AppState = {
  // The ids of the companies that are selected in the dashboard
  selectedCompanyIds: string[];
};

const initialState: AppState = {
  selectedCompanyIds: [],
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setSelectedCompanyIds: (state, action: PayloadAction<string[]>) => {
      state.selectedCompanyIds = action.payload;
    },
  },
});

export const selectAppData = (state: RootStateInterface) => state.sop.app;

export const { setSelectedCompanyIds } = appSlice.actions;

export default appSlice.reducer;
