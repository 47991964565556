import { useEffect } from "react"
import { useAuth } from "@androshq/auth/browser-client"
import { useNavigate } from "react-router-dom"  

export default function Logout() {
    const {logout} = useAuth()
    const navigate = useNavigate()

    
    useEffect(() => {
        logout()
        localStorage.clear();
        sessionStorage.clear();
        navigate('/login')
    }, [])
    return (
    <div></div>
  )
}
