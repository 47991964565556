import { useCallback, useEffect, useMemo, useState } from "react";
import { Card, CardContent, CardHeader, CheckIcon, Pencil1Icon, ReloadIcon } from "@androshq/shared-ui";
import { useQueryClient } from "@tanstack/react-query";
import {
  ColDef,
  EditableCallbackParams,
  GetRowIdParams,
  GridApi,
  GridReadyEvent,
  ICellRendererParams,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import _ from "lodash";
import { useParams } from "react-router-dom";
import { sopApiClient } from "../../../../api";
import { CompanyConfigShippingMethods, ItemWithConfig } from "../../../../api/types";
import { useItems } from "../../../../hooks/items/useItems";

interface RowData {
  id: keyof CompanyConfigShippingMethods;
  method: string;
  leadTime: number;
  actions?: unknown;
  editable?: boolean;
  dirty?: boolean;
}

const ButtonRenderer = (params: ICellRendererParams) => {
  const editable = params.data?.editable;
  const queryClient = useQueryClient();

  const [saving, setSaving] = useState<boolean>(false);
  const [editableMode, setEditableMode] = useState<boolean>(editable || false);

  const toggleEditMode = (value: boolean) => {
    params.node.setData({ ...params.data, editable: value, dirty: false });
    params.api.refreshCells({ rowNodes: [params.node], force: true });
    if (!value) params.api.stopEditing();
  };

  const handleEdit = () => {
    toggleEditMode(true);
    setEditableMode(true);
  };

  const handleSave = useCallback(() => {
    setEditableMode(false);
    params.api.stopEditing();
    params.node.setData({ ...params.data, editable: false, dirty: false });
  }, [params]);

  const updateConfigValueHandler = useCallback(async () => {
    const {
      id: shippingMethodKey,
      companyId,
      leadTime,
    } = params.data as { id: keyof CompanyConfigShippingMethods; companyId: number; leadTime: number };
    const newLeadTime = leadTime || 0;

    setSaving(true);

    try {
      await sopApiClient.config.update(+companyId, shippingMethodKey, newLeadTime);
      queryClient.setQueryData(["items"], (prevData: ItemWithConfig[]) =>
        prevData.map((item: ItemWithConfig) => {
          if (+item.company_id === +companyId) item.config.company[shippingMethodKey] = newLeadTime;
          return item;
        }),
      );
    } finally {
      setSaving(false);
      setEditableMode(false);
      params.node.setData({ ...params.data, editable: false, dirty: false });
    }
  }, [params]);

  useEffect(() => {
    if (params.data?.dirty) {
      updateConfigValueHandler();
    }
  }, [params.data?.dirty]);

  return editableMode ? (
    <button className="flex items-center gap-x-1 h-full text-zinc-950 text-xs" onClick={handleSave}>
      {saving ? (
        <>
          <ReloadIcon className="w-3 h-3 animate-spin" />
          Saving…
        </>
      ) : (
        <>
          <CheckIcon className="h-4" />
          Save
        </>
      )}
    </button>
  ) : (
    <button className="flex items-center gap-x-1 h-full text-zinc-950 text-xs" disabled={saving} onClick={handleEdit}>
      <Pencil1Icon className="w-3 h-3" />
      Edit
    </button>
  );
};

export default function ConfigurationTable() {
  const [gridApi, setGridApi] = useState<GridApi | null>(null);

  const { id: companyId } = useParams<{ id: string }>();
  const { data: items, isLoading } = useItems();

  const rowData: RowData[] | null = useMemo(() => {
    const companyConfig = _.find(items, { company_id: +companyId! })?.config?.company;
    const fastvesselTimeDays = companyConfig?.shipment_time_days_fastvessel || 0;
    const airTimeDays = companyConfig?.shipment_time_days_air || 0;

    return [
      {
        id: "shipment_time_days_fastvessel",
        method: "Fast Vessel",
        leadTime: fastvesselTimeDays,
        companyId,
      },
      { id: "shipment_time_days_air", method: "Air", leadTime: airTimeDays, companyId },
    ];
  }, [items, companyId]);

  const [columnDefs] = useState<ColDef<RowData>[]>([
    {
      field: "method",
      headerName: "Method",
      cellClass: "pl-4",
      maxWidth: 120,
    },
    {
      field: "leadTime",
      headerName: "Lead Time (Days)",
      editable: (params: EditableCallbackParams) => params.data?.editable,
      onCellValueChanged: (params) => {
        params.node?.setData({ ...params.data, dirty: params.oldValue !== params.newValue });
      },
      cellClassRules: {
        "outline outline-cyan-500": (params) => (params.data?.editable !== undefined ? params.data.editable : true),
      },
      cellEditor: "agNumberCellEditor",
      maxWidth: 140,
    },
    {
      headerName: "",
      field: "actions",
      cellRenderer: ButtonRenderer,
      maxWidth: 120,
    },
  ]);

  const getRowId = useCallback((params: GetRowIdParams) => params.data.id, []);
  const onGridReady = useCallback((event: GridReadyEvent) => setGridApi(event.api), []);
  useEffect(() => {
    if (!gridApi) return;

    if (isLoading) {
      gridApi.showLoadingOverlay();
    } else if (rowData?.length === 0) {
      gridApi.showNoRowsOverlay();
      gridApi.updateGridOptions({
        rowData: [],
      });
    } else {
      gridApi.hideOverlay();
      gridApi.updateGridOptions({
        rowData,
        columnDefs,
      });
    }
  }, [gridApi, columnDefs, rowData, isLoading]);

  const defaultColDef: ColDef = {
    sortable: false,
    headerClass: "pl-4",
    resizable: false,
    suppressHeaderMenuButton: true,
    suppressMovable: true,
  };

  return (
    <div className="flex flex-col gap-y-2">
      <h3 className="text-lg font-semibold">Configuration</h3>
      <Card className="flex flex-col flex-1">
        <CardHeader className="p-4 pb-0">
          <h4 className="text-base font-semibold">Shipping Methods</h4>
        </CardHeader>
        <CardContent className="flex-1 p-0">
          <div className="ag-theme-quartz w-full ag-sm h-full pt-2">
            <AgGridReact
              suppressAutoSize
              suppressCellFocus
              reactiveCustomComponents
              rowBuffer={25}
              loadingOverlayComponent={() => <ReloadIcon className="h-6 w-6 animate-spin" />}
              noRowsOverlayComponent={() => <div>No data available</div>}
              {...{ defaultColDef, onGridReady, getRowId }}
            />
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
