import { User } from "oidc-client-ts";

export const LOCAL_STORAGE_AUTH_USER = "LOCAL_STORAGE_AUTH_USER";

export const getPersistedUser = () => {
  const userJson = localStorage.getItem(LOCAL_STORAGE_AUTH_USER);
  return userJson ? (JSON.parse(userJson) as User) : null;
};

export const persistUser = (user: User) => {
  const userJson = JSON.stringify(user);
  localStorage.setItem(LOCAL_STORAGE_AUTH_USER, userJson);
};

export const removePersistedUser = () => {
  localStorage.removeItem(LOCAL_STORAGE_AUTH_USER);
};
