import React from "react";
import { AgCharts } from "ag-charts-enterprise";
import { LicenseManager } from "ag-grid-enterprise";
import "ag-grid-enterprise";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import "./index.css";
import * as monitor from "./utils/monitor.ts";

monitor.init();

// Set AGGrid License Key
LicenseManager.setLicenseKey(import.meta.env.AGGRID_ENTERPRISE_KEY);
AgCharts.setLicenseKey(import.meta.env.AGGRID_ENTERPRISE_KEY);

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
