import { PropsWithChildren, useCallback, useState } from "react";
import { TooltipProvider, TooltipTrigger, Tooltip as UITooltip, cn } from "@androshq/shared-ui";
import TooltipContentWithPortal from "./TooltipPortal";

interface Props {
  tooltip: string | React.ReactNode | undefined;
  disabled?: boolean;
  className?: string | undefined;
  side?: "top" | "right" | "bottom" | "left" | undefined;
  copy?: boolean;
}

export default function Tooltip({ tooltip, side, disabled, copy, className, ...props }: PropsWithChildren<Props>) {
  const [copied, setCopied] = useState<boolean>(false);

  const handleCopyText = useCallback(
    (text: any) => {
      if (!copy || !text) return;
      navigator.clipboard.writeText(text.toString()).then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      });
    },
    [copy],
  );

  if (!tooltip) return null;
  if (disabled) return props?.children;
  return (
    <TooltipProvider skipDelayDuration={0} delayDuration={0}>
      <UITooltip>
        <TooltipTrigger asChild {...props} />
        <TooltipContentWithPortal
          data-testid="tooltip"
          side={side ? side : "top"}
          className={cn(" max-w-80 whitespace-normal", className, { "cursor-pointer": copy })}
          onClick={() => handleCopyText(tooltip)}
        >
          {copied ? <span>Copied!</span> : tooltip}
        </TooltipContentWithPortal>
      </UITooltip>
    </TooltipProvider>
  );
}
