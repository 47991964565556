export const CLIENT_MODULE_NAME = "TIPS";

export enum SectionNames {
  Dashboard = "Dashboard",
  Items = "Items",
  Companies = "Customers",
  Workflows = "Workflows",
}

export const dateFormat = "MMM dd yyyy";
export const dateFormatShort = "M/d/yy";
