import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@androshq/shared-ui";
import { TimeDimensionGranularity } from "@cubejs-client/core";
import { SupportedGranularityOptions } from "../../tables/standards";

interface Props {
  granularity: TimeDimensionGranularity|undefined;
  handleValueChange: (val: TimeDimensionGranularity) => void;
  fixed: boolean;
}

const renderGranularityLabel = (granularity: TimeDimensionGranularity) => {
  return `By ${granularity.charAt(0).toUpperCase() + granularity.slice(1)}`
}

export default function CardGranularitySelect({ granularity, handleValueChange, fixed }: Props) {
  return (
    <Select value={granularity} onValueChange={handleValueChange} disabled={fixed}>
      <SelectTrigger className="w-fit h-6 px-2 py-1 bg-zinc-100 rounded-md shadow-none border-none text-xs transition">
        <SelectValue />
      </SelectTrigger>
      <SelectContent>
        {Object.values(SupportedGranularityOptions).map((option) => (
          <SelectItem key={option} value={option}>
            {renderGranularityLabel(option)}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
}
