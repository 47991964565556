import { TooltipContent } from "@androshq/shared-ui";
import * as ReactDOM from "react-dom";

interface TooltipContentWithPortalProps {
  children: React.ReactNode;
  side?: "top" | "right" | "bottom" | "left";
  className?: string;
  onClick?: () => void;
}

export default function TooltipContentWithPortal({
  children,
  side = "top",
  className,
  onClick,
}: TooltipContentWithPortalProps) {
  return ReactDOM.createPortal(
    <TooltipContent side={side} className={className} onClick={onClick}>
      {children}
    </TooltipContent>,
    document.body,
  );
}
