import { Company, ItemWithConfig, State } from "../../../../api/types";

export const mapStatesToOptions = (states: State[] | undefined) =>
  states?.map((state) => ({
    value: state.id.toString(),
    label: state.name,
  })) || [];

export const mapCompaniesToOptions = (items: ItemWithConfig[] | undefined, company: Company | undefined) =>
  items
    ?.filter((item) => item.company_id === company?.id)
    .map((item) => ({
      value: item.id?.toString(),
      label: item.name,
    })) || [];
