import { Button, MultiSelect, Tabs, TabsList, TabsTrigger, cn } from "@androshq/shared-ui";
import { useLocation, useNavigate } from "react-router-dom";
import { CompanyPicker } from "../controls/CompanyPicker";
import ItemPicker from "../controls/ItemPicker";

interface Picker {
  onChange?: () => void;
}

interface Switch {
  data: {
    label: string;
    href: string;
  }[];
  type?: "link" | "button";
  onChange?: () => void;
  value?: boolean;
}

interface Multiselect {
  values: string[];
  options: {
    value: any;
    label: any;
  }[];
  placeholder: string;
  onValueChange: (ids: string[]) => Promise<void>;
}

interface Button {
  title: string;
  isLoading?: boolean;
  onClick: () => void;
  disabledData?: any[];
  icon?: any;
}

interface Combobox {
  value: string;
  options: {
    value: string;
    label: string;
  }[];
  onChange: (value: any) => void;
}

export interface PageActionBarData {
  filters: {
    pickers?: Picker[];
    switches?: Switch[];
    multiselects?: Multiselect[];
    comboboxes?: Combobox[];
  };
  actions?: Button[];
}

interface Props {
  className?: string;
  pageActionBarData: PageActionBarData;
}
/**
 * PageActionBar component
 *
 * COMBOBOXES ARE ITEMS ... PICKERS ARE COMPANIES ... ONLY ... TODO refactor
 */
export const PageActionBar = ({ className, pageActionBarData }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleTabChange = (href: string) => navigate(href);

  return (
    <div className={cn("flex items-center justify-between w-full border-b px-2.5 py-1", className)}>
      <div className="flex items-center gap-2.5 flex-wrap">
        {pageActionBarData.filters.comboboxes?.map((combobox, idx) => <ItemPicker key={idx} {...combobox} />)}
        {pageActionBarData.filters.pickers?.map((elem, idx) => <CompanyPicker key={idx} onChange={elem.onChange} />)}
        {pageActionBarData.filters.switches?.map((elem, idx) => (
          <div className="flex gap-5 items-center" key={idx}>
            {elem.type === "button" ? (
              <Tabs value={elem.value ? elem.data[1].href : elem.data[0].href} onValueChange={elem.onChange}>
                <TabsList>
                  {elem.data.map((item, index) => (
                    <TabsTrigger key={index} value={item.href}>
                      {item.label}
                    </TabsTrigger>
                  ))}
                </TabsList>
              </Tabs>
            ) : (
              <Tabs
                value={
                  elem.data.find((item) => {
                    const [pathname, search] = item.href?.split("?");

                    if (search) {
                      const pathnameMatch = location.pathname.endsWith(pathname);
                      const searchMatch = location.search.endsWith(search);
                      return pathnameMatch && searchMatch;
                    }

                    return location.pathname.endsWith(item.href);
                  })?.href || elem.data[0].href
                }
                onValueChange={handleTabChange}
              >
                <TabsList>
                  {elem.data.map((item, index) => (
                    <TabsTrigger key={index} value={item.href}>
                      {item.label}
                    </TabsTrigger>
                  ))}
                </TabsList>
              </Tabs>
            )}
          </div>
        ))}
        {pageActionBarData.filters.multiselects?.map((elem, idx) => (
          <div className="max-w-[50%]" key={idx}>
            <MultiSelect
              placeholder={elem.placeholder}
              values={elem.values}
              options={elem.options}
              onValueChange={elem.onValueChange}
            />
          </div>
        ))}
      </div>

      {pageActionBarData.actions?.map((elem, idx) => (
        <Button key={idx} disabled={elem.isLoading || elem.disabledData?.length === 0} onClick={() => elem.onClick()}>
          {elem.icon}
          {elem.title}
        </Button>
      ))}
    </div>
  );
};
