import { useEffect, useMemo, useState } from "react";
import { Card, CardContent, CardHeader, cn } from "@androshq/shared-ui";
import { BinaryOperator, Filter, TimeDimensionGranularity } from "@cubejs-client/core";
import { AgChartOptions } from "ag-charts-community";
import { ItemWithConfig } from "../../../api/types";
import { SupportedGranularityOptions } from "../../tables/standards";
import CardDateRangePicker from "../inputs/CardDateRangePicker";
import CardGranularitySelect from "../inputs/CardGranularitySelect";
import CardSelectItem from "../inputs/CardItemSelect";
import { useFetchMetrics } from "./hooks/useFetchMetrics";

interface Props {
  title: string;
  measures: string[];
  itemList?: ItemWithConfig[];
  granularity: SupportedGranularityOptions;
  granularityIsFixed?: boolean;
  dateRange: [Date, Date];
  dateRangeIsFixed?: boolean;
  contentComponent: React.ComponentType<{
    data: Record<string, number | string | Date>[];
    series: {
      xKey: string;
      yKey: string;
      yName: string;
    }[];
    options: Partial<AgChartOptions>;
    isLoading: boolean;
  }>;
  className?: string | undefined;
}

export default function MetricsCard(props: Props) {
  const [item, setItem] = useState<ItemWithConfig | null>(null);
  const [dateRange, setDateRange] = useState<[Date, Date] | undefined>(props.dateRange);
  const [granularity, setGranularity] = useState<TimeDimensionGranularity | undefined>(props.granularity);

  const filters: Filter[] = useMemo(() => {
    return [
      {
        member: "items.id",
        operator: "equals" as BinaryOperator,
        values: [item ? item.id.toString() : "-1"],
      },
    ];
  }, [item]);

  const { data, series, axes, otherOptions, isMetricsLoading } = useFetchMetrics(
    props.measures,
    dateRange,
    granularity,
    filters,
  );

  const handleItemChange = (value: string) => {
    const newItem = props.itemList?.find((element) => element.name === value);

    if (newItem) {
      setItem(newItem);
    }
  };

  useEffect(() => {
    setItem(props.itemList?.length ? props.itemList[0]! : null);
  }, [props.itemList]);

  return (
    <Card className={cn("flex flex-1 flex-col min-h-[350px] overflow-hidden", props.className)}>
      <CardHeader className="p-4 pb-0 z-10">
        <div className="flex flex-col gap-2">
          <h3 className="text-base font-semibold">{props.title}</h3>
          <div className="flex flex-col gap-3">
            <div
              className={cn("flex gap-1.5 flex-wrap transition", {
                "opacity-50 pointer-events-none": isMetricsLoading,
              })}
            >
              <CardDateRangePicker
                dateRange={dateRange ? { from: dateRange[0], to: dateRange[1] } : undefined}
                granularity={granularity}
                onChange={(value) => setDateRange(value ? [value?.from, value?.to] : undefined)}
                fixed={props.dateRangeIsFixed ?? true}
              />
              <CardGranularitySelect
                granularity={granularity}
                handleValueChange={setGranularity}
                fixed={props.granularityIsFixed ? true : false}
              />
              {item &&
                (props.itemList && props.itemList?.length < 2 ? (
                  <div className="w-fit h-6 px-2 py-1 bg-zinc-100 rounded-md shadow-none border-none text-xs transition">
                    {props.itemList[0]?.name}
                  </div>
                ) : (
                  <CardSelectItem value={item} handleValueChange={handleItemChange} elemList={props.itemList} />
                ))}
            </div>
          </div>
        </div>
      </CardHeader>
      <CardContent className="flex-1 p-0 overflow-auto">
        <props.contentComponent
          data={data}
          series={series}
          options={{
            axes: axes,
            autoSize: true,
            ...otherOptions,
          }}
          isLoading={isMetricsLoading}
        />
      </CardContent>
    </Card>
  );
}
