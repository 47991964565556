import { format, startOfWeek } from "date-fns";
import moment from "moment";
import { nanoid } from "nanoid";
import { dateFormatShort } from "../constants";

export const getStartOfDay = (date: Date): Date => {
  const d = new Date(date);
  d.setHours(0, 0, 0, 0);
  return d;
};

export const getFutureMonday = (weeks: number): string => {
  const currentDate = new Date();

  const futureDate = new Date(currentDate.getTime() + weeks * 7 * 24 * 60 * 60 * 1000); // get date W weeks from today

  const day = futureDate.getDay();
  const diff = day === 0 ? 6 : day - 1; // find the number of days to previous Monday

  const futureMonday = new Date(futureDate.getTime() - diff * 24 * 60 * 60 * 1000); // get Monday of the week

  return futureMonday.toISOString().substring(0, 10); // format date as YYYY-MM-DD
};

export const getWeekNumberSinceEpoch = (date: Date) => {
  const weekStart = startOfWeek(date, { weekStartsOn: 1 });
  return Math.floor(weekStart.getTime() / (1000 * 60 * 60 * 24 * 7));
};

export const getDateObj = (date: Date | string | undefined) => {
  if (!date) return null;
  if (typeof date === "object") return date;
  if (typeof date === "string") {
    if (date.length === 10) {
      return new Date(`${date}T00:00:00`);
    } else if (date.length >= 19) {
      return new Date(date.split(".")[0]);
    }
    return new Date(date);
  }
  return date;
};

export const getISODateString = (date: Date) => format(date, "yyyy-MM-dd");

export const formatDateFromNow = (date: moment.MomentInput): string => moment(date).fromNow();

export const formatDateFromNowByMin = (date: moment.MomentInput): string =>
  moment.min(moment(date), moment()).fromNow();

export const formatDeltaNumeric = (value: number) => {
  if (value > 0) return `+${value}`;
  if (value < 0) return `${value}`;
  if (value == 0) return 0;
};

export const formatDateShort = (date: string | undefined | Date) => {
  if (!date || date === "Invalid Date") return "-";

  try {
    let toFormat = date;
    if (typeof toFormat === "string" && toFormat.length > 19) {
      toFormat = toFormat.split(".")[0];
    }
    return format(toFormat as string, dateFormatShort);
  } catch (e: unknown) {
    console.debug(`Caught error in date formatting for "${date}"; returning "-"`);
    return "-";
  }
};

export const formatCellDate = (params: any) => formatDateShort(params.value);

// Accepts any value and returns string or fallback "-" if passed values is not convertable to string
export const toString = (str: any, fallback?: string) => str?.toString() ?? fallback ?? "-";

export const toISOString = (date: Date | string | undefined): string | undefined => {
  if (!date) return;
  return new Date(date).toISOString();
};

export const generateId = () => nanoid();

export const removeDuplicatesBy = (array: any[], key?: string) => {
  const seen = new Set();
  const uniqueArray = [];

  for (const item of array) {
    const identifier = key ? item[key] : item;

    if (!seen.has(identifier)) {
      seen.add(identifier);
      uniqueArray.push(item);
    }
  }

  return uniqueArray;
};
