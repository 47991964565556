//
// DO NOT TOUCH THIS FILE -- AUTO-GENERATED BY BACKEND
//
export enum Department {
  ANY = "",
  PCL = "pcl",
  CT = "ct",
  PLANNER = "planner",
  AM = "am",
}

export enum Region {
  GLOBAL = "global",
  CHINA = "china",
  INDIA = "india",
  VIETNAM = "vietnam",
}

export enum Level {
  MANAGER = "manager",
  VIEWER = "viewer",
  USER = "user",
}

export enum Role {
  APP_GLOBAL_VIEWER = "app::global:viewer",
  APP_GLOBAL_MANAGER = "app::global:manager",
  APP_PCL_GLOBAL_VIEWER = "app:pcl:global:viewer",
  APP_PCL_GLOBAL_MANAGER = "app:pcl:global:manager",
  APP_PCL_CHINA_VIEWER = "app:pcl:cn:viewer",
  APP_PCL_CHINA_MANAGER = "app:pcl:cn:manager",
  APP_PCL_CHINA_USER = "app:pcl:cn:user",
  APP_PCL_VIETNAM_VIEWER = "app:pcl:vn:viewer",
  APP_PCL_VIETNAM_MANAGER = "app:pcl:vn:manager",
  APP_PCL_VIETNAM_USER = "app:pcl:vn:user",
  APP_PCL_INDIA_VIEWER = "app:pcl:in:viewer",
  APP_PCL_INDIA_MANAGER = "app:pcl:in:manager",
  APP_PCL_INDIA_USER = "app:pcl:in:user",
  APP_CONTROLTOWER_GLOBAL_MANAGER = "app:ct:global:manager",
  APP_PLANNER_GLOBAL_MANAGER = "app:planner:global:manager",
  APP_ACCOUNTMGR_GLOBAL_VIEWER = "app:am:global:viewer",
  SOP_COMPANIES_READ = "sop:companies:read",
  SOP_ITEMS_READ = "sop:items:read",
  SOP_STATES_READ = "sop:states:read",
  SOP_STATES_CREATE = "sop:states:create",
  SOP_TRANSITIONS_CREATE = "sop:transitions:create",
  SOP_TRANSITIONS_READ = "sop:transitions:read",
}

export enum Permission {
  APP_VIEWS_DASHBOARD = "app:views:dashboard",
  APP_VIEWS_COMPANIES = "app:views:companies",
  APP_VIEWS_WORKFLOWS = "app:views:workflows",
  APP_VIEWS_ITEMS = "app:views:items",
  APP_VIEWS_SEARCH = "app:views:search",
  APP_DATA_WORKFLOWS_SIGNAL = "app:data:workflows:signal",
  APP_DATA_PURCHASE_CREATE = "app:data:purchase:create",
  APP_DATA_PURCHASE_EDIT = "app:data:purchase:edit",
  APP_DATA_PURCHASE_CANCEL = "app:data:purchase:cancel",
  APP_DATA_PURCHASE_APPROVE = "app:data:purchase:approve",
  APP_DATA_PURCHASE_SYNC = "app:data:purchase:sync",
  APP_DATA_FORECAST_EDIT = "app:data:forecast:edit",
  APP_DATA_CONFIG_EDIT = "app:data:config:edit",
  APP_DATA_USERS_READ = "app:data:users:read",
  APP_DATA_METRICS_READ = "app:data:metrics:read",
  APP_DATA_LOCATIONS_READ = "app:data:locations:read",
  APP_DATA_VENDORS_READ = "app:data:vendors:read",
  APP_DATA_COMPANIES_READ = "app:data:companies:read",
  APP_DATA_ITEMS_READ = "app:data:items:read",
  APP_DATA_WORKFLOWS_READ = "app:data:workflows:read",
}

export const rolePermissionsMap: {
  [key in Role]?: Permission[];
} = {
  [Role.APP_GLOBAL_VIEWER]: [
    Permission.APP_DATA_LOCATIONS_READ,
    Permission.APP_DATA_VENDORS_READ,
    Permission.APP_DATA_METRICS_READ,
    Permission.APP_DATA_COMPANIES_READ,
    Permission.APP_DATA_ITEMS_READ,
    Permission.APP_DATA_WORKFLOWS_READ,
    Permission.APP_DATA_USERS_READ,
    Permission.APP_VIEWS_DASHBOARD,
    Permission.APP_VIEWS_COMPANIES,
    Permission.APP_VIEWS_WORKFLOWS,
    Permission.APP_VIEWS_ITEMS,
    Permission.APP_VIEWS_SEARCH,
  ],
  [Role.APP_GLOBAL_MANAGER]: [
    Permission.APP_VIEWS_DASHBOARD,
    Permission.APP_VIEWS_COMPANIES,
    Permission.APP_VIEWS_WORKFLOWS,
    Permission.APP_VIEWS_ITEMS,
    Permission.APP_VIEWS_SEARCH,
    Permission.APP_DATA_WORKFLOWS_SIGNAL,
    Permission.APP_DATA_PURCHASE_CREATE,
    Permission.APP_DATA_PURCHASE_EDIT,
    Permission.APP_DATA_PURCHASE_CANCEL,
    Permission.APP_DATA_PURCHASE_APPROVE,
    Permission.APP_DATA_PURCHASE_SYNC,
    Permission.APP_DATA_FORECAST_EDIT,
    Permission.APP_DATA_CONFIG_EDIT,
    Permission.APP_DATA_USERS_READ,
    Permission.APP_DATA_METRICS_READ,
    Permission.APP_DATA_LOCATIONS_READ,
    Permission.APP_DATA_VENDORS_READ,
    Permission.APP_DATA_COMPANIES_READ,
    Permission.APP_DATA_ITEMS_READ,
    Permission.APP_DATA_WORKFLOWS_READ,
  ],
  [Role.APP_PCL_GLOBAL_VIEWER]: [
    Permission.APP_DATA_LOCATIONS_READ,
    Permission.APP_DATA_VENDORS_READ,
    Permission.APP_DATA_METRICS_READ,
    Permission.APP_DATA_COMPANIES_READ,
    Permission.APP_DATA_ITEMS_READ,
    Permission.APP_DATA_WORKFLOWS_READ,
    Permission.APP_DATA_USERS_READ,
    Permission.APP_VIEWS_WORKFLOWS,
  ],
  [Role.APP_PCL_GLOBAL_MANAGER]: [
    Permission.APP_DATA_LOCATIONS_READ,
    Permission.APP_DATA_VENDORS_READ,
    Permission.APP_DATA_METRICS_READ,
    Permission.APP_DATA_COMPANIES_READ,
    Permission.APP_DATA_ITEMS_READ,
    Permission.APP_DATA_WORKFLOWS_READ,
    Permission.APP_DATA_USERS_READ,
    Permission.APP_VIEWS_WORKFLOWS,
    Permission.APP_DATA_WORKFLOWS_SIGNAL,
    Permission.APP_DATA_PURCHASE_EDIT,
    Permission.APP_DATA_PURCHASE_APPROVE,
    Permission.APP_DATA_PURCHASE_SYNC,
  ],
  [Role.APP_PCL_CHINA_VIEWER]: [
    Permission.APP_DATA_LOCATIONS_READ,
    Permission.APP_DATA_VENDORS_READ,
    Permission.APP_DATA_METRICS_READ,
    Permission.APP_DATA_COMPANIES_READ,
    Permission.APP_DATA_ITEMS_READ,
    Permission.APP_DATA_WORKFLOWS_READ,
    Permission.APP_DATA_USERS_READ,
    Permission.APP_VIEWS_WORKFLOWS,
  ],
  [Role.APP_PCL_CHINA_MANAGER]: [
    Permission.APP_DATA_LOCATIONS_READ,
    Permission.APP_DATA_VENDORS_READ,
    Permission.APP_DATA_METRICS_READ,
    Permission.APP_DATA_COMPANIES_READ,
    Permission.APP_DATA_ITEMS_READ,
    Permission.APP_DATA_WORKFLOWS_READ,
    Permission.APP_DATA_USERS_READ,
    Permission.APP_VIEWS_WORKFLOWS,
    Permission.APP_DATA_WORKFLOWS_SIGNAL,
    Permission.APP_DATA_PURCHASE_EDIT,
    Permission.APP_DATA_PURCHASE_APPROVE,
    Permission.APP_DATA_PURCHASE_SYNC,
  ],
  [Role.APP_PCL_CHINA_USER]: [
    Permission.APP_DATA_LOCATIONS_READ,
    Permission.APP_DATA_VENDORS_READ,
    Permission.APP_DATA_METRICS_READ,
    Permission.APP_DATA_COMPANIES_READ,
    Permission.APP_DATA_ITEMS_READ,
    Permission.APP_DATA_WORKFLOWS_READ,
    Permission.APP_DATA_USERS_READ,
    Permission.APP_VIEWS_WORKFLOWS,
    Permission.APP_DATA_WORKFLOWS_SIGNAL,
    Permission.APP_DATA_PURCHASE_EDIT,
    Permission.APP_DATA_PURCHASE_APPROVE,
    Permission.APP_DATA_PURCHASE_SYNC,
  ],
  [Role.APP_PCL_VIETNAM_VIEWER]: [
    Permission.APP_DATA_LOCATIONS_READ,
    Permission.APP_DATA_VENDORS_READ,
    Permission.APP_DATA_METRICS_READ,
    Permission.APP_DATA_COMPANIES_READ,
    Permission.APP_DATA_ITEMS_READ,
    Permission.APP_DATA_WORKFLOWS_READ,
    Permission.APP_DATA_USERS_READ,
    Permission.APP_VIEWS_WORKFLOWS,
  ],
  [Role.APP_PCL_VIETNAM_MANAGER]: [
    Permission.APP_DATA_LOCATIONS_READ,
    Permission.APP_DATA_VENDORS_READ,
    Permission.APP_DATA_METRICS_READ,
    Permission.APP_DATA_COMPANIES_READ,
    Permission.APP_DATA_ITEMS_READ,
    Permission.APP_DATA_WORKFLOWS_READ,
    Permission.APP_DATA_USERS_READ,
    Permission.APP_VIEWS_WORKFLOWS,
    Permission.APP_DATA_WORKFLOWS_SIGNAL,
    Permission.APP_DATA_PURCHASE_EDIT,
    Permission.APP_DATA_PURCHASE_APPROVE,
    Permission.APP_DATA_PURCHASE_SYNC,
  ],
  [Role.APP_PCL_VIETNAM_USER]: [
    Permission.APP_DATA_LOCATIONS_READ,
    Permission.APP_DATA_VENDORS_READ,
    Permission.APP_DATA_METRICS_READ,
    Permission.APP_DATA_COMPANIES_READ,
    Permission.APP_DATA_ITEMS_READ,
    Permission.APP_DATA_WORKFLOWS_READ,
    Permission.APP_DATA_USERS_READ,
    Permission.APP_VIEWS_WORKFLOWS,
    Permission.APP_DATA_WORKFLOWS_SIGNAL,
    Permission.APP_DATA_PURCHASE_EDIT,
    Permission.APP_DATA_PURCHASE_APPROVE,
    Permission.APP_DATA_PURCHASE_SYNC,
  ],
  [Role.APP_PCL_INDIA_VIEWER]: [
    Permission.APP_DATA_LOCATIONS_READ,
    Permission.APP_DATA_VENDORS_READ,
    Permission.APP_DATA_METRICS_READ,
    Permission.APP_DATA_COMPANIES_READ,
    Permission.APP_DATA_ITEMS_READ,
    Permission.APP_DATA_WORKFLOWS_READ,
    Permission.APP_DATA_USERS_READ,
    Permission.APP_VIEWS_WORKFLOWS,
  ],
  [Role.APP_PCL_INDIA_MANAGER]: [
    Permission.APP_DATA_LOCATIONS_READ,
    Permission.APP_DATA_VENDORS_READ,
    Permission.APP_DATA_METRICS_READ,
    Permission.APP_DATA_COMPANIES_READ,
    Permission.APP_DATA_ITEMS_READ,
    Permission.APP_DATA_WORKFLOWS_READ,
    Permission.APP_DATA_USERS_READ,
    Permission.APP_VIEWS_WORKFLOWS,
    Permission.APP_DATA_WORKFLOWS_SIGNAL,
    Permission.APP_DATA_PURCHASE_EDIT,
    Permission.APP_DATA_PURCHASE_APPROVE,
    Permission.APP_DATA_PURCHASE_SYNC,
  ],
  [Role.APP_PCL_INDIA_USER]: [
    Permission.APP_DATA_LOCATIONS_READ,
    Permission.APP_DATA_VENDORS_READ,
    Permission.APP_DATA_METRICS_READ,
    Permission.APP_DATA_COMPANIES_READ,
    Permission.APP_DATA_ITEMS_READ,
    Permission.APP_DATA_WORKFLOWS_READ,
    Permission.APP_DATA_USERS_READ,
    Permission.APP_VIEWS_WORKFLOWS,
    Permission.APP_DATA_WORKFLOWS_SIGNAL,
    Permission.APP_DATA_PURCHASE_EDIT,
    Permission.APP_DATA_PURCHASE_APPROVE,
    Permission.APP_DATA_PURCHASE_SYNC,
  ],
  [Role.APP_CONTROLTOWER_GLOBAL_MANAGER]: [
    Permission.APP_DATA_LOCATIONS_READ,
    Permission.APP_DATA_VENDORS_READ,
    Permission.APP_DATA_METRICS_READ,
    Permission.APP_DATA_COMPANIES_READ,
    Permission.APP_DATA_ITEMS_READ,
    Permission.APP_DATA_WORKFLOWS_READ,
    Permission.APP_DATA_USERS_READ,
    Permission.APP_VIEWS_DASHBOARD,
    Permission.APP_VIEWS_COMPANIES,
    Permission.APP_VIEWS_WORKFLOWS,
    Permission.APP_VIEWS_ITEMS,
    Permission.APP_VIEWS_SEARCH,
    Permission.APP_DATA_WORKFLOWS_SIGNAL,
    Permission.APP_DATA_PURCHASE_EDIT,
    Permission.APP_DATA_PURCHASE_APPROVE,
    Permission.APP_DATA_PURCHASE_SYNC,
  ],
  [Role.APP_PLANNER_GLOBAL_MANAGER]: [
    Permission.APP_DATA_LOCATIONS_READ,
    Permission.APP_DATA_VENDORS_READ,
    Permission.APP_DATA_METRICS_READ,
    Permission.APP_DATA_COMPANIES_READ,
    Permission.APP_DATA_ITEMS_READ,
    Permission.APP_DATA_WORKFLOWS_READ,
    Permission.APP_DATA_USERS_READ,
    Permission.APP_VIEWS_DASHBOARD,
    Permission.APP_VIEWS_COMPANIES,
    Permission.APP_VIEWS_WORKFLOWS,
    Permission.APP_VIEWS_ITEMS,
    Permission.APP_VIEWS_SEARCH,
    Permission.APP_DATA_WORKFLOWS_SIGNAL,
    Permission.APP_DATA_PURCHASE_CREATE,
    Permission.APP_DATA_PURCHASE_EDIT,
    Permission.APP_DATA_PURCHASE_APPROVE,
    Permission.APP_DATA_PURCHASE_SYNC,
    Permission.APP_DATA_PURCHASE_CANCEL,
  ],
  [Role.APP_ACCOUNTMGR_GLOBAL_VIEWER]: [
    Permission.APP_DATA_LOCATIONS_READ,
    Permission.APP_DATA_VENDORS_READ,
    Permission.APP_DATA_METRICS_READ,
    Permission.APP_DATA_COMPANIES_READ,
    Permission.APP_DATA_ITEMS_READ,
    Permission.APP_DATA_WORKFLOWS_READ,
    Permission.APP_DATA_USERS_READ,
    Permission.APP_VIEWS_DASHBOARD,
    Permission.APP_VIEWS_COMPANIES,
    Permission.APP_VIEWS_WORKFLOWS,
    Permission.APP_VIEWS_ITEMS,
    Permission.APP_VIEWS_SEARCH,
  ],
  [Role.SOP_COMPANIES_READ]: [],
  [Role.SOP_ITEMS_READ]: [],
  [Role.SOP_STATES_READ]: [],
  [Role.SOP_STATES_CREATE]: [],
  [Role.SOP_TRANSITIONS_CREATE]: [],
  [Role.SOP_TRANSITIONS_READ]: [],
};
