import { useEffect } from "react";
import {
  LoginPage,
  NoRoleAssignedPage,
  useAuth,
} from "@androshq/auth/browser-client";
import { BrowserRouter, Navigate, useRoutes } from "react-router-dom";
import { useModulesWithPermission } from "../hooks";
import DashboardLayout from "../layouts/DashboardLayout";
import Logout from "../pages/Logout";
import NotFound from "../pages/NotFound";
import { checkUserForPermissions } from "@androshq/auth/common";
import * as monitor from "../utils/monitor";

const AppRouter = () => {
  const { user } = useAuth();
  const modulesWithPermission = useModulesWithPermission();

  useEffect(() => {
    if (user) {
      monitor.setUser(user);
    }
  }, [user]);

  const allRouters = modulesWithPermission.flatMap((module) => module.router);

  const sectionsWithPermission = modulesWithPermission.flatMap((module) => {
    return module.sections.filter((section) => {
      return checkUserForPermissions(user, [section.authSpec.permission])
        .allowed;
    });
  });

  const filteredRoutes = allRouters.filter((route) =>
    sectionsWithPermission.some((section) => section.path === route.path),
  );

  const routes = useRoutes([
    {
      path: "/",
      element: <DashboardLayout />,
      children: [
        ...filteredRoutes,
        { path: "no-role-assigned", element: <NoRoleAssignedPage /> },
        { path: "*", element: <Navigate to={"/"} replace /> },
      ],
    },
    { path: "/signout-callback", element: null },
    { path: "/auth-callback", element: null },

    {
      path: "/login",
      element: <LoginPage />,
    },
    {
      path: "/logout",
      element: <Logout />,
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ]);

  return routes;
};

export default function Router() {
  return (
    <BrowserRouter>
      <AppRouter />
    </BrowserRouter>
  );
}
