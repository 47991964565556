import { useQuery, useQueryClient } from "@tanstack/react-query";
import { sopApiClient } from "../../api/client";
import { WorkflowExecutionsSearchQueryParams } from "../../api/client";

export const queryKeyFactory = (params: any) => ["workflow-executions", params];

export const useFetchWorkflowExecutions = (params?: WorkflowExecutionsSearchQueryParams) => {
  const queryClient = useQueryClient();
  const queryKey = queryKeyFactory(params);

  const invalidate = () => queryClient.invalidateQueries({ queryKey });

  const query = useQuery({
    queryKey,
    queryFn: async () => {
      const response = await sopApiClient.workflows.executions.search(params);
      if (!response.success) throw new Error(response.message);
      return response.data;
    },
    meta: { cache: false },
  });

  return { ...query, invalidate };
};

export const useFetchWorkflowExecution = (workflow_id: string | undefined, workflow_run_id: string | undefined) => {
  const filters = {
    filters: {
      workflow_id: [workflow_id || ""],
      workflow_run_id: [workflow_run_id || ""],
    },
    order_by: "last_modified",
    order_by_desc: true,
    limit: 1,
    offset: 0,
  };
  //@ts-expect-error order_by for some reasons buggy, and return error
  return useFetchWorkflowExecutions(filters);
};
