import { useMemo } from "react";
import { DateRange, Query, ResultSet } from "@cubejs-client/core";
import { useCubeQuery } from "@cubejs-client/react";
import { format, startOfWeek } from "date-fns";
import _ from "lodash";
import { GetForecastRecommendationsParams } from "../../../api/client";
import { formatDateRangeAsIntervalString, getTSColWeekMinMax } from "../../../components/tables/standards";

export const ROLLFORWARD_TS_COL_QTY = 24;
export const ROLLFORWARD_TS_MIN_WEEK_NUM = 1;

const aggregateDataByItem = (data: any[]) => {
  if (!data) return undefined;
  const result: any = {};

  data?.forEach((row) => {
    const { week, item, inflow, outflow, endingBalance, ...rest } = row;

    if (!result[item.id as number]) {
      result[item.id as number] = { item, ...rest };
    }

    result[item.id as number][week] = { inflow, outflow, endingBalance };
  });

  return Object.values(result);
};

export const mapDataFromResultSet = (resultSet: ResultSet) => {
  return resultSet
    ?.tablePivot()
    .filter((i) => i["inventory_rollforward.date.week"])
    .map((i) => ({
      week: format(
        startOfWeek(new Date(i["inventory_rollforward.date.week"] as string), { weekStartsOn: 1 }),
        "yyyy-MM-dd",
      ),

      item: {
        id: i["inventory_rollforward.item_id"],
        name: i["inventory_rollforward.item_name"],
      },
      company: {
        id: i["inventory_rollforward.company_id"],
        name: i["inventory_rollforward.company_name"],
      },

      minInvQty: i["inventory_rollforward.item_inventory_quantity_min"],
      dateUnderMinQty: i["inventory_rollforward.first_insufficient_balance_date"],
      leadTimeDays: i["inventory_rollforward.lead_time_days"],
      dailyExcessWt: i["inventory_rollforward.wtd_excess_balance_avg_per_day"],

      inflow: +i["inventory_rollforward.inflow"],
      outflow: +i["inventory_rollforward.outflow"],
      endingBalance: +i["inventory_rollforward.ending_balance"],
    }));
};

export const useFetchInventoryRollforward = (params?: GetForecastRecommendationsParams) => {
  const companyIds = params?.companyIds || [];
  const granularity = "week";
  const dateRange: DateRange = useMemo(() => {
    const minMax = getTSColWeekMinMax(ROLLFORWARD_TS_MIN_WEEK_NUM, ROLLFORWARD_TS_COL_QTY);
    return formatDateRangeAsIntervalString([minMax.start.date, minMax.end.date], granularity);
  }, []);

  const query: Query = {
    measures: [
      "inventory_rollforward.inflow", // +# (plus sign before number)
      "inventory_rollforward.outflow", // -# (negative sign before number)
      "inventory_rollforward.ending_balance", // # (just the number)
    ],
    dimensions: [
      "inventory_rollforward.item_id", // Item ID
      "inventory_rollforward.item_name", // Item Name
      "inventory_rollforward.company_id", // Company ID
      "inventory_rollforward.company_name", // Company Name
      "inventory_rollforward.item_inventory_quantity_min", // Min Inv Qty
      "inventory_rollforward.first_insufficient_balance_date", // Date Under Min Qty
      "inventory_rollforward.lead_time_days", // Lead Time Days
      "inventory_rollforward.wtd_excess_balance_avg_per_day", // Daily Excess Wt (MT)
    ],
    timeDimensions: [
      {
        dimension: "inventory_rollforward.date",
        granularity,
        dateRange,
      },
    ],
    order: [
      [
        "inventory_rollforward.first_insufficient_balance_date", // Rows are ordered by this (date when item runs out of inventory)
        "asc",
      ],
      [
        "inventory_rollforward.item_id", // This is to make sure items aren't mixed in order
        "asc",
      ],
      [
        "inventory_rollforward.date", // Column order
        "asc",
      ],
    ],
    filters: [
      {
        member: "inventory_rollforward.company_id",
        operator: "equals",
        values: companyIds,
      },
      {
        member: "inventory_rollforward.first_insufficient_balance_date",
        operator: "afterDate",
        values: ["0"],
      },
    ],
  };

  const { resultSet, ...cube } = useCubeQuery(query, { resetResultSetOnChange: true });

  const mappedData = aggregateDataByItem(mapDataFromResultSet(resultSet!));

  return { query, data: mappedData, ...cube };
};
