import { ApiClientResponse } from "@androshq/core/browser-client";
import { ExternalToast, toast } from "sonner";
import {
  CAWorkflowEventRequestData,
  CAWorkflowExecution,
  EventTypeEnum,
} from "../pages/workflows/changeApprovals/types";
import { ERPWriteWorkflowEventRequestData } from "../pages/workflows/erpWrite/types";
import { WorkflowName } from "../pages/workflows/types";

export const beforeCAWorkflowSignal = (payload: CAWorkflowEventRequestData | ERPWriteWorkflowEventRequestData) => {
  let message = "Loading...";
  if (payload.workflow_type_name === WorkflowName.ChangeApprovalsWorkflow) {
    if (payload.workflow_event_type == EventTypeEnum.CREATE) {
      message = "Creating requisition & new approval workflow...";
    } else if (payload.workflow_event_type == EventTypeEnum.EDIT) {
      message = "Saving edit & assigning to next approver...";
    } else if (payload.workflow_event_type == EventTypeEnum.APPROVE) {
      message = "Recording approval & assigning to next approver...";
    } else if (payload.workflow_event_type == EventTypeEnum.CANCEL) {
      message = "Cancelling workflow & reverting row to original state...";
    }
  } else if (payload.workflow_type_name === WorkflowName.ERPWriteWorkflow) {
    message = "The sync process is in progress...";
  }
  return toast.loading(message);
};

export const afterCAWorkflowSignal = (
  toastId: number | string,
  payload: CAWorkflowEventRequestData | ERPWriteWorkflowEventRequestData,
  res: ApiClientResponse<CAWorkflowExecution>,
) => {
  let message = "Action complete";
  let toastOptions: ExternalToast = {
    id: toastId,
    duration: 4000,
  };
  let toastType: (message: string | React.ReactNode, data?: ExternalToast) => string | number = toast;
  if (res.success) {
    toastType = toast.success;
    if (payload.workflow_type_name === WorkflowName.ChangeApprovalsWorkflow) {
      message = "Action successful";
      const approver_fullname = `${res.data.search_attributes.current_approver?.given_name} ${res.data.search_attributes.current_approver?.family_name}`;
      const approval_status = res.data.search_attributes.approval_status;
      if (payload.workflow_event_type == EventTypeEnum.CREATE) {
        message = `Requisition created & assigned to ${approver_fullname} for approval.`;
      } else if (payload.workflow_event_type == EventTypeEnum.EDIT) {
        if (approval_status === "AWAITING_APPROVAL") {
          const numApproved = res.data.search_attributes.approvers.filter(
            (approver) => approver.approved === true,
          ).length;
          if (numApproved > 1) {
            message = `Edit saved and was within tolerance to bypass reapproval. Now assigned to ${approver_fullname}.`;
          } else {
            message = `Edit saved and was outside of tolerance. Now assigned to ${approver_fullname} for reapproval.`;
          }
        } else {
          message = `Edit saved and was within tolerance to bypass reapproval. Row is now fully approved & pending sync to ERP.`;
        }
      } else if (payload.workflow_event_type == EventTypeEnum.APPROVE) {
        if (approval_status === "AWAITING_APPROVAL") {
          message = `Approval recorded; now assigned to ${approver_fullname} for approval.`;
        } else if (approval_status === "APPROVED__SYNC_READY") {
          message = `Approval recorded; row is fully approved & pending sync to ERP.`;
        }
      } else if (payload.workflow_event_type == EventTypeEnum.CANCEL) {
        message = "Workflow cancelled & row reverted to original state.";
      }
    } else if (payload.workflow_type_name === WorkflowName.ERPWriteWorkflow) {
      message = "The sync successfully started. Refresh in 10-20 seconds to check result.";
    }
  } else {
    message = res.message;
    toastType = toast.warning;
    toastOptions = {
      ...toastOptions,
      duration: 8000,
      action: {
        label: "Dismiss",
        onClick: () => {},
      },
    };
  }
  toastType(message, toastOptions);
};
