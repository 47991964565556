import { CAWorkflowEventData } from "../../../types";
import { EventTypeEnum } from "../../../types";

export const parseMemo = (memo: string) => {
  const lines = memo.split("\n").filter((line) => line.trim() !== "");
  const initialLine = lines[0];
  const approvers = lines
    .slice(2)
    .map((line) => {
      const match = line.match(/-\s+(\w+):\s+(\S+)(\s+\((\w+)\))?/);
      if (match) {
        return {
          role: match[1],
          email: match[2],
          status: match[4] || "PENDING",
        };
      }
      return null;
    })
    .filter((approver) => approver !== null);

  return { initialLine, approvers };
};

interface Props {
  eventData: CAWorkflowEventData;
}

export default function RenderedMemo({ eventData }: Props) {
  if (!eventData.memo)
    return (
      <div className="text-sm">
        <div className="text-gray-500">No memo</div>
      </div>
    );
  if (eventData.workflow_event_type !== EventTypeEnum.START)
    return (
      <div className="text-sm">
        <div className="text-gray-800">{eventData.memo}</div>
      </div>
    );
  const parsedMemo = parseMemo(eventData.memo);
  return (
    <div className="text-sm">
      <div className="flex flex-col gap-0.5">
        <p>{parsedMemo.initialLine}</p>
        <h3>Approvers:</h3>
        <ul className="list-disc">
          {parsedMemo.approvers.map((approver, index) => (
            <li className="ml-10" key={index}>
              <div className="flex gap-2">
                <span className="font-semibold">{approver.role}:</span> {approver.email}{" "}
                <span>({approver.status})</span>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
