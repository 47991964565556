import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootStateInterface, sopApiClient } from "..";
import { StateTransitionFull } from "../api/types";

type SupplyChainState = {
  data: StateTransitionFull[];
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
};

export const fetchTransitions = createAsyncThunk(
  "transitions/fetchTransitions",
  async (ids: { itemId: number; stateId?: number }, { rejectWithValue }) => {
    const { itemId, stateId } = ids;
    try {
      const data = await sopApiClient.items.item.getStateTransitions(itemId, stateId);
      if (!data.success) throw new Error(data.message);
      return data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const initialState: SupplyChainState = {
  data: [],
  status: "idle",
  error: null,
};

const supplyChainSlice = createSlice({
  name: "transitions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTransitions.pending, (state) => {
        state.error = null;
        state.status = "loading";
        state.data = [];
      })
      .addCase(fetchTransitions.fulfilled, (state, action: PayloadAction<StateTransitionFull[]>) => {
        state.status = "succeeded";
        state.error = null;
        state.data = action.payload;
      })
      .addCase(fetchTransitions.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message ? action.error.message : null;
      });
  },
});

export const selectSupplyChainData = (state: RootStateInterface) => state.sop.supplyChain;

export default supplyChainSlice.reducer;
