import { Permission } from "@androshq/auth/common";
import { ModuleDashboardSection } from "@androshq/core/browser-client";
import { Navigate, Outlet, RouteObject } from "react-router-dom";
import { SectionNames } from "./constants";
import { CompanyPage } from "./pages/companies";
import { CompaniesOutlet } from "./pages/companies/CompaniesOutlet";
import { CompanyDetailPage } from "./pages/companies/CompanyDetailPage";
import { DashboardPage } from "./pages/dashboard/DashboardPage";
import { ItemForecastingPage } from "./pages/items/ItemForecastingPage";
import { SupplyChainPage } from "./pages/items/ItemSupplyPlanningPage";
import { ItemsOutlet } from "./pages/items/ItemsOutlet";
import { ItemsPage } from "./pages/items/ItemsPage";
import WorkflowExecutionsPage from "./pages/workflows";
import { WorkflowExecutionsOutlet } from "./pages/workflows/WorkflowExecutionsOutlet";

export const sections: ModuleDashboardSection[] = [
  {
    name: SectionNames.Dashboard,
    path: "sop/dashboard",
    authSpec: {
      permission: Permission.APP_VIEWS_DASHBOARD,
    },
  },
  {
    name: SectionNames.Items,
    path: "sop/items",
    authSpec: {
      permission: Permission.APP_VIEWS_ITEMS,
    },
  },
  {
    name: SectionNames.Workflows,
    path: "sop/workflows",
    authSpec: {
      permission: Permission.APP_VIEWS_WORKFLOWS,
    },
  },
  {
    name: SectionNames.Companies,
    path: "sop/customers",
    authSpec: {
      permission: Permission.APP_VIEWS_COMPANIES,
    },
  },
];

export const router: RouteObject[] = [
  {
    path: "sop/dashboard",
    element: <DashboardPage />,
  },
  {
    path: "sop/workflows",
    element: <WorkflowExecutionsOutlet />,
    children: [
      {
        path: "",
        element: <WorkflowExecutionsPage />,
      },
    ],
  },
  {
    path: "sop/customers",
    element: <CompaniesOutlet />,
    children: [
      {
        path: "",
        element: <CompanyPage />,
      },
      {
        path: ":id",
        element: <CompanyDetailPage />,
      },
    ],
  },

  {
    path: "sop/items",
    element: <ItemsOutlet />,
    children: [
      {
        path: "",
        element: <ItemsPage />,
      },
      {
        path: ":id",
        element: <Outlet />,
        children: [
          {
            /*
              Solution for now, will be removed once we figure out which page to display here
              ISSUE: CEQI-79
            */
            path: "",
            element: <Navigate to="forecasting" replace />,
          },
          {
            path: "forecasting",
            element: <ItemForecastingPage />,
          },
          {
            path: "supply-chain",
            element: <SupplyChainPage />,
          },
        ],
      },
    ],
  },
];
