import { asProtectedRoute } from "@androshq/auth/browser-client";
import { DashboardProvider } from "@androshq/core/browser-client";
import { Toaster } from "@androshq/shared-ui";
import { Search } from "@androshq/sop/browser-client";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import Header from "../components/Header";
import { useModulesWithPermission } from "../hooks";
import { useAuth } from "@androshq/auth/browser-client";
import { checkUserForPermissions } from "@androshq/auth/common";

function DashboardLayout() {
  const location = useLocation();
  const modulesWithPermission = useModulesWithPermission();
  const { user } = useAuth();

  let firstModuleRoutePath = "no-role-assigned";

  if (modulesWithPermission.length) {
    const sectionsWithPermission = modulesWithPermission[0].sections.filter(
      (section) => {
        return checkUserForPermissions(user, [section.authSpec.permission])
          .allowed;
      },
    );

    firstModuleRoutePath = sectionsWithPermission[0].path;
  }

  return location.pathname === "/" ? (
    <Navigate to={firstModuleRoutePath} replace />
  ) : (
    <DashboardProvider>
      <div className="flex h-full flex-col">
        <Header />
        <Outlet />
      </div>

      <Search />
      <Toaster />
    </DashboardProvider>
  );
}

export default asProtectedRoute(DashboardLayout);
