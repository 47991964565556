import { useMemo } from "react";
import { Button } from "@androshq/shared-ui";
import _ from "lodash";
import { useItems } from "../../hooks/items/useItems";
import { ItemName } from "../labels/ItemName";
import { triggerGlobalSearchPopup } from "./GlobalSearch";

// TODO refactor so this is typed as an item object vs this generic thing, or otherwise allow custom rendering
interface Props {
  value: string;
  options: {
    value: string;
    label: string;
  }[];
  onChange: (value: any) => void;
}

const ItemPicker = ({ value }: Props) => {
  const { data: items } = useItems();

  const selectedItem = useMemo(
    () => (items ? items.find((item) => item.id == parseInt(value)) : undefined),
    [value, items],
  );

  return (
    /* @ts-ignore */
    <Button variant="outline" onClick={triggerGlobalSearchPopup} className="w-fit justify-between">
      {selectedItem ? <ItemName item={selectedItem} /> : "Select an item…"}
    </Button>
  );
};

export default ItemPicker;
