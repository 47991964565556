import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@androshq/shared-ui";
import { useNavigate } from "react-router-dom";
import { getModuleConfigByName } from "../utils/navigation";
import { useDashboardContext } from "@androshq/core/browser-client";
import { useModulesWithPermission } from "../hooks";
import { useAuth } from "@androshq/auth/browser-client";
import { checkUserForPermissions } from "@androshq/auth/common";

export default function ModuleSwitcher() {
  const navigate = useNavigate();

  const { user } = useAuth();
  const modulesWithPermissison = useModulesWithPermission();

  const moduleNames = modulesWithPermissison.map((config) => config.name);
  const { moduleName } = useDashboardContext();

  const handleValueChange = (value: string) => {
    const sectionsWithPermission = getModuleConfigByName(
      value,
    )?.sections.filter(
      (section) =>
        checkUserForPermissions(user, [section.authSpec.permission]).allowed,
    );

    const path = sectionsWithPermission && sectionsWithPermission[0].path;
    path && navigate(path);
  };

  return (
    <Select value={moduleName} onValueChange={handleValueChange}>
      <SelectTrigger className="gap-3 border-none px-0 font-medium shadow-none focus:ring-0">
        <SelectValue />
      </SelectTrigger>
      <SelectContent>
        {moduleNames.map((item) => {
          return (
            <SelectItem key={item} value={item}>
              {item}
            </SelectItem>
          );
        })}
      </SelectContent>
    </Select>
  );
}
