import { PageContent } from "../../../components/navigation/PageContent";
import ConfigurationTable from "./components/ConfigurationTable";

export const CompanyDetailPage = () => {
  return (
    <PageContent className="grid grid-cols-1 grid-rows-2 gap-2.5">
      <ConfigurationTable />
    </PageContent>
  );
};
