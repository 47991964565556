import React, { useState } from "react";
import { useAuth } from "@androshq/auth/browser-client";
import { Department, checkUserForPermissions } from "@androshq/auth/common";
import { Permission } from "@androshq/auth/common";
import {
  CheckIcon,
  CopyIcon,
  Cross2Icon,
  DotsHorizontalIcon,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
  ExclamationTriangleIcon,
  Pencil1Icon,
  PlusIcon,
  ReloadIcon,
  cn,
} from "@androshq/shared-ui";
import { GroupCellRendererParams } from "ag-grid-community";
import _ from "lodash";
import { getWarningList } from "../../../api/standards";
import { ItemConfig } from "../../../api/types";
import Tooltip from "../../../components/messages/Tooltip";
import { ApprovalStatusEnum } from "../../workflows/changeApprovals/types";

type PropsWithParams<P = unknown> = P & { params: GroupCellRendererParams };

type ActionsCellProps = {
  insert: (params: GroupCellRendererParams, mirror?: boolean) => void;
  save: (params: GroupCellRendererParams) => Promise<void>;
  cancel: (params: GroupCellRendererParams) => void;
  edit: (params: GroupCellRendererParams) => void;
  item_config: ItemConfig | undefined;
};

export const ActionsCell = (props: PropsWithParams<ActionsCellProps>) => {
  const [open, setOpen] = useState<boolean>(false);

  const { params, insert, edit } = props;
  const data = params?.data;
  const editable = data?.editable;

  const { user } = useAuth();
  const { allowed: editAllowed, reason: editReason } = checkUserForPermissions(
    user,
    [Permission.APP_DATA_PURCHASE_EDIT],
    Department.PLANNER,
  );
  const { allowed: createAllowed, reason: createReason } = checkUserForPermissions(user, [
    Permission.APP_DATA_PURCHASE_CREATE,
  ]);

  const isInboundShipmentCreated = data?.sourceType === "PO-IS";
  const isOutbound = data?.type === "outbound";

  const disabled = isInboundShipmentCreated || isOutbound;

  // TODO: Needs to know either req was created on FE, or was fetched from API. Solution for now, will be changed later
  // const existed = data.sourceLastSync === null;

  // const now = new Date();
  // const txnDate = new Date(data.txnDate);
  // const leadTime = data.vendorTimeDays + data.shipmentTimeDays || 0;

  // const disabled = differenceInDays(now, txnDate) >= leadTime || now >= data.dateRequired;

  if (params.node.rowIndex === 0) return null;
  if (editable) return <ActionsEditingCell {...props} />;

  return (
    <DropdownMenu modal={false} open={open} onOpenChange={setOpen}>
      <div className="w-full h-full grid place-items-center">
        <div className="relative h-[80%] aspect-square">
          <DropdownMenuTrigger asChild>
            <button
              data-testid="dots-button"
              className="grid place-items-center w-full h-full rounded-sm data-[state=open]:!bg-zinc-200 focus-visible:outline-0 hover:bg-zinc-200/60 group-hover:bg-zinc-200/60 transition duration-100"
            >
              <DotsHorizontalIcon />
            </button>
          </DropdownMenuTrigger>
          <div className="absolute -top-0.5 -right-0.5 h-fit">
            <ApprovalStatusDot status={data.approvalStatus} />
          </div>
        </div>
      </div>
      <DropdownMenuContent side="right" sticky="always" hideWhenDetached className="origin-left">
        <DropdownMenuGroup>
          <Tooltip tooltip={createReason} disabled={createAllowed}>
            <DropdownMenuItem
              disabled={!createAllowed}
              className="flex items-center gap-x-2 h-6 text-xs data-[disabled]:pointer-events-auto"
              onSelect={() => insert(params, false)}
              data-testid="add-row-button"
            >
              <PlusIcon className="w-4 h-4 text-zinc-950 " />
              Add
            </DropdownMenuItem>
          </Tooltip>
          <Tooltip tooltip={createReason} disabled={createAllowed}>
            <DropdownMenuItem
              disabled={!createAllowed}
              className="flex items-center gap-x-2 h-6 text-xs data-[disabled]:pointer-events-auto"
              onSelect={() => insert(params, true)}
              data-testid="add-mirror-row-button"
            >
              <CopyIcon className="w-4 h-4 text-zinc-950 " />
              Mirror
            </DropdownMenuItem>
          </Tooltip>
          <Tooltip
            tooltip={
              editAllowed ? "The row is either an inbound shipment or outbound so it cannot be edited." : editReason
            }
            disabled={editAllowed}
          >
            <DropdownMenuItem
              data-testid="cypress-actions-edit"
              disabled={disabled || !editAllowed}
              className="flex items-center gap-x-2 h-6 text-xs data-[disabled]:pointer-events-auto"
              onSelect={() => edit(params)}
            >
              <Pencil1Icon className="w-4 h-4 text-zinc-950" />
              Edit
            </DropdownMenuItem>
          </Tooltip>
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

const ActionsEditingCell = ({
  params,
  save,
  cancel,
  item_config,
}: PropsWithParams<{
  save: ActionsCellProps["save"];
  cancel: ActionsCellProps["cancel"];
  item_config: ItemConfig | undefined;
}>) => {
  const { data } = params;

  const [saving, setSaving] = useState<boolean>(false);

  const saveable = !!data?.quantity && !!data.vendorName && !!data.locationName;
  // TODO: Needs to know if req was not created yet. Solution for now, will be changed later
  const disabledClassName = "opacity-50 pointer-events-none";

  const handleSave = async () => {
    setSaving(true);
    try {
      await save(params);
    } finally {
      setSaving(false);
    }
  };
  const vendor = _.find(item_config?.vendor, { state_name: params.data.vendorName });
  const warningList = getWarningList(vendor, item_config, params.data.quantity);
  const formattedWarningListToString = () => {
    return (
      <div>
        <p className="text-left">Click to save, but note warnings:</p>
        {warningList.map((item, index) => (
          <p className=" text-left" key={index}>
            {index + 1}. {item}.
          </p>
        ))}
      </div>
    );
  };

  return (
    <div className="flex items-center justify-center gap-x-1 w-full h-full">
      <button
        data-testid="cypress-actions-cancel"
        disabled={saving}
        className={cn(
          "grid place-items-center h-[80%] aspect-square bg-red-50 hover:bg-red-100 rounded-sm transition",
          {
            [disabledClassName]: saving,
          },
        )}
        onClick={() => cancel(params)}
      >
        <Cross2Icon className="w-4 h-4 text-destructive" />
      </button>
      <button
        data-testid="cypress-actions-save"
        disabled={saving}
        className={cn(
          "grid place-items-center h-[80%] aspect-square bg-zinc-100 hover:bg-zinc-200 rounded-sm transition",
          {
            [disabledClassName]: !saveable || saving,
          },
        )}
        onClick={handleSave}
      >
        {saving ? (
          <ReloadIcon className="w-4 h-3 text-zinc-950 animate-spin" />
        ) : (
          <div>
            {warningList.length === 0 ? (
              <CheckIcon className="w-4 h-4 text-zinc-950" />
            ) : (
              <Tooltip
                tooltip={formattedWarningListToString()}
                className=" bg-white text-zinc-950 border border-zinc-800"
                side="bottom"
              >
                <ExclamationTriangleIcon className="w-[15px] h-[15px] text-yellow-500" />
              </Tooltip>
            )}
          </div>
        )}
      </button>
    </div>
  );
};

const ApprovalStatusDot = ({ status }: { status: ApprovalStatusEnum }) => {
  const renderStatus = (status: ApprovalStatusEnum): React.ReactNode => (
    <>
      <span className="font-semibold">Status:</span> {status}
    </>
  );
  return (
    <Tooltip tooltip={!status ? undefined : renderStatus(status)}>
      <div
        className={cn("w-1.5 h-1.5 rounded-full", {
          "bg-blue-500": status === ApprovalStatusEnum.AWAITING_APPROVAL,
          "bg-green-500": [ApprovalStatusEnum.APPROVED__SYNC_STARTED, ApprovalStatusEnum.APPROVED__SYNC_READY].includes(
            status,
          ),
        })}
      />
    </Tooltip>
  );
};
