import { useEffect, useState } from "react";
import { Dialog, DialogContent } from "@androshq/shared-ui";
import queryString from "query-string";
import { useNavigate } from "react-router-dom";
import { sopApiClient } from "../../../../../api";
import { RowGroup } from "../../types";
import PCLEditTable from "../CAWorkflowNewTable";

interface Props {
  sourceIdAndLineId: { line_id_source: string | undefined; line_parent_id_source: string | undefined };
  invalidateWorkflowExecution: () => void;
}

export default function PCLEditWorkflowModal({ invalidateWorkflowExecution, sourceIdAndLineId }: Props) {
  const queryParams: queryString.ParsedQuery<string> = queryString.parse(location.search);
  const [isWorkflowModalOpen, setWorkflowModalOpen] = useState(false);
  const [selectedLine, setSelectedLine] = useState<RowGroup | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    setWorkflowModalOpen(true);
  }, [sourceIdAndLineId]);

  const getRowData = async (line_id_source: string, line_parent_id_source: string) => {
    const response = await sopApiClient.lines.line({
      line_id_source: line_id_source,
      line_parent_id_source: line_parent_id_source,
    });
    if (response.success) {
      console.log(response.data);

      setSelectedLine(response.data);
    } else {
      setSelectedLine(null);
    }
  };

  useEffect(() => {
    if (isWorkflowModalOpen && sourceIdAndLineId.line_id_source && sourceIdAndLineId.line_parent_id_source) {
      getRowData(sourceIdAndLineId.line_id_source, sourceIdAndLineId.line_parent_id_source);
    }
  }, [sourceIdAndLineId, isWorkflowModalOpen]);

  const closeModal = () => {
    delete queryParams["line_id_source"];
    delete queryParams["line_parent_id_source"];
    const newSearch = queryString.stringify(queryParams);
    navigate({ search: newSearch, pathname: location.pathname }, { replace: true });
    setWorkflowModalOpen(false);
  };

  return (
    <Dialog open={isWorkflowModalOpen} onOpenChange={closeModal}>
      <DialogContent className="sm:max-w-6xl" tabIndex={-1}>
        <PCLEditTable
          invalidateWorkflowExecution={invalidateWorkflowExecution}
          data={selectedLine}
          closeModal={closeModal}
        />
      </DialogContent>
    </Dialog>
  );
}
