import { withModuleDashboardPageProps } from "@androshq/core/browser-client";
import { Outlet } from "react-router-dom";
import { CLIENT_MODULE_NAME, SectionNames } from "../../constants";

export function _WorkflowExecutionsOutlet() {
  return <Outlet />;
}

export const WorkflowExecutionsOutlet = withModuleDashboardPageProps(_WorkflowExecutionsOutlet, {
  moduleName: CLIENT_MODULE_NAME,
  sectionName: SectionNames.Workflows,
});
