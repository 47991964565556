import React from "react";
import {
  CheckIcon,
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
  Cross2Icon,
  Popover,
  PopoverContent,
  PopoverTrigger,
  cn,
} from "@androshq/shared-ui";
import Tooltip from "../messages/Tooltip";

const pluralize = (count: number) => (count === 1 ? "single" : "multiple");

export const getShortenedLabel = (selected: any[]) => {
  if (!selected?.length || selected.length < 2) return "";

  const count = selected.length - 1;
  const isMultiple = pluralize(count) === "multiple";
  const others = `& ${count} ${isMultiple ? "others" : "other"}`;

  return others;
};

interface Props {
  values: string[];
  wordSeparator: string;
  label: string;
  selectorType: "single" | "multi";
  options: {
    label: string;
    value: string;
    Icon?: React.ComponentType<{ className?: string }>;
  }[];
  placeholder: string;
  onValueChange: (value: string[]) => void;
}

export const FilterMultiSelect = ({
  values,
  options,
  label,
  placeholder,
  wordSeparator,
  selectorType,
  onValueChange,
}: Props) => {
  const toggleOption = (value: string) => {
    const newSelectedValues = new Set(values);

    if (newSelectedValues.has(value)) newSelectedValues.delete(value);
    else newSelectedValues.add(value);

    if (selectorType === "multi") onValueChange(Array.from(newSelectedValues));
    else onValueChange([value]);
  };

  return (
    <Popover>
      <PopoverTrigger asChild>
        <div className="flex w-fit max-w-full grow rounded-md border items-center justify-between bg-inside overflow-hidden">
          <span className="text-xs px-2 py-1 border-r whitespace-nowrap">{label}</span>
          <span className="text-xs px-2 py-1 border-r">{wordSeparator}</span>
          {Array.from(values).length > 0 ? (
            <>
              <Tooltip tooltip={values.join(", ")}>
                <div className="flex flex-wrap items-center cursor-pointer w-full overflow-x-auto border-r">
                  <div className="flex items-center gap-x-1 px-2 text-xs overflow-x-auto">
                    <span className="truncate">{options.find((o) => o.value === values[0])?.label}</span>
                    <span className="whitespace-nowrap">{getShortenedLabel(values)}</span>
                  </div>
                </div>
              </Tooltip>
              <div className="flex items-center justify-between">
                <Cross2Icon
                  className="h-4 mx-3 cursor-pointer text-muted-foreground"
                  onClick={(event: PointerEvent) => {
                    onValueChange([]);
                    event.stopPropagation();
                  }}
                />
              </div>
            </>
          ) : (
            <Tooltip tooltip={`select ${label.toLowerCase()}`}>
              <div className="flex items-center justify-between w-full mx-auto cursor-pointer">
                <span className="text-xs text-muted-foreground px-3">any</span>
              </div>
            </Tooltip>
          )}
        </div>
      </PopoverTrigger>
      <PopoverContent className="w-fit max-w-[260px] p-0" align="start">
        <Command>
          <CommandInput placeholder={placeholder} />
          <CommandList>
            <CommandEmpty>No results found.</CommandEmpty>
            <CommandGroup>
              {options.map((option) => {
                const Icon = option?.Icon;
                const isSelected = values.includes(option.value);

                return (
                  <CommandItem key={option.value} onSelect={() => toggleOption(option.value)}>
                    <div
                      className={cn(
                        "mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary",
                        isSelected ? "bg-primary text-primary-foreground" : "opacity-50 [&_svg]:invisible",
                      )}
                    >
                      <CheckIcon className={cn("h-4 w-4")} />
                    </div>
                    {!Icon ? null : <Icon className="mr-2 h-4 w-4 text-muted-foreground" />}
                    <span>{option.label}</span>
                  </CommandItem>
                );
              })}
            </CommandGroup>
            {values.length > 0 && (
              <>
                <CommandSeparator />
                <CommandGroup>
                  <CommandItem onSelect={() => onValueChange([])} className="justify-center text-center">
                    Clear filters
                  </CommandItem>
                </CommandGroup>
              </>
            )}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};
