import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { ReloadIcon, cn } from "@androshq/shared-ui";
import {
  CellClickedEvent,
  ColDef,
  ColGroupDef,
  DataTypeDefinition,
  GridApi,
  GridReadyEvent,
  RowValueChangedEvent,
  CellClassParams
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { format, parse } from "date-fns";
import { useDeepCompareMemo } from "use-deep-compare";
import { mapColumnDefsFromResultSet, mapDataFromResultSet, rowIsMuted, mutedCellClasses } from "./helpers";
import { ExtendedResultSet } from "./types";

interface Props {
  resultSet: ExtendedResultSet | null;
  columnDefsCustom?: (ColGroupDef<any> | ColDef<any, any>)[]; // Will render default columns unless this is passed
  isLoading: boolean;
  onCellClicked?: (event: CellClickedEvent) => void;
  onRowValueChanged?: (event: RowValueChangedEvent) => void;
  noRowsMessage?: string;
}

const DataCardTable = ({
  resultSet,
  isLoading,
  onCellClicked,
  onRowValueChanged,
  noRowsMessage,
  columnDefsCustom,
}: Props) => {
  const [gridApi, setGridApi] = useState<GridApi | null>(null);

  const rowData = useMemo(() => mapDataFromResultSet(resultSet), [resultSet]);
  const columnDefs = useDeepCompareMemo(
    () => mapColumnDefsFromResultSet(resultSet),
    [mapColumnDefsFromResultSet(resultSet)],
  );

  const defaultColDef: ColDef = {
    flex: 1,
    resizable: false,
    suppressHeaderMenuButton: true,
    suppressMovable: true,
    sortable: false,
    cellClass: (params: CellClassParams) => {
      let colorClass = ""
      let cursorClass = "cursor-pointer"
      if (rowIsMuted(params)) {
        colorClass = mutedCellClasses
      }
      if (params.colDef.editable) {
        cursorClass = "cursor-text"
      }
      return `${cursorClass} ${colorClass}`
    },
  };

  const onGridReady = useCallback((event: GridReadyEvent) => setGridApi(event.api), []);

  useEffect(() => {
    if (!gridApi) return;

    if (isLoading) {
      gridApi.showLoadingOverlay();
    } else if (rowData?.length === 0) {
      gridApi.showNoRowsOverlay();
      gridApi.updateGridOptions({
        rowData: [],
      });
    } else {
      gridApi.hideOverlay();
      gridApi.updateGridOptions({
        rowData,
        ...{ columnDefs: columnDefsCustom ? columnDefsCustom : columnDefs },
      });
    }
  }, [gridApi, columnDefs, columnDefsCustom, rowData, isLoading]);

  const dataTypeDefinitions: { [cellDataType: string]: DataTypeDefinition<any> } = {
    dateString: {
      baseDataType: "dateString",
      extendsDataType: "dateString",
      dateParser: (value: string | undefined) => {
        if (!value) return undefined;
        return parse(value, "M/d/yy", new Date());
      },
      dateFormatter: (value: Date | undefined) => {
        if (!value) return value;
        return format(value, "M/d/yy");
      },
    },
  };

  return (
    <div className={cn("ag-theme-quartz w-full h-full", { "ag-borders-none": !rowData?.length })}>
      <AgGridReact
        suppressAutoSize
        suppressCellFocus
        reactiveCustomComponents
        rowBuffer={25}
        onCellClicked={onCellClicked}
        onRowValueChanged={onRowValueChanged}
        editType={"fullRow"}
        dataTypeDefinitions={dataTypeDefinitions}
        loadingOverlayComponent={() => <ReloadIcon className="h-6 w-6 animate-spin" />}
        noRowsOverlayComponent={() => <div>{noRowsMessage || "No data available"}</div>}
        {...{ defaultColDef, onGridReady }}
      />
    </div>
  );
};

export default memo(DataCardTable);
