interface Props {
  item_name: string;
  item_name_prefix?: string;
  item_variant?: string;
  extra_classes?: string;
}

export default function ItemNameCell({ item_name, item_name_prefix, item_variant, extra_classes }: Props) {
  return (
    <span className={extra_classes ? extra_classes : ""}>
      <span className={extra_classes ? extra_classes : "text-muted-foreground"}>{item_name_prefix || " "}</span>
      <span data-testid="item-cell">{item_name} </span>
      {item_variant && <span className={extra_classes ? extra_classes : "text-muted-foreground"}>({item_variant})</span>}
    </span>
  );
}
