import { sopReducer } from "@androshq/sop/browser-client";
import { configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

const sopPersistConfig = {
  key: "sop",
  storage: storage,
  whitelist: ["app"], // only persist "appSlice" state
};

const store = configureStore({
  reducer: {
    sop: persistReducer(sopPersistConfig, sopReducer),
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["persist/PERSIST"],
      },
    }),
});

let persistor = persistStore(store);

export { store, persistor };

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
