import { useMemo } from "react";
import { MultiSelect } from "@androshq/shared-ui";
import { useSopDispatch, useSopSelector } from "../..";
import { useCachedData } from "../../hooks/use-cached-data";
import { selectAppData, setSelectedCompanyIds } from "../../redux/appSlice";

/* 
This component acts as a global company picker across the application.
It utilizes the appSlice to manage and retrieve the state of selectedCompanyIds.
If you need to use the selectedCompanyIds elsewhere, use the useSopSelector hook. 
*/

type Props = {
  onChange?: (selectedCompanyIds: string[]) => void;
};

export const CompanyPicker = ({ onChange }: Props) => {
  const dispatch = useSopDispatch();

  const { selectedCompanyIds } = useSopSelector(selectAppData);
  const { companies } = useCachedData();

  // Map companies for the MultiSelect
  const companyOptions = useMemo(
    () =>
      (companies.data || [])
        .map((company) => ({
          value: company.id.toString(),
          label: company.name,
        }))
        .sort((cA, cB) => cA.label.localeCompare(cB.label)),
    [companies.data],
  );

  const handleSelectedCompaniesChange = async (selectedIds: string[]) => {
    dispatch(setSelectedCompanyIds(selectedIds));
    onChange && onChange(selectedIds);
  };

  return (
    <div className="max-w-[50%]">
      <MultiSelect
        placeholder="Filter by customer"
        values={selectedCompanyIds}
        options={companyOptions}
        onValueChange={handleSelectedCompaniesChange}
      />
    </div>
  );
};
