import { useMemo } from "react";
import { cn } from "@androshq/shared-ui";
import { AgGridReact } from "ag-grid-react";
import { ReasonVendorSelectionCT, ReasonVendorSelectionPCL, State } from "../../../../../api/types";
import Tooltip from "../../../../../components/messages/Tooltip";
import { useCachedData } from "../../../../../hooks/use-cached-data";
import { formatDateFromNow } from "../../../../../utils";
import { CAWorkflowState, RowGroup } from "../../types";
import { EventTypeEnum } from "../../types";
import { defaultColDef, getColumnDefs } from "./columns";
import RenderedMemo from "./components/RenderedMemo";
import { CAHistoryRow } from "./types";

interface Props {
  workflowState: CAWorkflowState | null | undefined;
}

const buildRowData = (rowGroup: RowGroup): CAHistoryRow[] => {
  return rowGroup.rows.map((row) => {
    let reason_ct = "";
    let reason_pcl = "";
    if (row?.source_metadata?.reason_vendor_selection_ct) {
      reason_ct = ReasonVendorSelectionCT[row?.source_metadata?.reason_vendor_selection_ct];
    }
    if (row?.source_metadata?.reason_vendor_selection_pcl) {
      reason_pcl = ReasonVendorSelectionPCL[row?.source_metadata?.reason_vendor_selection_pcl];
    }

    return {
      ...row,
      company_id: rowGroup.company_id,
      item_id: rowGroup.item_id,
      source_row_name: rowGroup.source_row_name,
      quantity: row.quantity,
      shipping_method: row.shipping_method,
      reason_vendor_selection_ct: reason_ct,
      reason_vendor_selection_pcl: reason_pcl,
    };
  });
};

export default function CAModalSectionHistory({ workflowState }: Props) {
  const { locations, vendors } = useCachedData();

  const typedLocations = locations.data as State[];
  const typedVendors = vendors.data as State[];

  const columnDefs = useMemo(() => getColumnDefs(typedLocations, typedVendors), [typedLocations, typedVendors]);

  return (
    <div className="flex flex-col gap-5 overflow-auto max-h-96">
      {workflowState?.event_history?.map((eventHistoryEntry, idx) => {
        const versionHistoryEntry = workflowState.version_history.find(
          (version) => version.created_by.id === eventHistoryEntry.id,
        );

        return (
          <div key={idx} className="flex border-t pt-5 flex-col gap-1.5">
            <div className="grid grid-cols-3 gap-2 w-1/3 text-xs">
              <span>{formatDateFromNow(eventHistoryEntry.time)}</span>
              <span
                data-testid="aproval-status"
                className={cn("text-green-500", {
                  "text-yellow-500": eventHistoryEntry.data.workflow_event_type === EventTypeEnum.EDIT,
                  "text-destructive": eventHistoryEntry.data.workflow_event_type === EventTypeEnum.SYNC_FAILED,
                  "text-gray-500":
                    eventHistoryEntry.data.workflow_event_type === EventTypeEnum.APPROVE ||
                    eventHistoryEntry.data.workflow_event_type === EventTypeEnum.CANCEL,
                })}
              >
                {eventHistoryEntry.data.workflow_event_type}
              </span>
              <Tooltip tooltip={eventHistoryEntry.data.triggered_by.id} copy={true}>
                <span className="cursor-pointer">{eventHistoryEntry.data.triggered_by.id}</span>
              </Tooltip>
            </div>
            <RenderedMemo eventData={eventHistoryEntry.data} />
            {versionHistoryEntry && (
              <div className="ag-theme-quartz w-full pt-2">
                <AgGridReact
                  rowData={buildRowData(versionHistoryEntry.data)}
                  domLayout="autoHeight"
                  suppressCellFocus
                  suppressAutoSize
                  reactiveCustomComponents
                  {...{ columnDefs, defaultColDef }}
                />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}
