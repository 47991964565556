import { User } from "oidc-client-ts";
import { isJwtExpired } from "./utils";

interface RequestConfig {
  path: string;
  isPublic?: boolean;
  baseUrlOverride?: string;
  requestInit?: RequestInit;
}

export type ApiClientResponse<T> =
  | {
      data: T;
      success: true;
    }
  | {
      success: false;
      message: string;
      status: number;
    };

export class ApiClient {
  private static instance: ApiClient;

  // Keeping track of the refresh token request.
  private refreshingFunc: Promise<User | undefined> | null = null;

  private _userProvider: (() => Promise<User | null>) | null = null;

  set userProvider(provider: () => Promise<User | null>) {
    this._userProvider = provider;
  }

  private _refreshHandler: (() => Promise<User | undefined>) | null = null;

  set refreshHandler(handler: () => Promise<User | undefined>) {
    this._refreshHandler = handler;
  }

  private baseUrl: string | undefined = (import.meta as any).env.SERVER_API_BASE_URL;

  constructor() {}

  public static getInstance(): ApiClient {
    if (!ApiClient.instance) ApiClient.instance = new ApiClient();

    return ApiClient.instance;
  }

  private async refreshToken() {
    if (!this._refreshHandler) throw new Error("No refreshHandler was set for the API Client.");

    if (!this.refreshingFunc) this.refreshingFunc = this._refreshHandler();

    const newUser = await this.refreshingFunc;

    if (!newUser) throw new Error("Unable to refresh token Maybe the token expired.");

    this.refreshingFunc = null;

    return newUser;
  }

  async makeRequest<T>({
    path,
    isPublic,
    baseUrlOverride,
    requestInit: { headers, ...requestInitRest } = {},
  }: RequestConfig): Promise<ApiClientResponse<T>> {
    try {
      if (!this._userProvider) throw new Error("No userProvider was set for the API Client.");

      let user = await this._userProvider();

      // If request needs authentication, check if we have a working access token
      if (!isPublic) {
        if (!user) throw new Error("This request needs authentication but no access token was found.");

        if (isJwtExpired(user.access_token, 15000)) user = await this.refreshToken();
      }

      const mergedHeaders = {
        "Content-Type": "application/json",
        Authorization: user?.access_token && !isPublic ? `Bearer ${user.access_token}` : "",
        "X-ID-Token": user?.id_token && !isPublic ? user.id_token : "",
        ...headers,
      };

      const res = await fetch((baseUrlOverride || this.baseUrl) + path, {
        headers: mergedHeaders,
        credentials: "include",
        ...requestInitRest,
      });

      let resData;
      const contentType = res.headers.get("Content-Type");
      if (contentType && contentType.includes("application/json")) {
        try {
          resData = await res.json();
        } catch (error) {
          console.error("Error parsing JSON:", error);
          resData = { message: "Invalid JSON response" };
        }
      } else {
        resData = { message: await res.text() };
      }

      if (!res.ok) {
        const errorResponse: ApiClientResponse<T> = {
          success: false,
          message: resData.message,
          status: res.status,
        };

        return errorResponse;
      }

      const successResponse: ApiClientResponse<T> = {
        data: resData as T,
        success: true,
      };

      return successResponse;
    } catch (e: any) {
      console.error(e);
      throw Error(`Something went wrong with the request on path "${path}". This shouldn't happen. More details:`, e);
    }
  }
}

export abstract class AbstractModuleApiClient {
  private baseApiClient: ApiClient;
  private modulePath: string;
  private baseUrlOverride: string | undefined;

  constructor(modulePath: string, baseUrlOverride?: string) {
    this.modulePath = modulePath;
    this.baseUrlOverride = baseUrlOverride;
    this.baseApiClient = ApiClient.getInstance();
  }

  protected makeRequest<T>({ path, ...rest }: RequestConfig) {
    return this.baseApiClient.makeRequest<T>({
      path: this.modulePath + path,
      baseUrlOverride: this.baseUrlOverride,
      ...rest,
    });
  }
}
