import { cn } from "@androshq/shared-ui";
import { ColDef, ColGroupDef } from "ag-grid-community";
import { format } from "date-fns";
import numeral from "numeral";
import { toString } from "..";
import CellMultilineValue from "../../components/tables/cells/CellMultilineValue";
import ItemNameCell from "../../components/tables/cells/ItemNameCell";
import {
  ITEM_FCST_PAGE_TS_COL_QTY,
  ITEM_FCST_PAGE_TS_MIN_WEEK_NUM,
  getHeaderOptionsForTimeSeriesCol,
  getTSColWeekRange,
} from "../../components/tables/standards";

// Function to generate weekly columns starting from the current week
const generateWeeklyColumns = (): (ColDef | ColGroupDef)[] => {
  const weekRange = getTSColWeekRange(ITEM_FCST_PAGE_TS_MIN_WEEK_NUM, ITEM_FCST_PAGE_TS_COL_QTY);
  const columns: (ColDef | ColGroupDef)[] = [];

  for (let i = 0; i < weekRange.length; i++) {
    const weekDateStr = format(weekRange[i].date, "yyyy-MM-dd");

    columns.push({
      ...getHeaderOptionsForTimeSeriesCol(weekRange[i].num, weekRange[i].date),
      field: weekDateStr,
      autoHeight: true,
      sortable: false,
      minWidth: 50,
      maxWidth: 90,
      valueGetter: ({ data }: any) => ({
        value: data?.[weekDateStr]?.value,
        recommendation: data?.[weekDateStr]?.recommendation,
        plan: data?.[weekDateStr]?.plan,
      }),
      cellRenderer: (params: any) => {
        const { value, recommendation, plan } = params.value;
        const formatValue = (value: number) => (!value ? 0 : numeral(value).format("0,0"));
        return (
          <>
            <CellMultilineValue id={1} value={formatValue(value)} />
            <CellMultilineValue
              id={2}
              value={formatValue(recommendation)}
              className={cn({ "text-[#5090DC]": value > recommendation && !plan })}
            />
            <CellMultilineValue id={3} value={formatValue(plan)} />
          </>
        );
      },
    });
  }

  return [
    {
      field: "label",
      headerName: "",
      autoHeight: true,
      sortable: true,
      minWidth: 80,
      cellRenderer: () => (
        <>
          <CellMultilineValue id={1} value="Forecast" title />
          <CellMultilineValue id={2} value="Model" title />
          <CellMultilineValue id={3} value="Plan" title />
        </>
      ),
    },
    {
      headerName: "Forward Weeks (Forecast, Model, Plan)",
      children: columns,
    },
  ];
};

const valueFormatter = (params: { value: any }) => toString(params.value);

const defaultColDef: ColDef = {
  flex: 1,
  resizable: false,
  // sortingOrder: ["asc", "desc"],
  // unSortIcon: true,
  suppressHeaderMenuButton: true,
  suppressMovable: true,
  sortable: false,
  cellClass: "flex items-center cursor-pointer",
  valueFormatter,
};

const getColumnDefs = (): (ColDef | ColGroupDef)[] => [
  {
    field: "company",
    headerName: "Customer",
    pinned: "left",
    maxWidth: 120,
    cellClass: "cursor-pointer",
    valueGetter: (params) => params.data.company.name,
  },
  {
    field: "item",
    headerName: "Item",
    maxWidth: 100,
    pinned: "left",
    cellClass: "cursor-pointer",
    valueGetter: (params) => params.data.item.name,
    cellRendererSelector: (params) => {
      return {
        component: () => {
          if (!params.data.item) return;
          return (
            <ItemNameCell
              item_name={params.data.item.name}
              item_name_prefix={params.data.item.name_prefix}
              item_variant={params.data.item.variant}
            />
          );
        },
      };
    },
  },
  {
    headerName: "Avg Last 12W",
    children: [
      {
        field: "demandActual",
        headerName: "Actual",
        minWidth: 60,
        cellClass: "cursor-pointer",
        valueFormatter: (params) => numeral(params.value).format("0,0"),
      },
      {
        field: "demandForecastAtLeadTime",
        headerName: "Fcst (LT)",
        minWidth: 70,
        cellClass: "cursor-pointer",
        valueFormatter: (params) => numeral(params.value).format("0,0"),
      },
    ],
  },
  {
    headerName: "Avg Fwd",
    children: [
      {
        field: "demandForecastForward",
        headerName: "Fcst",
        minWidth: 70,
        valueFormatter: (params) => {
          type ForecastAverageComponents = {
            forecastSum: number;
            forecastCount: number;
          };

          const forecastAverageComponents = Object.entries(params.data).reduce<ForecastAverageComponents | null>(
            (acc, itemEntry) => {
              if (itemEntry[0].length == 10 && itemEntry[0].startsWith("20") && "value" in (itemEntry[1] as Object)) {
                // This is a time series column entry
                if (!acc) {
                  return {
                    forecastSum: Number((itemEntry[1] as any).value),
                    forecastCount: 1,
                  };
                }
                acc.forecastSum += Number((itemEntry[1] as any).value);
                acc.forecastCount += 1;
                return acc;
              }
              return acc;
            },
            null,
          );

          if (!forecastAverageComponents) {
            return "-";
          }
          const avgForward = Math.round(
            forecastAverageComponents.forecastSum / forecastAverageComponents.forecastCount,
          );
          return numeral(avgForward).format("0,0");
        },
      },
    ],
  },
  {
    headerName: "Model vs Fcst",
    children: [
      {
        field: "activeCutQtySumWtd",
        headerName: "Total MT Cut",
        minWidth: 100,
        sort: "desc",
        comparator: (vA: string, vB: string) => parseInt(vA) - parseInt(vB),
      },
    ],
  },
  ...generateWeeklyColumns(),
];

export default { defaultColDef, getColumnDefs };
