import { useAuth } from "@androshq/auth/browser-client";
import { getAccessibleModules } from "../utils/navigation";

export const useModulesWithPermission = () => {
  const { user } = useAuth();

  if (!user) return [];

  return getAccessibleModules(user);
};
