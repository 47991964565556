import { ModuleDashboardConfig } from "@androshq/core/browser-client";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { CLIENT_MODULE_NAME } from "./constants";
import { router, sections } from "./navigation";
import "./styles/ag-grid.css";

const dashboardConfig: ModuleDashboardConfig = {
  name: CLIENT_MODULE_NAME,
  sections,
  router,
};

export * from "./api";
export * from "./redux/reducer";
export * from "./components/controls/GlobalSearch";

export default dashboardConfig;
