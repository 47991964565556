import { useEffect } from "react";
import { AuthProvider, ZitadelOidcConfig } from "@androshq/auth/browser-client";
import cube from "@cubejs-client/core";
import { CubeProvider } from "@cubejs-client/react";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { localStoragePersister, queryClient } from "./lib/queryClient";
import Router from "./navigation/Router";
import { persistor, store } from "./redux/state";
import { ErrorBoundary } from "react-error-boundary";
import Error from "./pages/Error";

// Note: The entry point to the app (Router) could be exposed from package later on (e.g. @androshq/core),
// would only take modules, login pages, etc as a parameter and renders the app.

export const LOCAL_STORAGE_AUTH_USER = "LOCAL_STORAGE_AUTH_USER";
const getToken = async () => {
  const userJson = localStorage.getItem(LOCAL_STORAGE_AUTH_USER);
  if (!userJson) {
    return null;
  }
  return JSON.parse(userJson).id_token;
};

const cubeApi = cube(
  // TODO: Add API Key for usage on prod environment
  getToken,
  {
    apiUrl: `${import.meta.env.CUBE_API_BASE_URL}/cubejs-api/v1`,
  },
);

function App() {
  useEffect(() => {
    const environment = import.meta.env.ENVIRONMENT;

    let environmentLabel;

    switch (environment) {
      case "staging":
        environmentLabel = "Staging";
        break;
      case "prod":
        environmentLabel = "Platform";
        break;
      default:
        environmentLabel = "Development";
    }

    document.title = `${import.meta.env.COMPANY_NAME || "Company"} (Andros ${environmentLabel})`;
  }, []);

  // prettier-ignore
  const oidcConfig: ZitadelOidcConfig = {
    authority: import.meta.env.OIDC_AUTHORITY,
    app_endpoint: import.meta.env.CLIENT_BASE_URL,
    client_id: import.meta.env.OIDC_CLIENT_ID,
    project_resource_id: import.meta.env.OIDC_PROJECT_RESOURCE_ID,
    organization_resource_id: import.meta.env.OIDC_ORGANIZATION_RESOURCE_ID,
  };

  return (
    <ErrorBoundary fallback={<Error />}>
      <PersistQueryClientProvider
        client={queryClient}
        persistOptions={{
          persister: localStoragePersister,
          dehydrateOptions: {
            // Use the cache flag in the query to exclude it from the persisted state
            shouldDehydrateQuery: (query) => query.meta?.cache !== false,
          },
        }}
      >
        <AuthProvider {...{ oidcConfig }}>
          <Provider {...{ store }}>
            <CubeProvider cubeApi={cubeApi}>
              <PersistGate loading={null} {...{ persistor }}>
                <Router />
              </PersistGate>
            </CubeProvider>
          </Provider>
        </AuthProvider>
      </PersistQueryClientProvider>
    </ErrorBoundary>
  );
}

export default App;
