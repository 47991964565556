import { useDashboardContext } from "@androshq/core/browser-client";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { useModulesWithPermission } from "../hooks";
import { useAuth } from "@androshq/auth/browser-client";
import { Alert } from "@androshq/shared-ui";
import { checkUserForPermissions } from "@androshq/auth/common";

export default function Navigation() {
  const { moduleName, sectionName } = useDashboardContext();
  const modulesWithPermissison = useModulesWithPermission();
  const { user } = useAuth();

  const sections =
    modulesWithPermissison.find((config) => config.name === moduleName)
      ?.sections || [];

  return (
    <div className="flex gap-6">
      {sections.map((item) => {
        const hasPermission = checkUserForPermissions(user, [
          item.authSpec.permission,
        ]).allowed;

        return (
          <Alert
            key={item.name}
            enabled={!hasPermission}
            title={"Insufficient permissions"}
            description="You do not have the necessary permissions to access this resource. If you need access, reach out to your admin; they can assign you the appropriate role."
          >
            <Link
              to={hasPermission ? item.path : window.location.pathname}
              className={twMerge(
                "text-sm font-medium hover:text-foreground",
                item.name !== sectionName && "text-muted-foreground",
              )}
            >
              {item.name}
            </Link>
          </Alert>
        );
      })}
    </div>
  );
}
