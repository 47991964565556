import { DateRange, Query, ResultSet } from "@cubejs-client/core";
import { useCubeQuery } from "@cubejs-client/react";
import { format, startOfWeek } from "date-fns";
import { measures } from "../../api/cube";

const mapTableDataFromSet = (resultSet: ResultSet) => {
  const tableData: Record<string, string | number | boolean>[] = [
    { start: "Forecast" },
    { start: "Model" },
    { start: "Plan" },
  ];

  resultSet?.tablePivot().forEach((elem) => {
    const week = format(
      startOfWeek(new Date(elem["demand_forecast_most_recent.week"] as string), { weekStartsOn: 1 }),
      "yyyy-MM-dd",
    );
    const recommended = elem[measures.forecast.mostRecent.recommended];
    const recentForecast = elem[measures.forecast.mostRecent.total];
    let fixedTotal = elem[measures.forecast.mostRecent.fixed];
    if (fixedTotal === 0) {
      fixedTotal = +recommended;
    }
    tableData[0][week] = recentForecast;
    tableData[1][week] = recommended;
    tableData[2][week] = fixedTotal;
  });

  return tableData;
};

export const useFetchForecastTableData = (itemId: string | number, dateRange: DateRange) => {
  const query: Query = {
    measures: [
      measures.forecast.mostRecent.total,
      measures.forecast.mostRecent.recommended,
      measures.forecast.mostRecent.fixed,
    ],
    timeDimensions: [
      {
        dimension: "demand_forecast_most_recent.week",
        granularity: "week",
        dateRange,
      },
    ],
    order: {
      "demand_forecast_most_recent.week": "asc",
    },
    dimensions: [
      "demand_forecast_most_recent.company_id",
      "demand_forecast_most_recent.item_id",
      "demand_forecast_most_recent.week",
    ],
    filters: [
      {
        member: "demand_forecast_most_recent.item_id",
        operator: "equals",
        values: [itemId.toString()],
      },
    ],
  };

  const { resultSet, ...cube } = useCubeQuery(query);
  const tableData = mapTableDataFromSet(resultSet!);

  return { tableData, ...cube };
};
