import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@androshq/shared-ui";
import { ItemWithConfig } from "../../../api/types";

interface Props {
  value: ItemWithConfig | null;
  handleValueChange: (value: string) => void;
  elemList: ItemWithConfig[] | undefined;
}

export default function CardSelectItem({ value, handleValueChange, elemList }: Props) {
  return (
    <Select value={value?.name} onValueChange={handleValueChange}>
      <SelectTrigger className="w-fit h-6 px-2 py-1 bg-zinc-100 rounded-md shadow-none border-none text-xs transition">
        <SelectValue />
      </SelectTrigger>
      <SelectContent>
        {elemList &&
          elemList.map((elem, idx) => (
            <SelectItem key={idx} value={elem?.name}>
              {elem?.name}
            </SelectItem>
          ))}
      </SelectContent>
    </Select>
  );
}
