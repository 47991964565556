export const measures = {
  actual: {
    total: "demand_actual.quantity_total",
    totalWeight: "demand_actual.quantity_total_wtd",
    totalAvg: "demand_actual.quantity_total_avg",
    totalWeightAvg: "demand_actual.quantity_total_wtd_avg",
  },
  forecast: {
    total: "demand_forecast.quantity_total",
    totalWeight: "demand_forecast.quantity_total_wtd",
    leadTime: {
      total: "demand_forecast_at_lead_time.quantity_total",
      totalWeight: "demand_forecast_at_lead_time.quantity_total_wtd",
      totalAvg: "demand_forecast_at_lead_time.quantity_total_avg",
      totalWeightAvg: "demand_forecast_at_lead_time.quantity_total_wtd_avg",
    },
    mostRecent: {
      total: "demand_forecast_most_recent.quantity_total",
      totalWeight: "demand_forecast_most_recent.quantity_total_wtd",
      recommended: "demand_forecast_most_recent.quantity_recommended_total",
      recommendedWeight: "demand_forecast_most_recent.quantity_recommended_total_wtd",
      fixed: "demand_forecast_most_recent.quantity_fixed_total",
      fixedWeight: "demand_forecast_most_recent.quantity_fixed_total_wtd",
    },
  },
};
