import { cn } from "@androshq/shared-ui";
import { ColDef, ColGroupDef } from "ag-grid-community";
import { format } from "date-fns";
import numeral from "numeral";
import { toString } from "..";
import CellMultilineValue from "../../components/tables/cells/CellMultilineValue";
import ItemNameCell from "../../components/tables/cells/ItemNameCell";
import { getHeaderOptionsForTimeSeriesCol, getTSColWeekRange } from "../../components/tables/standards";
import {
  ROLLFORWARD_TS_COL_QTY,
  ROLLFORWARD_TS_MIN_WEEK_NUM,
} from "../../hooks/items/list-views/useFetchInventoryRollforward";

// Function to generate weekly columns starting from the current week
const generateWeeklyColumns = (): (ColDef | ColGroupDef)[] => {
  const weekRange = getTSColWeekRange(ROLLFORWARD_TS_MIN_WEEK_NUM, ROLLFORWARD_TS_COL_QTY);
  const columns: (ColDef | ColGroupDef)[] = [];

  for (let i = 0; i < weekRange.length; i++) {
    const weekDateStr = format(weekRange[i].date, "yyyy-MM-dd");

    columns.push({
      ...getHeaderOptionsForTimeSeriesCol(weekRange[i].num, weekRange[i].date),
      field: weekDateStr,
      autoHeight: true,
      sortable: true,
      sort: i === 0 ? "asc" : null,
      minWidth: 60,
      width: 60,
      maxWidth: 80,
      cellClass: "cursor-pointer",
      comparator: (vA, vB) => {
        return vA.endingBalance - vB.endingBalance;
      },
      valueGetter: ({ data }: any) => {
        // Calculate the balances in each week by rolling forward values
        if (typeof data !== "object" || data === null) {
          throw Error("Data must be a non-null object in inventory rollforward table");
        }
        // Return values as is if the week exists
        if (data[weekDateStr]) {
          return {
            inflow: data[weekDateStr].inflow,
            outflow: data[weekDateStr].outflow,
            endingBalance:
              data[weekDateStr].balanceSnapshot +
              data[weekDateStr].inflowCumulative +
              data[weekDateStr].outflowCumulative,
          };
        }
        // Week does not exist...
        // Find available weeks ordered ASCending
        const availableStrs = Object.keys(data)
          .filter((k) => k.length === 10)
          .sort((a, b) => a.localeCompare(b));

        // No weeks (shouldnt happen, but if it does just return zeros)
        if (availableStrs.length == 0) {
          console.warn(
            "Found no weekly data in inventory rollforward valueGetter. Should not happen given we now filter out fully zero-value weeks.",
            data,
          );
          return {
            inflow: 0,
            outflow: 0,
            endingBalance: 0,
          };
        }

        // Try find prior week; if exists, find its ending balance and use for this week (carry forward)
        const availableStrsPriorWeeks = availableStrs.filter((k) => k < weekDateStr);
        if (availableStrsPriorWeeks.length > 0) {
          const priorWeekDateStr = availableStrsPriorWeeks.pop() as string;
          const priorWeekEndingBal =
            data[priorWeekDateStr].balanceSnapshot +
            data[priorWeekDateStr].inflowCumulative +
            data[priorWeekDateStr].outflowCumulative;
          return {
            inflow: 0,
            outflow: 0,
            endingBalance: priorWeekEndingBal,
          };
        }

        // Last option (fill backward from next available week)
        // KEY NOTE: In this case we calc that weeks ending balance, then subtract the in-week inflow/outflow to get to prior
        const nextWeekDateStr = availableStrs[0] as string;
        return {
          inflow: 0,
          outflow: 0,
          endingBalance:
            data[nextWeekDateStr].balanceSnapshot +
            data[nextWeekDateStr].inflowCumulative +
            data[nextWeekDateStr].outflowCumulative -
            data[nextWeekDateStr].inflow -
            data[nextWeekDateStr].outflow,
        };
      },
      cellRenderer: (params: any) => {
        const { inflow, outflow, endingBalance } = params.value;
        const formatValue = (value: number) => numeral(value).format("0,0");
        return (
          <>
            <CellMultilineValue id={1} value={!inflow ? null : `+${formatValue(inflow)}`} />
            <CellMultilineValue id={2} value={!outflow ? null : formatValue(outflow)} />
            <CellMultilineValue
              id={3}
              value={formatValue(endingBalance)}
              renderZeros
              className={cn({ "text-destructive": endingBalance < params.data.minInvQty })}
            />
          </>
        );
      },
    });
  }

  return [
    {
      field: "label",
      headerName: "",
      autoHeight: true,
      sortable: false,
      width: 90,
      minWidth: 90,
      cellRenderer: () => (
        <>
          <CellMultilineValue id={1} value="Inflow" title />
          <CellMultilineValue id={2} value="Outflow" title />
          <CellMultilineValue id={0} value="End Bal" title />
        </>
      ),
    },
    {
      headerName: "Forward Weeks (Inflow, Outflow, Ending Balance)",
      children: columns,
    },
  ];
};

const valueFormatter = (params: { value: any }) => toString(params.value);

const defaultColDef: ColDef = {
  resizable: false,
  // sortingOrder: ["asc", "desc"],
  // unSortIcon: true,
  suppressHeaderMenuButton: true,
  suppressMovable: true,
  cellClass: "flex items-center cursor-pointer",
  valueFormatter,
};

const getColumnDefs = (): (ColDef | ColGroupDef)[] => [
  {
    field: "company",
    headerName: "Customer",
    width: 120,
    pinned: "left",
    cellClass: "cursor-pointer",
    valueGetter: (params: any) => params.data.company.name,
  },
  {
    field: "item",
    headerName: "Item",
    width: 120,
    pinned: "left",
    cellClass: "cursor-pointer",
    valueGetter: (params: any) => params.data.item.name,
    cellRendererSelector: (params) => {
      return {
        component: () => {
          if (!params.data.item) return;
          return (
            <ItemNameCell
              item_name={params.data.item.name}
              item_name_prefix={params.data.item.name_prefix}
              item_variant={params.data.item.variant}
            />
          );
        },
      };
    },
  },
  ...generateWeeklyColumns(),
];

export default { defaultColDef, getColumnDefs };
