import { useCallback, useEffect, useMemo, useState } from "react";
import { ReloadIcon } from "@androshq/shared-ui";
import { ColDef, GridApi, GridReadyEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useCompanies } from "../../hooks/companies/useCompanies";
import { useNavigate } from "react-router-dom";

export function CompanyPage() {
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const { data: companies, isLoading } = useCompanies();
  const navigate = useNavigate();

  const columnDefs: ColDef[] = useMemo(
    () => [
      { field: "companyName", cellClass: 'cursor-pointer', headerName: "Customer" },
      { field: "parent", cellClass: 'cursor-pointer', headerName: "Parent" },
    ],
    [],
  );

  const rowData = useMemo(() => {
    return companies?.map((company) => ({ companyName: company.name, parent: company.parent_name, id:company.id }));
  }, [companies]);

  const onGridReady = useCallback((event: GridReadyEvent) => setGridApi(event.api), []);

  const onRowClicked = (params: any) => {
    navigate(`/sop/customers/${params.data.id}`)
  }

  useEffect(() => {
    if (!gridApi) return;

    if (isLoading) {
      gridApi.showLoadingOverlay();
    } else if (rowData?.length === 0) {
      gridApi.showNoRowsOverlay();
      gridApi.updateGridOptions({
        rowData: [],
      });
    } else {
      gridApi.hideOverlay();
      gridApi.updateGridOptions({
        rowData,
        columnDefs,
      });
    }
  }, [gridApi, columnDefs, rowData, isLoading]);

  const defaultColDef: ColDef = {
    resizable: false,
    suppressHeaderMenuButton: true,
    suppressMovable: true,
  };

  return (
    <div className="ag-theme-quartz w-full h-full pt-2">
      <AgGridReact
        suppressAutoSize
        suppressCellFocus
        reactiveCustomComponents
        rowBuffer={25}
        onRowClicked={onRowClicked}
        loadingOverlayComponent={() => <ReloadIcon className="h-6 w-6 animate-spin" />}
        noRowsOverlayComponent={() => <div>No data available</div>}
        {...{ onGridReady, defaultColDef }}
      />
    </div>
  );
}
