import { User } from "oidc-client-ts";
import { dashboardModuleConfigs } from "../config";
import { checkUserForPermissions } from "@androshq/auth/common";

export function getModuleConfigByName(moduleName: string) {
  return dashboardModuleConfigs.find((config) => config.name === moduleName);
}

export function getAccessibleModules(user: User) {
  return dashboardModuleConfigs.filter((config) => {
    return config.sections.some((section) => {
      return checkUserForPermissions(user, [section.authSpec.permission])
        .allowed;
    });
  });
}
