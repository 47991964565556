import { ColDef, ValueGetterParams } from "ag-grid-community";
import { Item, State } from "../../../../api/types";
import { formatCellDate } from "../../../../utils";
import { subDays } from "date-fns";

export const defaultColDef: ColDef = {
  flex: 1,
  resizable: false,
  suppressHeaderMenuButton: true,
  suppressMovable: true,
};

export const getColumnDefs = (
  locations: State[],
  items: Item[],
  vendors: State[],
  type: "recent" | "old",
): ColDef[] => [
    {
      field: "item",
      headerName: "Item",
      valueGetter: (params: ValueGetterParams) => {
        const itemId = params.data?.state.version_history[0].data.item_id;
        const itemName = items.find((item) => item.id === itemId)?.name;
        return itemName || "Unknown";
      },
    },
    {
      field: "to_vendor",
      headerName: "To Vendor",
      valueFormatter: formatCellDate,
      valueGetter: (params) => {
        const versionHistoryIdx = type === "recent" ? 0 : params.data.state.version_history.length - 1
        return params.data.state.version_history[versionHistoryIdx]
          .data.rows[0].state_transitions[0].at;
      },
    },
    {
      field: "cargo_ready",
      headerName: "Cargo Ready",
      valueFormatter: formatCellDate,
      valueGetter: (params) => {
        const versionHistoryIdx = type === "recent" ? 0 : params.data.state.version_history.length - 1
        const toShipment = params.data.state.version_history[versionHistoryIdx]
          .data.rows[0].state_transitions[1].at;
        return subDays(toShipment, 7)
      },
    },
    {
      field: "toShipment",
      headerName: "To Shipment",
      valueFormatter: formatCellDate,
      valueGetter: (params) => {
        const versionHistoryIdx = type === "recent" ? 0 : params.data.state.version_history.length - 1
        return (
          params.data.state.version_history[versionHistoryIdx].data
            .rows[0].state_transitions[1].at
        )
      }
    },
    {
      field: "toLocation",
      headerName: "To Location",
      valueFormatter: formatCellDate,
      valueGetter: (params) => {
        const versionHistoryIdx = type === "recent" ? 0 : params.data.state.version_history.length - 1
        return params.data.state.version_history[versionHistoryIdx].data
          .rows[0].state_transitions[2].at
      }
    },
    {
      field: "quantity",
      headerName: "Quantity",
      maxWidth: 90,
      valueGetter: (params: ValueGetterParams) => {
        const versionHistoryIdx = type === "recent" ? 0 : params.data.state.version_history.length - 1
        return params.data?.state.version_history[versionHistoryIdx]?.data
          .rows[0].quantity
      }
    },
    {
      field: "vendor",
      headerName: "Vendor",
      valueGetter: (params: ValueGetterParams) => {
        const versionHistoryIdx = type === "recent" ? 0 : params.data.state.version_history.length - 1
        const vendorId =
          params.data?.state.version_history[versionHistoryIdx].data
            .rows[0].state_transitions[0].to_state_id;
        const vendorName = vendors.find((vendor) => vendor.id === vendorId)?.name;
        return vendorName || "Unknown";
      },
    },
    {
      field: "shipping_method",
      headerName: "Shp Method",
      minWidth: 100,
      valueGetter: (params: ValueGetterParams) => {
        const versionHistoryIdx = type === "recent" ? 0 : params.data.state.version_history.length - 1
        return params.data?.state.version_history[versionHistoryIdx].data
          .rows[0].shipping_method
      }
    },
    {
      field: "location",
      headerName: "Location",
      valueGetter: (params) => {
        const versionHistoryIdx = type === "recent" ? 0 : params.data.state.version_history.length - 1
        return params.data.state.version_history[versionHistoryIdx].data
          .rows[0].state_transitions[2].to_state_id
      },
      refData: locations?.reduce(
        (acc, location) => {
          acc[location.id] = location.name;
          return acc;
        },
        {} as Record<string, string>,
      ),
    },
  ];
