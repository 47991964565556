import { useQuery } from "@tanstack/react-query";
import { sopApiClient } from "../client";
import { ItemType } from "../types";
import { useLocations, useVendors } from "./states";

export const useItems = () => {
  return useQuery({
    queryKey: ["items"],
    queryFn: async () => {
      const data = await sopApiClient.items.getAll();
      if (!data.success) throw new Error(data.message);
      return data.data;
    },
    staleTime: 1000 * 60 * 60, // 1 hour
  });
};

/**
 * Hook to fetch items, filtered to only the inventory items.
 *
 * Inventory items are those for which we track balances.
 * These are the base level parts that are orderable.
 * Customers may send orders for things like "Assemblies" that use many of these parts,
 * but the data coming to frontend in States/Transitions will be just for base level.
 * We use this for showing relevant search results (users shouldn't go to an item with no data
 * because it's not an inventory item).
 */
export const useInventoryItems = () => {
  const itemsQuery = useItems();
  const inventoryItems = itemsQuery.data
    ? itemsQuery.data.filter((item) => item.source_metadata?.item_type === ItemType.InventoryItem)
    : undefined;
  return {
    data: inventoryItems,
    isLoading: itemsQuery.isLoading || (!itemsQuery.isLoading && !inventoryItems),
    error: itemsQuery.error,
  };
};

export const useItem = (id: number) => {
  const itemsQuery = useItems();

  const item = itemsQuery.data?.find((item) => item.id === id);

  return {
    data: item,
    isLoading: itemsQuery.isLoading || (!itemsQuery.isLoading && !item),
    error: itemsQuery.error,
  };
};

export const useItemVendors = (id: number) => {
  const itemQuery = useItem(id);
  const vendorsQuery = useVendors();

  const itemVendorIds = itemQuery.data
    ? Object.keys(itemQuery.data.config.vendor).map((vendorId) => parseInt(vendorId))
    : undefined;
  const itemVendors = vendorsQuery.data?.filter((vendor) => itemVendorIds?.includes(vendor.id));

  return {
    data: itemVendors,
    isLoading: itemQuery.isLoading || vendorsQuery.isLoading,
    error: itemQuery.error ? itemQuery.error : vendorsQuery.error,
  };
};

export const useItemLocations = (id: number) => {
  const itemQuery = useItem(id);
  const locationsQuery = useLocations();

  const itemLocationIds = itemQuery.data
    ? Object.keys(itemQuery.data.config.location).map((locationId) => parseInt(locationId))
    : undefined;
  const itemLocations = locationsQuery.data?.filter((location) => itemLocationIds?.includes(location.id));

  return {
    data: itemLocations,
    isLoading: itemQuery.isLoading || locationsQuery.isLoading,
    error: itemQuery.error ? itemQuery.error : locationsQuery.error,
  };
};
