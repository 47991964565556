import { withModuleDashboardPageProps } from "@androshq/core/browser-client";
import { Outlet } from "react-router-dom";
import { CLIENT_MODULE_NAME, SectionNames } from "../../constants";

export function _CompaniesOutlet() {
  return <Outlet />;
}

export const CompaniesOutlet = withModuleDashboardPageProps(_CompaniesOutlet, {
  moduleName: CLIENT_MODULE_NAME,
  sectionName: SectionNames.Companies,
});
