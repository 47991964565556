import { useContext, useEffect, useRef, useState } from "react";
import { Query, UnsubscribeObj, areQueriesEqual, isQueryPresent } from "@cubejs-client/core";
import { CubeContext, UseCubeQueryOptions } from "@cubejs-client/react";
import useDeepCompareMemoize from "./useDeepCompareMemoize";
import { useIsMounted } from "./useIsMounted";

export function useCubeQueries(queries: Query[], options: UseCubeQueryOptions = {}) {
  const mutexRef = useRef({});
  const isMounted = useIsMounted();
  const [currentQueries, setCurrentQueries] = useState<(Query | null)[]>(queries.map(() => null));
  const [isLoading, setLoading] = useState(false);
  const [resultSets, setResultSets] = useState<any[]>(queries.map(() => null));
  const [progress, setProgress] = useState<any[]>(queries.map(() => null));
  const [errors, setErrors] = useState<any[]>(queries.map(() => null));
  const context = useContext(CubeContext);

  let subscribeRequests: (UnsubscribeObj | null)[] = queries.map(() => null);

  const progressCallback = (index: number) => (progressResponse: any) => {
    setProgress((prev) => {
      const newProgress = [...prev];
      newProgress[index] = progressResponse;
      return newProgress;
    });
  };

  async function fetch(index: number, query: Query) {
    const { resetResultSetOnChange } = options;
    const cubeApi = options.cubeApi || context?.cubeApi;

    if (!cubeApi) {
      throw new Error("Cube API client is not provided");
    }

    if (resetResultSetOnChange) {
      setResultSets((prev) => {
        const newResultSets = [...prev];
        newResultSets[index] = null;
        return newResultSets;
      });
    }

    setErrors((prev) => {
      const newErrors = [...prev];
      newErrors[index] = null;
      return newErrors;
    });
    setLoading(true);

    try {
      const response: any = await cubeApi.load(query, {
        mutexObj: mutexRef.current,
        mutexKey: `query_${index}`,
        progressCallback: progressCallback(index),
        castNumerics: Boolean(
          typeof options.castNumerics === "boolean" ? options.castNumerics : context?.options?.castNumerics,
        ),
      });

      if (isMounted()) {
        setResultSets((prev) => {
          const newResultSets = [...prev];
          newResultSets[index] = response;
          return newResultSets;
        });
        setProgress((prev) => {
          const newProgress = [...prev];
          newProgress[index] = null;
          return newProgress;
        });
      }
    } catch (error: any) {
      if (isMounted()) {
        setErrors((prev) => {
          const newErrors = [...prev];
          newErrors[index] = error;
          return newErrors;
        });
        setResultSets((prev) => {
          const newResultSets = [...prev];
          newResultSets[index] = null;
          return newResultSets;
        });
        setProgress((prev) => {
          const newProgress = [...prev];
          newProgress[index] = null;
          return newProgress;
        });
      }
    }

    if (isMounted()) {
      setLoading(false);
    }
  }

  useEffect(
    () => {
      const { skip = false, resetResultSetOnChange } = options;

      const cubeApi = options.cubeApi || context?.cubeApi;

      if (!cubeApi) {
        throw new Error("Cube API client is not provided");
      }

      async function loadQueries() {
        if (!skip) {
          queries.forEach((query, index) => {
            if (isQueryPresent(query)) {
              if (!areQueriesEqual(currentQueries[index], query)) {
                if (resetResultSetOnChange == null || resetResultSetOnChange) {
                  setResultSets((prev) => {
                    const newResultSets = [...prev];
                    newResultSets[index] = null;
                    return newResultSets;
                  });
                }
                setCurrentQueries((prev) => {
                  const newQueries = [...prev];
                  newQueries[index] = query;
                  return newQueries;
                });
              }

              setErrors((prev) => {
                const newErrors = [...prev];
                newErrors[index] = null;
                return newErrors;
              });
              setLoading(true);

              try {
                if (subscribeRequests[index]) {
                  subscribeRequests[index]!.unsubscribe();
                  subscribeRequests[index] = null;
                }

                if (options.subscribe) {
                  subscribeRequests[index] = cubeApi.subscribe(
                    query,
                    {
                      mutexObj: mutexRef.current,
                      mutexKey: `query_${index}`,
                      progressCallback: progressCallback(index),
                    },
                    (e: any, result: any) => {
                      if (isMounted()) {
                        if (e) {
                          setErrors((prev) => {
                            const newErrors = [...prev];
                            newErrors[index] = e;
                            return newErrors;
                          });
                        } else {
                          setResultSets((prev) => {
                            const newResultSets = [...prev];
                            newResultSets[index] = result;
                            return newResultSets;
                          });
                        }
                        setLoading(false);
                        setProgress((prev) => {
                          const newProgress = [...prev];
                          newProgress[index] = null;
                          return newProgress;
                        });
                      }
                    },
                  );
                } else {
                  fetch(index, query);
                }
              } catch (e) {
                if (isMounted()) {
                  setErrors((prev) => {
                    const newErrors = [...prev];
                    newErrors[index] = e;
                    return newErrors;
                  });
                  setResultSets((prev) => {
                    const newResultSets = [...prev];
                    newResultSets[index] = null;
                    return newResultSets;
                  });
                  setLoading(false);
                  setProgress((prev) => {
                    const newProgress = [...prev];
                    newProgress[index] = null;
                    return newProgress;
                  });
                }
              }
            }
          });
        }
      }

      loadQueries();

      return () => {
        subscribeRequests.forEach((request) => {
          if (request) {
            request.unsubscribe();
          }
        });
        subscribeRequests = [];
      };
    },
    useDeepCompareMemoize([queries, options, context]),
  );

  return {
    isLoading,
    resultSets,
    errors,
    progress,
    previousQueries: currentQueries,
    refetch: () => queries.forEach((query, index) => fetch(index, query)),
  };
}
