import { jwtDecode as _jwtDecode } from "jwt-decode";

/**
 * Check whether the JWT is expired.
 *
 * @param token JWT token.
 * @param offset Use it to offset the expiration date by a certain amount of time.
 * E.g. use 15000 to offset the expiration date by 15 seconds to prevent ongoing requests from failing
 */
export function isJwtExpired(token: string, offset: number) {
  const currentDate = new Date();
  const decodedToken = jwtDecode(token);

  if (!decodedToken.exp) throw Error("No expiration date found in token.");

  return decodedToken.exp * 1000 < currentDate.getTime() + offset;
}

// Re-export
export function jwtDecode(token: string) {
  return _jwtDecode(token);
}
