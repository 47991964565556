import { Attributes, Span, SpanStatusCode } from "@opentelemetry/api";
import { ZoneContextManager } from "@opentelemetry/context-zone";
import { CompositePropagator, W3CBaggagePropagator, W3CTraceContextPropagator } from "@opentelemetry/core";
import { OTLPTraceExporter } from "@opentelemetry/exporter-trace-otlp-http";
import { registerInstrumentations } from "@opentelemetry/instrumentation";
import { FetchInstrumentation } from "@opentelemetry/instrumentation-fetch";
import { FetchError } from "@opentelemetry/instrumentation-fetch/build/src/types";
import { UserInteractionInstrumentation } from "@opentelemetry/instrumentation-user-interaction";
import { Resource } from "@opentelemetry/resources";
import { browserDetector, detectResourcesSync } from "@opentelemetry/resources";
import { BatchSpanProcessor } from "@opentelemetry/sdk-trace-base";
import { WebTracerProvider } from "@opentelemetry/sdk-trace-web";
import {
  SEMRESATTRS_DEPLOYMENT_ENVIRONMENT,
  SEMRESATTRS_SERVICE_NAME,
  SEMRESATTRS_SERVICE_VERSION,
} from "@opentelemetry/semantic-conventions";
import * as Sentry from "@sentry/react";
import { User } from "oidc-client-ts";

const STD_ATTRS = {
  [SEMRESATTRS_SERVICE_NAME]: "browser-client",
  [SEMRESATTRS_SERVICE_VERSION]: import.meta.env.PACKAGE_VERSION,
  [SEMRESATTRS_DEPLOYMENT_ENVIRONMENT]: import.meta.env.ENVIRONMENT,
};
const detectedResources = detectResourcesSync({
  detectors: [browserDetector],
});
const endpoint = import.meta.env.OTEL_EXPORTER_OTLP_ENDPOINT;
const sentryEnabled = true;
const otelEnabled = import.meta.env.OTEL_ENABLED == "TRUE";
const ENDPOINT_SUFFIX_TRACE = "/v1/traces";

export const init = () => {
  const attrs = {};

  console.debug("[MONITOR]", { OTEL: otelEnabled, SENTRY: sentryEnabled });
  if (sentryEnabled) {
    initSentry();
  }
  if (otelEnabled) {
    initOTEL(attrs);
  }
};

export const setUser = (user: User) => {
  console.debug("[MONITOR]", { setUser: true });
  let fullName =
    user.profile.given_name && user.profile.family_name
      ? `${user.profile.given_name} ${user.profile.family_name}`
      : undefined;
  const userAttrs = {
    "user.id": user.profile.email,
    "user.full_name": fullName,
    "user.email": user.profile.email,
  };
  if (otelEnabled) {
    initOTEL(userAttrs);
  }
  if (sentryEnabled) {
    Sentry.setUser({
      fullName: fullName,
      email: user.profile.email,
    });
  }
};

const initOTEL = (attrs: Attributes) => {
  const resource = new Resource({
    ...STD_ATTRS,
    ...detectedResources.attributes,
    ...attrs,
  });
  const provider = new WebTracerProvider({ resource });
  const exporter = new OTLPTraceExporter({
    url: `${endpoint}${ENDPOINT_SUFFIX_TRACE}`,
  });
  provider.addSpanProcessor(new BatchSpanProcessor(exporter));
  provider.register({
    contextManager: new ZoneContextManager(),
    propagator: new CompositePropagator({
      propagators: [new W3CBaggagePropagator(), new W3CTraceContextPropagator()],
    }),
  });

  registerInstrumentations({
    instrumentations: [
      new FetchInstrumentation({
        enabled: true,
        propagateTraceHeaderCorsUrls: /.*/,
        ignoreUrls: [/.*auth\.platform\.androshq\.com.*/],
        clearTimingResources: true,
        applyCustomAttributesOnSpan: (span: Span, _request: Request | RequestInit, result: Response | FetchError) => {
          const attributes = (span as any).attributes;
          if (attributes.component === "fetch") {
            span.updateName(`${attributes["http.method"]} ${attributes["http.url"]}`);
          }
          if (result instanceof Error) {
            span.setStatus({
              code: SpanStatusCode.ERROR,
              message: result.message,
            });
            span.recordException(result.stack || result.name);
          }
        },
      }),
      new UserInteractionInstrumentation({ enabled: true }),
    ],
  });
};

const initSentry = () => {
  Sentry.init({
    dsn: "https://342cb3ef3b7a0482504a37df38bd6c9b@o4507620784406528.ingest.us.sentry.io/4507620795416576",
    integrations: [
      Sentry.replayIntegration({
        maskAllText: false,
      }),
      Sentry.feedbackIntegration({
        colorScheme: "light",
        showBranding: false,
        showName: false,
        showEmail: true,
        isEmailRequired: true,
      }),
    ],

    // Session Replay
    replaysSessionSampleRate: STD_ATTRS[SEMRESATTRS_DEPLOYMENT_ENVIRONMENT] == "dev" ? 0 : 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: STD_ATTRS[SEMRESATTRS_DEPLOYMENT_ENVIRONMENT] == "dev" ? 0 : 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    environment: STD_ATTRS[SEMRESATTRS_DEPLOYMENT_ENVIRONMENT],
    release: `${STD_ATTRS[SEMRESATTRS_SERVICE_NAME]}@${STD_ATTRS[SEMRESATTRS_SERVICE_VERSION]}`,
  });
};
