import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../hooks";

export const asProtectedRoute =
  (Component: React.ComponentType<any>) => (props: any) => {
    const { isAuthenticated } = useAuth();
    const location = useLocation().pathname;

    return isAuthenticated ? (
      <Component {...props} />
    ) : (
      <Navigate to="/login" state={{ from: location }} replace />
    );
  };
