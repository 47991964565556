import { Query } from "@cubejs-client/core";
import { ColDef, ColGroupDef } from "ag-grid-community";
import { differenceInDays } from "date-fns";
import _ from "lodash";
import ItemNameCell from "../../../tables/cells/ItemNameCell";
import { BuiltQueryConfig, DisplayConfig, DynamicInputs, InputConfigs } from "../DataCard";
import {rowIsMuted, mutedCellClasses, cellClassFunc, companyCellWidth, muteUntilMaxWidth} from '../outputs/DataCardTable/helpers';

const customColumnDefs: (ColGroupDef<any> | ColDef<any, any>)[] = [
  {
    field: "inventory_projected_shortages_nearest-company_name",
    headerName: "Company",
    flex: 1,
    width: companyCellWidth,
    maxWidth: companyCellWidth+20,
  },
  {
    field: "inventory_projected_shortages_nearest-item_name",
    headerName: "Item",
    flex: 1,
    width: 120,
    maxWidth: 120,
    sortable: true,
    cellRendererSelector: (params) => {
      return {
        component: () => {
          if (!params.data) return;
          return (
            <ItemNameCell
              item_name={
                Object.keys(params.data).find((key) => key.endsWith("item_name"))
                  ? params.data[Object.keys(params.data).find((key) => key.endsWith("item_name"))!]
                  : ""
              }
              item_name_prefix={
                Object.keys(params.data).find((key) => key.endsWith("item_name_prefix"))
                  ? params.data[Object.keys(params.data).find((key) => key.endsWith("item_name_prefix"))!]
                  : ""
              }
              item_variant={
                Object.keys(params.data).find((key) => key.endsWith("item_variant"))
                  ? params.data[Object.keys(params.data).find((key) => key.endsWith("item_variant"))!]
                  : ""
              }
              extra_classes={cellClassFunc(params)}
            />
          );
        },
      };
    },
  },
  {
    headerName: "Date of Shortage",
    flex: 1,
    sort: "asc",
    sortable: true,
    sortIndex: 1,
    valueGetter: (params) => {
      return {
        date_under_min: params.data["inventory_projected_shortages_nearest-date_under_min_inv_qty"],
        days_till_under: Math.round(
          differenceInDays(params.data["inventory_projected_shortages_nearest-date_under_min_inv_qty"], new Date()),
        ),
        lead_time_days: params.data["inventory_projected_shortages_nearest-lead_time_days"],
      };
    },
    comparator: (valueA, valueB) => {
      if (valueA.days_till_under == valueB.days_till_under) return 0;
      return valueA.days_till_under > valueB.days_till_under ? 1 : -1;
    },
    valueFormatter: (params) => {
      let relStr = "today";
      if (params.value.days_till_under == 1) {
        relStr = "tomorrow";
      } else if (params.value.days_till_under == -1) {
        relStr = "yesterday";
      } else if (params.value.days_till_under > 1) {
        relStr = `in ${params.value.days_till_under} days`;
      } else if (params.value.days_till_under < 1) {
        relStr = `${Math.abs(params.value.days_till_under)} days ago`;
      }
      return `${params.value.date_under_min} (${relStr}, vs ${params.value.lead_time_days} days LT)`;
    },
    cellClass: (params) => {
      let cursorClass = "cursor-pointer"
      let colorClass = ""
      if (rowIsMuted(params)) {
        colorClass = mutedCellClasses
      } else if (params.value.days_till_under <= 30) {
        colorClass = "text-orange-700";
      } else if (params.value.days_till_under <= 60) {
        colorClass = "text-amber-500";
      }
      return `${cursorClass} ${colorClass}`
    },
  },
  {
    headerName: "Current Proj. Balance",
    flex: 1,
    valueGetter: (params) => params.data["inventory_projected_shortages_nearest-ending_balance"],
  },
  {
    headerName: "Mute Until",
    flex: 1,
    maxWidth: muteUntilMaxWidth,
    sort: "asc",
    sortable: true,
    sortIndex: 0,
    editable: true,
    singleClickEdit: true,
    cellDataType: "dateString",
    // cellEditor: "agDateStringCellEditor",
    field: "annotations-mute_until",
  },
  {
    headerName: "Note",
    flex: 1,
    editable: true,
    singleClickEdit: true,
    field: "annotations-memo",
  },
];

export const getQueryParams = (
  selectedCompanyIds: string[],
  companies: any[],
): [DisplayConfig, BuiltQueryConfig[], InputConfigs] => {
  const dynamicInputs: DynamicInputs = {
    timeDimensions: [],
    filters: [
      {
        inputDataKey: "company_ids",
        member: "inventory_projected_shortages_nearest.company_id",
        operator: "equals",
      },
    ],
  };

  const baseQuery: Query = {
    dimensions: [
      "inventory_projected_shortages_nearest.company_name",
      "inventory_projected_shortages_nearest.item_name",
      "inventory_projected_shortages_nearest.item_name_prefix",
      "inventory_projected_shortages_nearest.item_variant",
      "inventory_projected_shortages_nearest.item_id",
      "inventory_projected_shortages_nearest.date_under_min_inv_qty",
      "inventory_projected_shortages_nearest.date_required",
      "inventory_projected_shortages_nearest.lead_time_days",
      "inventory_projected_shortages_nearest.bal_snapshot_qty_all_loc",
      "inventory_projected_shortages_nearest.inflow_cumulative",
      "inventory_projected_shortages_nearest.outflow_cumulative",
      "inventory_projected_shortages_nearest.ending_balance",
      "inventory_projected_shortages_nearest.inv_qty_min_all_loc",
      "annotations.mute_until",
      "annotations.memo",
      "inventory_projected_shortages_nearest.scope",
    ],
    order: [
      ["annotations.mute_until", "asc"],
      ["inventory_projected_shortages_nearest.date_required", "asc"],
    ],
  };
  const displayConfig: DisplayConfig = {
    cardTitle: "Near-Term Shortages (PO Pull-In Required)",
    outputType: "table",
    noRowsMessage: selectedCompanyIds.length ? "No shortages found near lead time" : "Select a customer to see results",
    columnDefsCustom: customColumnDefs,
  };

  const queryConfigs: BuiltQueryConfig[] = [
    {
      baseQuery: baseQuery,
      dynamicInputs: dynamicInputs,
    },
  ];

  const inputConfigs: InputConfigs = {
    company_ids: {
      disabled: true,
      inputDataType: "multiSelect",
      selectedIds: selectedCompanyIds,
      options: companies,
    },
  };
  return [displayConfig, queryConfigs, inputConfigs];
};
