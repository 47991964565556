import { Query } from "@cubejs-client/core";
import { ColDef, ColGroupDef } from "ag-grid-community";
import { differenceInDays } from "date-fns";
import _ from "lodash";
import ItemNameCell from "../../../tables/cells/ItemNameCell";
import { BuiltQueryConfig, DisplayConfig, DynamicInputs, InputConfigs } from "../DataCard";
import {rowIsMuted, mutedCellClasses, cellClassFunc, companyCellWidth, muteUntilMaxWidth} from '../outputs/DataCardTable/helpers';

const customColumnDefs: (ColGroupDef<any> | ColDef<any, any>)[] = [
  {
    field: "inventory_projected_shortages_lt-company_name",
    headerName: "Company",
    flex: 1,
    width: companyCellWidth,
    maxWidth: companyCellWidth+20,
  },
  {
    field: "inventory_projected_shortages_lt-item_name",
    headerName: "Item",
    flex: 1,
    width: 120,
    maxWidth: 120,
    sortable: true,
    cellRendererSelector: (params) => {
      return {
        component: () => {
          if (!params.data) return;
          return (
            <ItemNameCell
              item_name={
                Object.keys(params.data).find((key) => key.endsWith("item_name"))
                  ? params.data[Object.keys(params.data).find((key) => key.endsWith("item_name"))!]
                  : ""
              }
              item_name_prefix={
                Object.keys(params.data).find((key) => key.endsWith("item_name_prefix"))
                  ? params.data[Object.keys(params.data).find((key) => key.endsWith("item_name_prefix"))!]
                  : ""
              }
              item_variant={
                Object.keys(params.data).find((key) => key.endsWith("item_variant"))
                  ? params.data[Object.keys(params.data).find((key) => key.endsWith("item_variant"))!]
                  : ""
              }
              extra_classes={cellClassFunc(params)}
            />
          );
        },
      };
    },
  },
  {
    field: "lead_time_order_date",
    headerName: "Order Date",
    flex: 1,
    sort: "asc",
    sortable: true,
    sortIndex: 1,
    valueGetter: (params) => {
      return {
        date_under_min: params.data["inventory_projected_shortages_lt-date_under_min_inv_qty"],
        lead_time_days: params.data["inventory_projected_shortages_lt-lead_time_days"],
        date_order_required: params.data["inventory_projected_shortages_lt-date_required"],
        days_till_required: differenceInDays(params.data["inventory_projected_shortages_lt-date_required"], new Date()),
      };
    },
    comparator: (valueA, valueB) => {
      if (valueA.days_till_required == valueB.days_till_required) return 0;
      return valueA.days_till_required > valueB.days_till_required ? 1 : -1;
    },
    valueFormatter: (params) => {
      let relStr = "today";
      if (params.value.days_till_required == 1) {
        relStr = "tomorrow";
      } else if (params.value.days_till_required == -1) {
        relStr = "yesterday";
      } else if (params.value.days_till_required > 1) {
        relStr = `in ${params.value.days_till_required} days`;
      } else if (params.value.days_till_required < 1) {
        relStr = `${Math.abs(params.value.days_till_required)} days ago`;
      }
      return `${params.value.date_order_required} (${relStr})`;
    },
    cellClass: (params) => {
      let cursorClass = "cursor-pointer"
      let colorClass = ""
      if (rowIsMuted(params)) {
        colorClass = mutedCellClasses
      } else if (params.value.days_till_required <= 0) {
        colorClass = "text-orange-700";
      } else if (params.value.days_till_required <= 7) {
        colorClass = "text-amber-500";
      }
      return `${cursorClass} ${colorClass}`
    },
  },
  {
    field: "lead_time_arrival_date",
    headerName: "Arrival Date",
    flex: 1,
    valueGetter: (params) => {
      return {
        date_under_min_inv_qty: params.data["inventory_projected_shortages_lt-date_under_min_inv_qty"],
        lead_time_days: params.data["inventory_projected_shortages_lt-lead_time_days"],
      };
    },
    cellRendererSelector: (params) => {
      return {
        component: () => {
          if (!params.data) return;
          let statusColorClass = rowIsMuted(params) ? cellClassFunc(params) : null;
          return (
            <div className={`inline-flex ${statusColorClass || ""}`}>
              <div className="mr-2 w-10 text-right">{params.value.date_under_min_inv_qty}</div>
              <span className={`${statusColorClass || "text-muted-foreground"}`}>{` in ${params.value.lead_time_days} days LT`}</span>
            </div>
          );
        },
      };
    },
  },
  {
    field: "lead_time_arrival_date",
    headerName: "Current Proj. Balance",
    flex: 1,
    valueGetter: (params) => {
      return {
        ending_balance: params.data["inventory_projected_shortages_lt-ending_balance"],
        inv_qty_min_all_loc: params.data["inventory_projected_shortages_lt-inv_qty_min_all_loc"],
      };
    },
    cellRendererSelector: (params) => {
      return {
        component: () => {
          if (!params.data) return;
          let statusColorClass = rowIsMuted(params) ? cellClassFunc(params) : null;
          return (
            <div className={`inline-flex ${statusColorClass || ""}`}>
              <div className="mr-2 w-8 text-right">{params.value.ending_balance}</div>
              <span className={`${statusColorClass || "text-muted-foreground"}`}>{` vs ${params.value.inv_qty_min_all_loc} min`}</span>
            </div>
          );
        },
      };
    },
  },
  {
    headerName: "Mute Until",
    flex: 1,
    maxWidth: muteUntilMaxWidth,
    sort: "asc",
    sortable: true,
    sortIndex: 0,
    editable: true,
    singleClickEdit: true,
    cellDataType: "dateString",
    // cellEditor: "agDateStringCellEditor",
    field: "annotations-mute_until",
  },
  {
    headerName: "Note",
    flex: 1,
    editable: true,
    singleClickEdit: true,
    field: "annotations-memo",
  },
];

export const getQueryParams = (
  selectedCompanyIds: string[],
  companies: any[],
): [DisplayConfig, BuiltQueryConfig[], InputConfigs] => {
  const dynamicInputs: DynamicInputs = {
    timeDimensions: [],
    filters: [
      {
        inputDataKey: "company_ids",
        member: "inventory_projected_shortages_lt.company_id",
        operator: "equals",
      },
    ],
  };

  const baseQuery: Query = {
    dimensions: [
      "inventory_projected_shortages_lt.company_name",
      "inventory_projected_shortages_lt.item_name",
      "inventory_projected_shortages_lt.item_name_prefix",
      "inventory_projected_shortages_lt.item_variant",
      "inventory_projected_shortages_lt.item_id",
      "inventory_projected_shortages_lt.date_under_min_inv_qty",
      "inventory_projected_shortages_lt.date_required",
      "inventory_projected_shortages_lt.lead_time_days",
      "inventory_projected_shortages_lt.bal_snapshot_qty_all_loc",
      "inventory_projected_shortages_lt.inflow_cumulative",
      "inventory_projected_shortages_lt.outflow_cumulative",
      "inventory_projected_shortages_lt.ending_balance",
      "inventory_projected_shortages_lt.inv_qty_min_all_loc",
      "annotations.mute_until",
      "annotations.memo",
      "inventory_projected_shortages_lt.scope",
    ],
    order: [
      ["annotations.mute_until", "asc"],
      ["inventory_projected_shortages_lt.date_required", "asc"]],
  };
  const displayConfig: DisplayConfig = {
    cardTitle: "Upcoming Order Dates (New Requisitions Required)",
    outputType: "table",
    noRowsMessage: selectedCompanyIds.length ? "No shortages found near lead time" : "Select a customer to see results",
    columnDefsCustom: customColumnDefs,
  };

  const queryConfigs: BuiltQueryConfig[] = [
    {
      baseQuery: baseQuery,
      dynamicInputs: dynamicInputs,
    },
  ];

  const inputConfigs: InputConfigs = {
    company_ids: {
      disabled: true,
      inputDataType: "multiSelect",
      selectedIds: selectedCompanyIds,
      options: companies,
    },
  };
  return [displayConfig, queryConfigs, inputConfigs];
};
