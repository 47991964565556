import { Checkbox, cn } from "@androshq/shared-ui";
import { IdentityUser } from "../../../../../api/types";
import { Approver } from "../../types";

interface Props {
  approversList: Approver[] | undefined;
  currentApprover: IdentityUser | undefined | null;
}

export default function WorkflowModalStatusBar({ approversList, currentApprover }: Props) {
  return (
    <div className="flex gap-10">
      {approversList?.map((approver, idx) => {
        return (
          <div key={idx} className="flex gap-2 items-center">
            <Checkbox
              className={cn(
                "w-4 h-4 rounded-full cursor-default ",
                approver.user.id === currentApprover?.id && " border-cyan-500",
              )}
              checked={approver.approved}
            />
            <div className="text-xs">
              <div>
                {approver.user.given_name} {approver.user.family_name}
              </div>
              <div className="font-semibold">{approver.role}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
