import { Button } from "@androshq/shared-ui";
import { asUnauthenticatedOnlyRoute } from "../hocs";
import { Icons } from "../components/login/Icons";
import { useState, useEffect } from "react";
import { useAuth } from "..";

export default function _LoginPage() {
  const { login } = useAuth();

  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [params, setParams] = useState(
    new URLSearchParams(window.location.search)
  );
  const code = params.get("code");
  // @ts-ignore
  const company_name = import.meta.env.COMPANY_NAME;

  useEffect(() => {
    const handleUrlChanged = () => {
      setParams(new URLSearchParams(window.location.search));
    };

    window.addEventListener("urlChanged", handleUrlChanged);

    return () => {
      window.removeEventListener("urlChanged", handleUrlChanged);
    };
  }, []);

  const handleLoginClicked = () => {
    setIsLoggingIn(true);
    login();
  };

  return (
    <div className="container relative h-full flex-col items-center sm:justify-center grid lg:max-w-none lg:grid-cols-2 lg:px-0">
      <div className="relative hidden h-full flex-col bg-muted p-10 text-white lg:flex dark:border-r">
        <div className="absolute inset-0 bg-zinc-900" />
        <div className="relative z-20 flex items-center text-lg font-medium">
          {company_name}
        </div>
        <div className="relative z-20 mt-auto">
          <footer className="text-base">Andros Platform</footer>
        </div>
      </div>
      <div className="lg:p-8">
        <div className="mx-auto flex flex-col justify-center space-y-6 w-full sm:w-[350px] max-w-[350px]">
          <div className="flex flex-col space-y-2 text-center">
            <h1 className="text-3xl font-bold tracking-tight mb-2">
              {!!code ? "Logging in..." : "Log in"}
            </h1>
          </div>

          {!!code ? (
            <div className="flex items-center justify-center">
              <Icons.spinner className="mr-2 h-8 w-8 animate-spin" />
            </div>
          ) : (
            <div className={"grid gap-4"}>
              <Button
                className="bg-black text-white hover:bg-black/90"
                type="button"
                disabled={isLoggingIn}
                onClick={handleLoginClicked}
              >
                {isLoggingIn && (
                  <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />
                )}{" "}
                Sign-In
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export const LoginPage = asUnauthenticatedOnlyRoute(_LoginPage);
