import { WorkflowExecution } from "../../../../api/types";

export const sortSelectedRows = (selectedRows: WorkflowExecution[]) => {
  const { newRows, changed, moved } = divideSelectedRowsOnThreeGroups(selectedRows);
  return {
    newRows: divideSelectedRowsByVendor(newRows) || [],
    changed: divideSelectedRowsSourceId(changed) || [],
    moved: divideSelectedRowsSourceId(moved) || [],
  };
};

const divideSelectedRowsOnThreeGroups = (selectedRows: WorkflowExecution[]) => {
  const newRows: WorkflowExecution[] = [];
  const changed: WorkflowExecution[] = [];
  const moved: WorkflowExecution[] = [];

  selectedRows.forEach((row) => {
    const recentVersion = row.state.version_history[0];
    const firstVersion = row.state.version_history[row.state.version_history.length - 1];
    if (!recentVersion.data.rows[0].source_metadata.source_id) {
      newRows.push(row);
    } else if (
      firstVersion.data.rows[0].state_transitions[0].to_state_id ===
      recentVersion.data.rows[0].state_transitions[0].to_state_id
    ) {
      changed.push(row);
    } else {
      moved.push(row);
    }
  });

  return { newRows, changed, moved };
};

const divideSelectedRowsByVendor = (rows: WorkflowExecution[]) => {
  const vendorsHashMap: Record<string, WorkflowExecution[]> = {};
  rows.forEach((row) => {
    const vendorId = row.state.version_history[0].data.rows[0].state_transitions[0].to_state_id.toString();
    if (vendorsHashMap[vendorId]) {
      vendorsHashMap[vendorId].push(row);
    } else {
      vendorsHashMap[vendorId] = [row];
    }
  });

  return Object.values(vendorsHashMap);
};

const divideSelectedRowsSourceId = (rows: WorkflowExecution[]) => {
  const sourceIdHashMap: Record<string, WorkflowExecution[]> = {};
  rows.forEach((row) => {
    if (row.state.version_history[0].data.rows[0].source_metadata.source_id) {
      const sourceId = row.state.version_history[0].data.rows[0].source_metadata.source_id.toString();
      if (sourceIdHashMap[sourceId]) {
        sourceIdHashMap[sourceId].push(row);
      } else {
        sourceIdHashMap[sourceId] = [row];
      }
    }
  });

  return Object.values(sourceIdHashMap);
};
