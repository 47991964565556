import { ItemWithConfig } from "../../api/types";

export const ItemName = ({ item }: { item: ItemWithConfig }) => {
  return (
    <span>
      <span className="text-muted-foreground">{item.source_metadata.item_name_prefix || " "}</span>
      {item.name} {item.variant !== null && <span className="text-muted-foreground">({item.variant})</span>}{" "}
      {<span className="text-muted-foreground">({item.config.company.name})</span>}
    </span>
  );
};
