import React, { useState } from "react";

type DashboardContextType = {
  moduleName: string;
  sectionName: string;
  setModuleName: (name: string) => void;
  setSectionName: (name: string) => void;
};

const DashboardContext = React.createContext<DashboardContextType | undefined>(
  undefined
);

export const DashboardProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [moduleName, setModuleName] = useState("");
  const [sectionName, setSectionName] = useState("");

  return (
    <DashboardContext.Provider
      value={{ moduleName, sectionName, setModuleName, setSectionName }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

export const useDashboardContext = () => {
  const context = React.useContext(DashboardContext);

  if (!context) {
    throw new Error(
      "useDashboardContext must be used within a DashboardProvider"
    );
  }

  return context;
};

export default DashboardContext;
