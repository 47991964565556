import { ReactNode, createContext, useEffect, useMemo, useRef, useState } from "react";
import { ApiClient } from "@androshq/core/browser-client";
import { User, UserManager, UserManagerSettings, WebStorageStateStore } from "oidc-client-ts";
import { toast } from "sonner";
import { getPersistedUser, persistUser, removePersistedUser } from "../utils";

// import { authApiClient } from "..";

export interface AuthContextProps {
  isAuthenticated: boolean;
  user: User | null;
  logout: () => Promise<void>;
  login: () => Promise<void>;
  processRedirect: () => Promise<void>;
}

export const AuthContext = createContext<AuthContextProps | undefined>(undefined);

type AuthProviderProps = {
  children: ReactNode;
  oidcConfig: ZitadelOidcConfig;
};

export type ZitadelOidcConfig = {
  project_resource_id: string;
  organization_resource_id: string;
  app_endpoint: string;
} & Omit<
  UserManagerSettings,
  | "scope"
  | "loadUserInfo"
  | "userStore"
  | "redirect_uri"
  | "silent_redirect_uri"
  | "automaticSilentRenew"
  | "response_type"
  | "response_mode"
>;

export const hasAuthParamsInUrl = (location: Location = window.location): boolean => {
  const searchParams = new URLSearchParams(location.search);
  const hashParams = new URLSearchParams(location.hash.replace("#", "?"));

  return (
    searchParams.has("code") ??
    searchParams.has("id_token") ??
    searchParams.has("session_state") ??
    hashParams.has("code") ??
    hashParams.has("id_token") ??
    hashParams.has("session_state")
  );
};

const normalizeUrl = (url: string): string => {
  return url.endsWith("/") ? url.slice(0, -1) : url;
};

const createOidcConfig = ({
  organization_resource_id,
  project_resource_id,
  ...oidcConfig
}: ZitadelOidcConfig): UserManagerSettings => {
  return {
    ...oidcConfig,
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    scope: `urn:zitadel:iam:org:id:${organization_resource_id ?? "269999624352182357"} urn:zitadel:iam:org:project:id:${project_resource_id}:aud urn:zitadel:iam:org:projects:roles openid profile email offline_access`,
    automaticSilentRenew: false,
    redirect_uri: `${normalizeUrl(oidcConfig.app_endpoint)}/login`,
    silent_redirect_uri: `${normalizeUrl(oidcConfig.app_endpoint)}/auth-callback`,
    response_type: "code",
    response_mode: "query",
    post_logout_redirect_uri: `${normalizeUrl(oidcConfig.app_endpoint)}/signout-callback`,
  };
};

const createUserManager = (config: UserManagerSettings) => {
  return new UserManager(config);
};

const apiClient = ApiClient.getInstance();

export const AuthProvider = ({ children, oidcConfig }: AuthProviderProps) => {
  const zitadelConfig = createOidcConfig(oidcConfig);
  const [user, setUser] = useState<User | null>(getPersistedUser());
  const [userManager] = useState(createUserManager(zitadelConfig));

  const isInitialized = useRef(false);

  const isAuthenticated = !!user;

  useEffect(() => {
    if (!userManager || isInitialized.current) return;

    isInitialized.current = true;

    void (async () => {
      // Process auth-callback
      if (hasAuthParamsInUrl()) {
        let user: User | null | void = null;

        try {
          user = await userManager.signinCallback();
          if (!user) throw new Error("Failed to sign in");
        } catch (error) {
          if (user) await userManager.signoutSilent();

          toast.error("Unable to sign in, please try again.", {
            position: "bottom-center",
          });

          // Remove auth params from URL
          window.history.replaceState({}, document.title, window.location.pathname);

          window.dispatchEvent(new Event("urlChanged"));
        }
      }

      // Handle silent signout
      if (window.location.href === zitadelConfig.post_logout_redirect_uri) await userManager.signoutSilentCallback();
    })();
  }, [userManager]);

  useEffect(() => {
    if (!userManager) return undefined;

    const handleUserLoaded = async (user: User) => {
      setUser(user);
    };

    const handleUserUnloaded = () => {
      setUser(null);
    };

    const handleUserSignedOut = () => {
      handleUserUnloaded();
    };

    userManager.events.addUserLoaded(handleUserLoaded);
    userManager.events.addUserUnloaded(handleUserUnloaded);
    userManager.events.addUserSignedOut(handleUserSignedOut);

    return () => {
      userManager.events.removeUserLoaded(handleUserLoaded);
      userManager.events.removeUserUnloaded(handleUserUnloaded);
      userManager.events.removeUserSignedOut(handleUserSignedOut);
    };
  }, [userManager]);

  useEffect(() => {
    apiClient.userProvider = async () => user || null;
    apiClient.refreshHandler = async () => {
      try {
        const user = await userManager.signinSilent();

        if (user) {
          setUser(user);
          return user;
        } else {
          throw Error("Failed to refresh token");
        }
      } catch (error) {
        setUser(null);
        toast.error("Session has expired, please sign in again.", {
          position: "bottom-center",
        });
      }
    };
  }, [user, userManager]);

  useEffect(() => {
    user ? persistUser(user) : removePersistedUser();
  }, [user]);

  const login = async () => {
    await userManager.signinRedirect();
  };

  const logout = async () => {
    await userManager.signoutSilent();
  };

  const processRedirect = async () => {};

  const value = useMemo<AuthContextProps>(() => {
    return {
      login,
      logout,
      user,
      isAuthenticated,
      processRedirect,
    };
  }, [userManager, user]);

  return <AuthContext.Provider {...{ value }}>{children}</AuthContext.Provider>;
};
