import { ResultSet } from "@cubejs-client/core";
import { Filter, TimeDimension, TimeDimensionGranularity } from "@cubejs-client/core";
import { useCubeQuery } from "@cubejs-client/react";
import {
  AgCartesianAxisOptions,
  AgCartesianSeriesTooltipRendererParams,
  AgTimeAxisOptions,
  time,
} from "ag-charts-community";
import { format } from "date-fns";
import { StandardDateFormat, StandardDateFormatStrf, dateToCubeQueryDateString } from "../../../tables/standards";

const replaceDotInKey = (key: string) => {
  return key.replace(/\./g, "_");
};

const transformCubeResultToAGChartInput = (resultSet: ResultSet, granularity?: TimeDimensionGranularity) => {
  const data = resultSet?.chartPivot({}).map((row) => {
    const transformedRow: { [key: string]: Date | number } = {};
    for (const key in row) {
      if (key === "xValues") {
        // Cube includes this, so strip out bc not needed
        continue;
      }
      const newKey = replaceDotInKey(key);
      if (key === "x") {
        transformedRow[newKey] = new Date(row[key]);
      } else {
        transformedRow[newKey] = row[key];
      }
    }
    return transformedRow;
  });

  const series = resultSet?.series().map((series) => {
    return {
      xKey: "x",
      yKey: replaceDotInKey(series.key),
      yName: series.title,
      tooltip: {
        renderer: function ({ datum, xKey, yKey, yName }: AgCartesianSeriesTooltipRendererParams) {
          return {
            content: `${format(datum[xKey], StandardDateFormat.FULLSHORTMONTH)} : ${datum[yKey].toFixed(0)}`,
            title: yName,
          };
        },
      },
    };
  });

  const axes = [
    {
      type: "number",
      position: "left",
      label: {},
      crosshair: {
        enabled: true,
      },
    } as AgCartesianAxisOptions,
    {
      type: "time",
      nice: false,
      position: "bottom",
      tick: {
        interval: granularity === "week" ? time.monday : time.month,
      },
      label: {
        format: granularity === "week" ? StandardDateFormatStrf.MONTHDAY : StandardDateFormatStrf.MONTHYEAR,
        autoRotateAngle: 335,
        autoRotate: true,
      },
      crosshair: {
        enabled: false,
      },
    } as AgTimeAxisOptions,
  ];

  const otherOptions = {};

  return {
    data: data,
    series: series,
    axes: axes,
    otherOptions: otherOptions,
  };
};

export const useFetchMetrics = (
  measures: string[],
  dateRange?: [Date, Date],
  granularity?: TimeDimensionGranularity,
  filters?: Filter[],
) => {
  const queryTimeDim: TimeDimension = {
    dimension: "weeks.week",
  };
  if (dateRange) {
    queryTimeDim.dateRange = [dateToCubeQueryDateString(dateRange[0]), dateToCubeQueryDateString(dateRange[1])];
  }
  if (granularity) {
    queryTimeDim.granularity = granularity;
  }

  const { resultSet, isLoading } = useCubeQuery({
    measures,
    timeDimensions: [queryTimeDim],
    filters: filters,
    dimensions: [],
    order: [["weeks.week", "asc"]],
  });

  const { data, series, axes, otherOptions } = transformCubeResultToAGChartInput(resultSet!, granularity);
  return { data: data, series: series, axes: axes, otherOptions: otherOptions, isMetricsLoading: isLoading };
};
