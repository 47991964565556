import { Navigate } from "react-router-dom";
import { useAuth } from "../hooks";

export const asUnauthenticatedOnlyRoute =
  (Component: React.ComponentType<any>) => (props: any) => {
    const { isAuthenticated } = useAuth();

    return isAuthenticated ? (
      <Navigate to="/" replace />
    ) : (
      <Component {...props} />
    );
  };
