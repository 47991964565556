import { ColDef, ColGroupDef, CellClassParams, ICellRendererParams } from "ag-grid-community";
import { format, parse, isSameDay } from "date-fns";
import _ from "lodash";
import { dateFormatShort } from "../../../../../constants";
import ItemNameCell from "../../../../tables/cells/ItemNameCell";
import { ExtendedResultSet } from "./types";

export const mapDataFromResultSet = (resultSet: ExtendedResultSet | null): any[] | undefined => {
  return (
    resultSet?.tablePivot().map((row: any) =>
      Object.keys(row).reduce((object, key) => {
        const annotation = resultSet?.loadResponses![0].annotation;
        const mergedKeys = { ...annotation.measures, ...annotation.dimensions };

        let value = row[key];
        switch (mergedKeys[key].type) {
          case "time":
            if (!_.isNull(value)) {
              value = format(value, dateFormatShort);
            }
        }

        return {
          ...object,
          [key.replace(".", "-")]: value,
        };
      }, {}),
    ) || []
  );
};

export const mapColumnDefsFromResultSet = (resultSet: ExtendedResultSet | null): ColDef[] => {
  const columnDefsMap: { [key: string]: ColGroupDef | ColDef } = {};
  const dimensions = resultSet?.loadResponses![0].annotation.dimensions;
  const measures = resultSet?.loadResponses![0].annotation.measures;

  const processColumn = (key: string, title: string) => {
    const splitTitle = title.split(" | ");
    const mainTitle = splitTitle[0];
    const subTitle = splitTitle[1] || "";

    if (
      subTitle.length === 0 &&
      !key.includes("item_id") &&
      !key.includes("item_name") &&
      !key.includes("item_name_prefix") &&
      !key.includes("item_variant")
    ) {
      columnDefsMap[mainTitle] = {
        field: key.replace(".", "-"),
        headerName: subTitle === "" ? mainTitle : subTitle,
        flex: 1,
        cellClass: "cursor-pointer",
      };
      if (key.includes("_name")) {
        columnDefsMap[mainTitle].minWidth = 120;
      }
    } else if (key.endsWith("item_name")) {
      columnDefsMap[mainTitle] = {
        field: key.replace(".", "-"),
        headerName: subTitle === "" ? mainTitle : subTitle,
        flex: 1,
        cellClass: "cursor-pointer",
        minWidth: 120,
        cellRendererSelector: (params: ICellRendererParams) => {
          return {
            component: () => {
              if (!params.data) return;
              return (
                <ItemNameCell
                  item_name={
                    Object.keys(params.data).find((key) => key.endsWith("item_name"))
                      ? params.data[Object.keys(params.data).find((key) => key.endsWith("item_name"))!]
                      : ""
                  }
                  item_name_prefix={
                    Object.keys(params.data).find((key) => key.endsWith("item_name_prefix"))
                      ? params.data[Object.keys(params.data).find((key) => key.endsWith("item_name_prefix"))!]
                      : ""
                  }
                  item_variant={
                    Object.keys(params.data).find((key) => key.endsWith("item_variant"))
                      ? params.data[Object.keys(params.data).find((key) => key.endsWith("item_variant"))!]
                      : ""
                  }
                  extra_classes={cellClassFunc(params)}
                />
              );
            },
          };
        },
      };
    }
  };

  for (const key in dimensions) {
    processColumn(key, dimensions[key].shortTitle);
  }
  for (const key in measures) {
    processColumn(key, measures[key].shortTitle);
  }

  return Object.values(columnDefsMap);
};

export const rowIsMuted = (params: CellClassParams|ICellRendererParams) => {
  const annotationsMuteField = "annotations-mute_until";
  if (params && params.data && annotationsMuteField in params.data && params.data[annotationsMuteField]) {
    const muteUntilDate = parse(params.data[annotationsMuteField],"M/d/yy", new Date());
    if (muteUntilDate > new Date() && !isSameDay(muteUntilDate, new Date())) {
      return true;
    }
  }
  return false;
}

export const mutedCellClasses = "text-gray-400"
export const companyCellWidth = 90
export const muteUntilMaxWidth = 110

export const cellClassFunc = (params: CellClassParams|ICellRendererParams) => {
  let colorClass = ""
  let cursorClass = "cursor-pointer"
  if (rowIsMuted(params)) {
    colorClass = mutedCellClasses
  }
  if (params.colDef?.editable) {
    cursorClass = "cursor-text"
  }
  return `${cursorClass} ${colorClass}`
}