import { ResultSet } from "@cubejs-client/core";
import { format, startOfWeek } from "date-fns";
import { measures } from "../../../api/cube";

type AggregatedForecastItem = {
  item: { id: number };
  company: { id: number };
  demandForecastAtLeadTime: number;
  demandActual: number;
  activeCutQtySumWtd: number;
  [week: string]:
  | { value: string | number | boolean; recommendation: string | number | boolean; plan: string | number | boolean }
  | number
  | { id: string | number | boolean };
};

export const mapForecastRecommendationsFromResultSets = (resultSets: (ResultSet | null)[]) => {
  if (
    resultSets.some((resultSet) => resultSet === null) ||
    !resultSets[0]?.seriesNames()[0]?.key.startsWith("demand_actual")
  ) {
    // Secondary check on series name to handle case where inv_balances is live view, then go to fcst and get wrong/stale resultSet
    return undefined;
  }

  const pivotLeftColRef = resultSets[0]?.tablePivot().reduce(
    (
      acc: Record<
        string,
        { demandForecastAtLeadTime: number; demandActual: number; demandDifferencePercentage: number }
      >,
      obj,
    ) => {
      const key = obj["items.id"] as number;
      const ltForecastVal = Number(obj["demand_forecast_at_lead_time.quantity_total_avg"]);
      const actualVal = Number(obj["demand_actual.quantity_total_avg"]);
      const actualForecastRatio = actualVal / ltForecastVal;

      acc[key] = {
        demandForecastAtLeadTime: Math.round(ltForecastVal),
        demandActual: Math.round(actualVal),
        demandDifferencePercentage: ltForecastVal == 0 ? Infinity : Math.round(actualForecastRatio * 100),
      };
      return acc;
    },
    {} as Record<
      string,
      { demandForecastAtLeadTime: number; demandActual: number; demandDifferencePercentage: number }
    >,
  );

  const mappedResult = resultSets[1]
    ?.tablePivot()
    .filter((i) => i["demand_forecast_most_recent.week"])
    .map((i) => {
      if (typeof i["demand_forecast_most_recent.item_id"] !== "number") {
        throw new Error("demand_forecast_most_recent.item_id");
      }
      const leftColRefRowData = pivotLeftColRef[i["demand_forecast_most_recent.item_id"]];

      return {
        week: format(
          startOfWeek(new Date(i["demand_forecast_most_recent.week"] as string), { weekStartsOn: 1 }),
          "yyyy-MM-dd",
        ),

        item: {
          id: i["demand_forecast_most_recent.item_id"],
        },
        company: {
          id: i["demand_forecast_most_recent.company_id"],
        },

        demandForecastAtLeadTime: leftColRefRowData.demandForecastAtLeadTime,
        demandActual: leftColRefRowData.demandActual,
        activeCutQtySumWtd: parseInt(i["demand_forecast_most_recent.active_cut_qty_item_sum_wtd"] as string),

        value: i[measures.forecast.mostRecent.total],
        recommendation: i[measures.forecast.mostRecent.recommended],
        plan: i[measures.forecast.mostRecent.fixed],
      };
    });

  const aggregatedResult = mappedResult?.reduce(
    (acc, row) => {
      const { week, item, value, recommendation, plan, ...rest } = row;
      const itemId = Number(item.id);

      if (!acc[itemId]) {
        acc[itemId] = {
          item: { ...item, id: itemId },
          ...rest,
          company: { ...rest.company, id: Number(rest.company.id) },
        };
      }

      acc[itemId][week] = { value, recommendation, plan };

      return acc;
    },
    {} as Record<number, AggregatedForecastItem>,
  );

  if (!aggregatedResult) return undefined;
  return Object.values(aggregatedResult);
};

type AggregatedInventoryFlowItem = {
  item: { id: number; name: string };
  company: { id: number; name: string };
  [week: string]:
  | {
    inflow: string | number | boolean;
    outflow: string | number | boolean;
    inflowCumulative: string | number | boolean;
    outflowCumulative: string | number | boolean;
    balanceSnapshot: string | number | boolean;
  }
  | number
  | { id: string | number | boolean };
};

export const mapInventoryRollforwardFromResultSets = (resultSets: (ResultSet | null)[]) => {
  const filteredResult = resultSets[0]?.tablePivot().filter((i) => i["inventory_flows.date.week"]);
  const mappedResult = filteredResult?.map((i) => {
    return {
      week: format(startOfWeek(new Date(i["inventory_flows.date.week"] as string), { weekStartsOn: 1 }), "yyyy-MM-dd"),

      item: {
        id: Number(i["inventory_flows.item_id"]),
        name: i["inventory_flows.item_name"].toString(),
        name_prefix: i["inventory_flows.item_name_prefix"],
        variant: i["inventory_flows.item_variant"],
      },
      company: {
        id: Number(i["inventory_flows.company_id"]),
        name: i["inventory_flows.company_name"].toString(),
      },

      inflow: +i["inventory_flows.inflow"],
      outflow: +i["inventory_flows.outflow"],
      inflowCumulative: +i["inventory_flows.inflow_cumulative"],
      outflowCumulative: +i["inventory_flows.outflow_cumulative"],
      balanceSnapshot: +i["inventory_flows.bal_snapshot_qty_all_loc"],

      // TODO: We need to find a way to pass Min Qty
      // minInvQty: i["inventory_flows.inv_qty_min_all_loc"],
    };
  });

  const itemActivityAbsoluteValueSum = {} as Record<number, number>;
  const aggregatedResult = mappedResult?.reduce(
    (acc, row) => {
      const { week, item, inflow, outflow, inflowCumulative, outflowCumulative, balanceSnapshot, ...rest } = row;
      const itemId = item.id as number;

      // Aggregate absolute value of all activity for item, so we can filter out fully zero rows (otherwise lot of noise)
      const itemWeekAbsSum = Math.abs(inflowCumulative) + Math.abs(outflowCumulative) + Math.abs(balanceSnapshot);
      if (!(itemId in itemActivityAbsoluteValueSum)) {
        itemActivityAbsoluteValueSum[itemId] = 0;
      }
      itemActivityAbsoluteValueSum[itemId] += itemWeekAbsSum;

      if (!acc[itemId]) {
        acc[itemId] = { item, ...rest };
      }

      acc[itemId][week] = { inflow, outflow, inflowCumulative, outflowCumulative, balanceSnapshot };

      return acc;
    },
    {} as Record<number, AggregatedInventoryFlowItem>,
  );

  if (!aggregatedResult) return undefined;
  return Object.values(aggregatedResult).filter(
    (invFlowItem) =>
      invFlowItem.item.id in itemActivityAbsoluteValueSum && itemActivityAbsoluteValueSum[invFlowItem.item.id] > 0,
  );
};
