import { Avatar } from "@androshq/shared-ui";

export default function Company() {
  const companyName = import.meta.env.COMPANY_NAME;

  return (
    <div className="flex cursor-pointer gap-2">
      <Avatar className="h-5 w-5 bg-[#E64B38]">
        {/* <AvatarImage src="/images/avatar/placeholder.jpg" /> */}
      </Avatar>

      <span className="truncate text-sm font-medium">{companyName}</span>
    </div>
  );
}
