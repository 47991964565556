import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  ReloadIcon,
  cn,
} from "@androshq/shared-ui";
import queryString from "query-string";
import { useNavigate } from "react-router-dom";
import { SyncedLinesSearchQueryParams, sopApiClient } from "../../../../api";
import { ItemWithConfig, TransitionFull } from "../../../../api/types";
import Tooltip from "../../../../components/messages/Tooltip";
import ItemNameCell from "../../../../components/tables/cells/ItemNameCell";

interface Props {
  isSearchDocumentOpen: boolean;
  setIsSearchDocumentOpen: Dispatch<SetStateAction<boolean>>;
  items: ItemWithConfig[] | undefined;
}

export default function SearchDocument({ isSearchDocumentOpen, setIsSearchDocumentOpen, items }: Props) {
  const [documentName, setDocumentName] = useState("");
  const [debouncedDocumentName, setDebouncedDocumentName] = useState(documentName);
  const [isLinesLoading, setIsLinesLoading] = useState<boolean>(false);
  const [lines, setLines] = useState<TransitionFull[] | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedDocumentName(documentName);
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [documentName]);

  useEffect(() => {
    if (debouncedDocumentName) {
      getLines();
    }
  }, [debouncedDocumentName]);

  const getLines = async () => {
    setIsLinesLoading(true);
    try {
      const filters: SyncedLinesSearchQueryParams = {
        filters: { line_parent_name: [debouncedDocumentName] },
        limit: 20,
        offset: 0,
      };
      const response = await sopApiClient.lines.search(filters);

      if (response.success) {
        setLines(response.data);
      }
    } catch (error) {
      console.error("Error in line search", error);
    } finally {
      setIsLinesLoading(false);
    }
  };

  const renderEmpty = () => {
    if (isLinesLoading) {
      return (
        <CommandEmpty>
          <div className="flex justify-center">
            <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
          </div>
        </CommandEmpty>
      );
    }
    return <CommandEmpty>No results found.</CommandEmpty>;
  };

  const selectLine = (line: TransitionFull) => {
    setIsSearchDocumentOpen(false);
    const queryParams = queryString.parse(location.search);
    queryParams.line_id_source = line.line_id_source;
    queryParams.line_parent_id_source = line.line_parent_id_source;

    const newSearch = queryString.stringify(queryParams, { encode: false });
    navigate({ search: newSearch, pathname: location.pathname }, { replace: true });
  };

  const getTooltipForLine = (line: TransitionFull) => {
    if (line.workflow_run_id_lock !== null) {
      return "An edit approval workflow has already started for this line.";
    } else if (line.actual) {
      return "This line can no longer be edited (already shipped).";
    } else {
      return "Click to edit";
    }
  };

  const renderItems = () => {
    if (lines === null || !lines.length || !items) return;

    return (
      <CommandGroup heading="Lines">
        {lines.map((line) => {
          const item = items.find((item) => item.id === line.item_id);

          return (
            <CommandItem
              disabled={line.actual === true || line.workflow_run_id_lock !== null}
              key={line.id}
              onSelect={() => selectLine(line)}
            >
              <Tooltip tooltip={getTooltipForLine(line)}>
                <div
                  className={cn("flex justify-between gap-2 w-full cursor-pointer", {
                    "opacity-50 cursor-auto": line.actual || line.workflow_run_id_lock !== null,
                  })}
                >
                  <span>
                    {line.line_parent_name}_{line.line_id_source}
                  </span>
                  <ItemNameCell
                    item_name={item?.name || ""}
                    item_name_prefix={item?.source_metadata.item_name_prefix}
                    item_variant={item?.variant}
                  />
                  <span className=" pr-5">Qty: {line.quantity}</span>
                </div>
              </Tooltip>
            </CommandItem>
          );
        })}
      </CommandGroup>
    );
  };

  return (
    <CommandDialog shouldFilter={false} open={isSearchDocumentOpen} onOpenChange={setIsSearchDocumentOpen}>
      <CommandInput placeholder="Search lines for edit..." value={documentName} onValueChange={setDocumentName} />
      <CommandList>
        {renderEmpty()}
        {renderItems()}
      </CommandList>
    </CommandDialog>
  );
}
