import { useEffect, useState } from "react";
import { Dialog, DialogContent } from "@androshq/shared-ui";
import queryString from "query-string";
import { useNavigate } from "react-router-dom";
import { useFetchWorkflowExecution } from "../../../../api/hooks/workflows";
import CAModalSectionHistory from "./CAModalSectionHistory";
import CAModalSectionInputs from "./CAModalSectionInputs";

interface Props {
  workflowIdAndRunId: { workflow_id: string | undefined; workflow_run_id: string | undefined };
  invalidateData: () => void;
}

export default function CAWorkflowModal({ workflowIdAndRunId, invalidateData }: Props) {
  const queryParams: queryString.ParsedQuery<string> = queryString.parse(location.search);
  const [isWorkflowModalOpen, setWorkflowModalOpen] = useState(false);
  const navigate = useNavigate();
  const [selectedRowData, setSelectedRowData] = useState<any | null>(null);

  const result = useFetchWorkflowExecution(workflowIdAndRunId.workflow_id, workflowIdAndRunId.workflow_run_id);

  useEffect(() => {
    if (result?.data) {
      if (result?.data.length === 0) return;
      setSelectedRowData(result?.data[0]);
      setWorkflowModalOpen(true);
    }
  }, [result.data]);

  const closeModal = () => {
    delete queryParams["workflow_id"];
    delete queryParams["workflow_run_id"];
    const newSearch = queryString.stringify(queryParams);
    navigate({ search: newSearch, pathname: location.pathname }, { replace: true });
    setWorkflowModalOpen(false);
  };

  return (
    <Dialog open={isWorkflowModalOpen} onOpenChange={closeModal}>
      <DialogContent className="sm:max-w-6xl" tabIndex={-1}>
        <CAModalSectionInputs data={selectedRowData} invalidateData={invalidateData} closeModal={closeModal} />
        <hr />
        <CAModalSectionHistory workflowState={selectedRowData?.state} />
      </DialogContent>
    </Dialog>
  );
}
