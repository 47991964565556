import { useEffect } from "react";
import { useDashboardContext } from "../contexts";

export const withModuleDashboardPageProps =
  (
    Component: React.ComponentType<any>,
    {
      moduleName,
      sectionName,
    }: {
      moduleName: string;
      sectionName: string;
    }
  ) =>
  (props: any) => {
    const { setModuleName, setSectionName } = useDashboardContext();

    useEffect(() => {
      setModuleName(moduleName);
      setSectionName(sectionName);
    }, []);

    return <Component {...props} />;
  };
