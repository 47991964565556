import { BellIcon, CheckCircledIcon, ClockIcon, CrossCircledIcon } from "@androshq/shared-ui";
import { getPersistedUser } from "../../../../../../auth/src/browser-client/utils";
import { User } from "../../../api/types";
import Tooltip from "../../../components/messages/Tooltip";
import { ApprovalStatusEnum } from "../changeApprovals/types";

interface Props {
  value: ApprovalStatusEnum;
  currentApprover: User | null | undefined;
}

const CloudIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="size-4 text-gray-500"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M2.25 15a4.5 4.5 0 0 0 4.5 4.5H18a3.75 3.75 0 0 0 1.332-7.257 3 3 0 0 0-3.758-3.848 5.25 5.25 0 0 0-10.233 2.33A4.502 4.502 0 0 0 2.25 15Z"
      />
    </svg>
  );
};

const CloudWithArrowIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="size-4 text-cyan-600"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12 16.5V9.75m0 0 3 3m-3-3-3 3M6.75 19.5a4.5 4.5 0 0 1-1.41-8.775 5.25 5.25 0 0 1 10.233-2.33 3 3 0 0 1 3.758 3.848A3.752 3.752 0 0 1 18 19.5H6.75Z"
      />
    </svg>
  );
};

const WarningIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="size-4 text-destructive"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z"
      />
    </svg>
  );
};

const SyncInProgressIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="size-4 text-cyan-600"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
      />
    </svg>
  );
};

export default function WFExecIconStatus({ value, currentApprover }: Props) {
  const userId = getPersistedUser()?.profile.email;

  // console.log(value, currentApprover);

  const getIcon = () => {
    if ([ApprovalStatusEnum.AWAITING_APPROVAL].includes(value)) {
      if (userId && currentApprover && userId === currentApprover.id) {
        return (
          <div className="flex gap-1 mt-1">
            <Tooltip
              tooltip={`Awaiting approval. Assigned to ${currentApprover.given_name} ${currentApprover.family_name}.`}
            >
              <BellIcon className="text-cyan-600" />
            </Tooltip>
            <Tooltip tooltip="ERP sync will be enabled when the line is fully approved.">
              <div>
                <CloudIcon />
              </div>
            </Tooltip>
          </div>
        );
      } else {
        return (
          <div className="flex gap-1 mt-1">
            <Tooltip
              tooltip={`Awaiting approval. Assigned to ${currentApprover?.given_name} ${currentApprover?.family_name}.`}
            >
              <ClockIcon className="text-cyan-600" />
            </Tooltip>
            <Tooltip tooltip="ERP sync will be enabled when the line is fully approved.">
              <div>
                <CloudIcon />
              </div>
            </Tooltip>
          </div>
        );
      }
    } else if ([ApprovalStatusEnum.APPROVED__SYNC_READY].includes(value)) {
      return (
        <div className="flex gap-1 mt-1">
          <Tooltip tooltip="Approvals complete">
            <CheckCircledIcon className="text-gray-500" />
          </Tooltip>
          <Tooltip tooltip="Ready to sync to ERP">
            <div>
              <CloudWithArrowIcon />
            </div>
          </Tooltip>
        </div>
      );
    } else if ([ApprovalStatusEnum.CANCELLED].includes(value)) {
      return (
        <div className="flex gap-1 mt-1">
          <Tooltip tooltip="Workflow canceled">
            <CrossCircledIcon className="text-gray-500" />
          </Tooltip>
          <Tooltip tooltip="ERP sync will be enabled when the line is fully approved.">
            <div>
              <CloudIcon />
            </div>
          </Tooltip>
        </div>
      );
    } else if ([ApprovalStatusEnum.APPROVED__SYNC_COMPLETE].includes(value)) {
      return (
        <div className="flex gap-1 mt-1">
          <Tooltip tooltip="Approvals complete">
            <CheckCircledIcon className="text-gray-500" />
          </Tooltip>
          <Tooltip tooltip="Line synced successfully">
            <CheckCircledIcon className=" text-green-500" />
          </Tooltip>
        </div>
      );
    } else if ([ApprovalStatusEnum.APPROVED__SYNC_FAILED].includes(value)) {
      return (
        <div className="flex gap-1 mt-1">
          <Tooltip tooltip="Approvals complete">
            <CheckCircledIcon className="text-gray-500" />
          </Tooltip>
          <Tooltip tooltip="Line failed to sync; open workflow for details">
            <div>
              <WarningIcon />
            </div>
          </Tooltip>
        </div>
      );
    } else if ([ApprovalStatusEnum.APPROVED__SYNC_STARTED].includes(value)) {
      return (
        <div className="flex gap-1 mt-1">
          <Tooltip tooltip="Approvals complete">
            <CheckCircledIcon className="text-gray-500" />
          </Tooltip>
          <Tooltip tooltip="Sync in progress...">
            <div>
              <SyncInProgressIcon />
            </div>
          </Tooltip>
        </div>
      );
    } else {
      return value;
    }
  };

  return getIcon();
}
