import { PropsWithChildren } from "react";
import { cn } from "@androshq/shared-ui";

interface Props {
  value: string | number | null;
  /**
   * Apply title styles to the component
   *
   * @default false
   * @example <CellMultilineValue value="Inflow" title />
   * @public
   */
  title?: boolean;
  renderZeros?: boolean;
  className?: string;
  id: number;
}

export default function CellMultilineValue({
  value,
  title = false,
  renderZeros,
  id,
  className,
  children,
  ...props
}: PropsWithChildren<Props>) {
  const renderValue = <T,>(num: T): T | "" => (num === 0 ? (renderZeros ? num : "") : num);
  const titleClassName = "text-zinc-400 text-2xs font-semibold uppercase";

  return (
    <span
      data-testid={`inventory-rollforward-cell-${id}`}
      className={cn("flex items-center gap-x-2.5 text-xs", { [titleClassName]: title }, className)}
      style={{ height: 20 }}
      {...props}
    >
      {renderValue(value)}
      {children}
    </span>
  );
}
