import { useQuery } from "@tanstack/react-query";
import { sopApiClient } from "../../api/client";

export const useItems = () => {
  return useQuery({
    queryKey: ["items"],
    queryFn: async () => {
      const data = await sopApiClient.items.getAll();
      if (!data.success) throw new Error(data.message);
      return data.data;
    },
    staleTime: 1000 * 60 * 60, // 1 hour
  });
};
